import React from 'react';
import {
  AccountBox,
  People,
  Groups,
  SettingsSuggest,
  Home,
  Storage,
  HelpCenter,
} from '@mui/icons-material';
import {
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import useTitle from '../hooks/useTitle';

function AppDrawer({ drawerOpen, setDrawerOpen }) {
  const { pathname } = useLocation();

  // Updating page title in AppDrawer as is the common component for all pages.
  useTitle(pathname);

  return (
    <Drawer
      variant="temporary"
      anchor="left"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <List>
        <ListItemButton
          component={Link}
          to="/"
          selected={pathname === '/'}
          onClick={() => setDrawerOpen(false)}
        >
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/teams"
          selected={pathname === '/teams'}
          onClick={() => setDrawerOpen(false)}
        >
          <ListItemIcon>
            <Groups />
          </ListItemIcon>
          <ListItemText primary="Teams" />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/services"
          selected={pathname === '/services'}
          onClick={() => setDrawerOpen(false)}
        >
          <ListItemIcon>
            <SettingsSuggest />
          </ListItemIcon>
          <ListItemText primary="Services" />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/people"
          selected={pathname === '/people'}
          onClick={() => setDrawerOpen(false)}
        >
          <ListItemIcon>
            <People />
          </ListItemIcon>
          <ListItemText primary="People" />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to="/resources"
          selected={pathname === '/resources'}
          onClick={() => setDrawerOpen(false)}
        >
          <ListItemIcon>
            <Storage />
          </ListItemIcon>
          <ListItemText primary="Resources" />
        </ListItemButton>
        <ListItemButton
          component="a"
          href="https://gopuff.atlassian.net/wiki/spaces/GD/pages/2668068893/PuffDirectory+Overview"
          target="_blank"
          rel="noopener noreferrer"
          selected={pathname === '/about'}
          onClick={() => setDrawerOpen(false)}
        >
          <ListItemIcon>
            <HelpCenter />
          </ListItemIcon>
          <ListItemText primary="About" />
        </ListItemButton>
      </List>
      <Divider />
      <List subheader={<ListSubheader>Settings</ListSubheader>}>
        <ListItemButton
          style={{ color: '#000' }}
          component={Link}
          to="/profile"
          selected={pathname === '/profile'}
          disabled
          onClick={() => setDrawerOpen(false)}
        >
          <ListItemIcon>
            <AccountBox />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItemButton>
      </List>
    </Drawer>
  );
}
export default AppDrawer;
