import {
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Card,
  MenuItem,
  Checkbox,
  LinearProgress,
  TextField,
  ListItemText,
  Box,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import SortableTable from '../components/SortableTable';
import useResources from '../hooks/useResources';
import ResourceIcon from '../components/ResourceIcon';

const columns = [
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    icons: true,
    label: 'Type',
  },
  {
    id: 'name',
    route: true,
    resource: 'resource',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'owner',
    route: true,
    resource: 'team',
    numeric: false,
    disablePadding: false,
    hide_mobile: true,
    label: 'Team',
  },

  {
    id: 'lifecycle',
    numeric: true,
    chip: true,
    disablePadding: false,
    label: 'Lifecycle',
  },
];

export default function ResourcesPage() {
  const { items, loading } = useResources();
  const [firstload, setFirstLoad] = React.useState(true);
  const [searchItems, setSearchItems] = React.useState([]);
  const [searchActive, setSearchActive] = React.useState(false);
  const [searchTxt, setSearchTxt] = React.useState('');
  const saved_lifecycle = localStorage.getItem('resource_lifecycle');
  if (!saved_lifecycle) {
    localStorage.setItem('resource_lifecycle', `${['Production']}`);
  }
  const saved_type = localStorage.getItem('resource_type');
  if (!saved_type) {
    localStorage.setItem('resource_type', `${['Postgres Flex', 'Postgres']}`);
  }

  const [lifecycle, setLifecycle] = React.useState(
    localStorage.getItem('resource_lifecycle').split(',')
  );
  const [res_type, setResType] = React.useState(
    localStorage.getItem('resource_type').split(',')
  );
  const theme = useTheme();
  // Set "all_lifecycles" from the array of object containing "lifecycle", excluding undefined values
  const all_lifecycles = [
    ...new Set(
      items.map((item) => item.lifecycle).filter((item) => item !== undefined)
    ),
  ];
  const all_types = [
    ...new Set(
      items.map((item) => item.type).filter((item) => item !== undefined)
    ),
  ];

  /** @param {any} params */
  const dropDownFiltering = ({ search, passed_res_type, passed_lifecycle }) => {
    // Filter items by lifecycle
    if (!search) {
      search = searchTxt;
    }
    if (search === 'EMPTY') {
      search = '';
    }
    if (!passed_res_type) {
      passed_res_type = res_type;
    }
    if (!passed_lifecycle) {
      passed_lifecycle = lifecycle;
    }
    let filtered = [];
    if (search !== '') {
      filtered = items.filter(
        (i) => i.name.toLowerCase().indexOf(searchTxt.toLowerCase()) >= 0
      );
    } else {
      filtered = items;
    }
    filtered = filtered.filter((i) => passed_lifecycle.includes(i.lifecycle));
    filtered = filtered.filter((i) => passed_res_type.includes(i.type));
    setSearchItems(filtered);
  };

  if (!loading && firstload) {
    setFirstLoad(false);
    dropDownFiltering({});
  }

  const handleInputChange = async (e) => {
    const len = e.target.value.length;
    if (e.target.value === '') {
      setSearchTxt('');
      dropDownFiltering({ search: 'EMPTY' });
      setSearchActive(false);
    }
    if (len > 1) {
      setSearchActive(true);
      setSearchTxt(e.target.value);
      let filtered = items.filter(
        (i) => i.name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
      );
      filtered = filtered.filter((i) => lifecycle.includes(i.lifecycle));
      filtered = filtered.filter((i) => res_type.includes(i.type));
      setSearchItems(filtered);
    }
  };

  function getStyles(name, lifecycleName, theme) {
    return {
      fontWeight:
        lifecycleName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleChangeLifecycle = (event) => {
    const {
      target: { value },
    } = event;
    setLifecycle(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    localStorage.setItem('resource_lifecycle', value);
    dropDownFiltering({ passed_lifecycle: value });
  };

  const handleChangeResType = (event) => {
    const {
      target: { value },
    } = event;
    setResType(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
    localStorage.setItem('resource_type', value);
    dropDownFiltering({ passed_res_type: value });
  };

  return (
    <span>
      <Typography variant="h6" component="h1" sx={{ p: 1.5 }}>
        Resources ({searchItems?.length})
      </Typography>
      <Card style={{ padding: '0px' }} variant="outlined" sx={{ padding: 2 }}>
        <form noValidate autoComplete="off">
          <Box display="flex">
            <FormControl sx={{ marginTop: 1, flexGrow: 1 }} size="small">
              <InputLabel id="lifecycle">Lifecycle</InputLabel>
              <Select
                labelId="lifecycle"
                id="lifecycle"
                multiple
                renderValue={(selected) => selected.join(', ')}
                value={lifecycle}
                onChange={handleChangeLifecycle}
                input={<OutlinedInput label="Lifecycle" />}
              >
                {all_lifecycles.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(all_lifecycles, lifecycle, theme)}
                  >
                    <Checkbox
                      size="small"
                      checked={lifecycle.indexOf(name) > -1}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ marginTop: 1, flexGrow: 1 }} size="small">
              <InputLabel id="res_type">Type</InputLabel>
              <Select
                labelId="type"
                id="type"
                multiple
                renderValue={(selected) => selected.join(', ')}
                value={res_type}
                onChange={handleChangeResType}
                input={<OutlinedInput label="Type" />}
              >
                {all_types.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(all_types, lifecycle, theme)}
                  >
                    <Checkbox
                      size="small"
                      checked={res_type.indexOf(name) > -1}
                    />
                    <ResourceIcon name={name} sx={{ marginRight: 1 }} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <TextField
            size="small"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
            }}
            id="outlined-search"
            label="Filter resources"
            type="search"
            variant="outlined"
            onChange={handleInputChange}
          />
        </form>
        {loading ? (
          <span>
            <LinearProgress />
            loading...
          </span>
        ) : (
          ''
        )}

        {searchItems?.length > 0 ? (
          <SortableTable id="resources" rows={searchItems} columns={columns} />
        ) : (
          ''
        )}
        {searchActive && searchItems?.length === 0 && (
          <div>No search results</div>
        )}
      </Card>
    </span>
  );
}
