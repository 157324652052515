import React from 'react';
import { Groups, Person } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  LinearProgress,
  TableCell,
  SvgIcon,
  Chip,
  TableRow,
  Typography,
  Link,
} from '@mui/material';
import { Link as RLink, useParams } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import { BasicTable } from '../components/BasicTable';
import PagerdutyIcon from '../components/PagerdutyIcon';
import usePerson from '../hooks/usePerson';
import { relativeDateFormat } from '../shared/data-utils';
import GitHubIcon from '@mui/icons-material/GitHub';

export default function PersonPage(params) {
  const { email } = useParams();
  const { person, loading } = usePerson(email);
  let joined_date;
  if (person.start_date) {
    joined_date = relativeDateFormat(person.start_date);
  }

  let pagerduty_details;
  let schedules = [];
  let escalations = [];
  if (person.pagerduty_details) {
    pagerduty_details = person.pagerduty_details;
    schedules = pagerduty_details.schedules;
    escalations = pagerduty_details.escalations;
  }
  function SlackIcon() {
    return (
      <SvgIcon>
        <svg viewBox="0 0 2447.6 2452.5" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path
              d="m897.4 0c-135.3.1-244.8 109.9-244.7 245.2-.1 135.3 109.5 245.1 244.8 245.2h244.8v-245.1c.1-135.3-109.5-245.1-244.9-245.3.1 0 .1 0 0 0m0 654h-652.6c-135.3.1-244.9 109.9-244.8 245.2-.2 135.3 109.4 245.1 244.7 245.3h652.7c135.3-.1 244.9-109.9 244.8-245.2.1-135.4-109.5-245.2-244.8-245.3z"
              fill="#36c5f0"
            />
            <path
              d="m2447.6 899.2c.1-135.3-109.5-245.1-244.8-245.2-135.3.1-244.9 109.9-244.8 245.2v245.3h244.8c135.3-.1 244.9-109.9 244.8-245.3zm-652.7 0v-654c.1-135.2-109.4-245-244.7-245.2-135.3.1-244.9 109.9-244.8 245.2v654c-.2 135.3 109.4 245.1 244.7 245.3 135.3-.1 244.9-109.9 244.8-245.3z"
              fill="#2eb67d"
            />
            <path
              d="m1550.1 2452.5c135.3-.1 244.9-109.9 244.8-245.2.1-135.3-109.5-245.1-244.8-245.2h-244.8v245.2c-.1 135.2 109.5 245 244.8 245.2zm0-654.1h652.7c135.3-.1 244.9-109.9 244.8-245.2.2-135.3-109.4-245.1-244.7-245.3h-652.7c-135.3.1-244.9 109.9-244.8 245.2-.1 135.4 109.4 245.2 244.7 245.3z"
              fill="#ecb22e"
            />
            <path
              d="m0 1553.2c-.1 135.3 109.5 245.1 244.8 245.2 135.3-.1 244.9-109.9 244.8-245.2v-245.2h-244.8c-135.3.1-244.9 109.9-244.8 245.2zm652.7 0v654c-.2 135.3 109.4 245.1 244.7 245.3 135.3-.1 244.9-109.9 244.8-245.2v-653.9c.2-135.3-109.4-245.1-244.7-245.3-135.4 0-244.9 109.8-244.8 245.1 0 0 0 .1 0 0"
              fill="#e01e5a"
            />
          </g>
        </svg>
      </SvgIcon>
    );
  }

  return (
    <span>
      {loading ? (
        <span>
          <LinearProgress />
          loading...
        </span>
      ) : (
        <span>
          <Card variant="outlined" sx={{ minWidth: 325 }}>
            <CardContent>
              <Typography
                style={{
                  fontWeight: 'bold',
                  fontStyle:
                    person.okta_status === 'DEPROVISIONED'
                      ? 'italic'
                      : 'normal',
                }}
                variant="h5"
                component="div"
                gutterBottom
              >
                {person.full_name || '(User not found)'}
                {person.okta_status === 'DEPROVISIONED' && (
                  <span style={{ color: 'red' }}> (Deprovisioned)</span>
                )}
                {person.okta_status !== 'DEPROVISIONED' && person.slack_id && (
                  <span>
                    {person.slack_id && (
                      <Button
                        startIcon={<SlackIcon />}
                        target="_blank"
                        rel="noreferrer"
                        href={`https://gopuffsquad.slack.com/team/${person.slack_id}`}
                      />
                    )}
                    <Button
                      startIcon={<EmailIcon />}
                      target="_blank"
                      rel="noreferrer"
                      href={`mailto:${person.email}`}
                    />
                    {person.pagerduty_id && (
                      <Button
                        startIcon={
                          <PagerdutyIcon
                            size="small"
                            sx={{
                              width: 24,
                              height: 24,
                            }}
                          />
                        }
                        target="_blank"
                        rel="noreferrer"
                        href={`https://gopuff.pagerduty.com/users/${person.pagerduty_id}`}
                      />
                    )}
                    {person.github_user && (
                      <Button
                        startIcon={<GitHubIcon />}
                        target="_blank"
                        rel="noreferrer"
                        href={`https://github.com/${person.github_user}`}
                      />
                    )}
                  </span>
                )}
              </Typography>
              {person.okta_status !== 'DEPROVISIONED' && (
                <Typography variant="body2" component="div">
                  <Avatar
                    alt={person.full_name}
                    src={person.slack_photo}
                    sx={{ width: 140, height: 140 }}
                  />
                </Typography>
              )}
              <BasicTable>
                <TableRow>
                  <TableCell>First name </TableCell>
                  <TableCell>{person.first_name || 'N/A'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Last name </TableCell>
                  <TableCell>{person.last_name || 'N/A'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Title </TableCell>
                  <TableCell>{person.title || 'N/A'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email </TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      startIcon={<EmailIcon />}
                      target="_blank"
                      rel="noreferrer"
                      href={`mailto:${person.email}`}
                    >
                      {person.email}
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Team</TableCell>
                  <TableCell>
                    {person.engineering_teams &&
                      person.engineering_teams?.map((t, i) => (
                        <Button
                          size="small"
                          key={i}
                          startIcon={<Groups />}
                          component={RLink}
                          to={`/team/${t}`}
                        >
                          {t || '-'}
                        </Button>
                      ))}
                    {!person.engineering_teams && 'N/A'}
                  </TableCell>
                </TableRow>
                {person.manager && (
                  <TableRow>
                    <TableCell>Manager</TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        sx={{ padding: 0 }}
                        startIcon={<Person />}
                        component={RLink}
                        to={`/person/${person.manager?.replace(
                          '@gopuff.com',
                          ''
                        )}`}
                      >
                        {person.manager
                          ?.replace('@gopuff.com', '')
                          .replace('.', ' ') || 'N/A'}
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
                {person.engineering_team_manager && (
                  <TableRow>
                    <TableCell>Engineering manager of</TableCell>
                    <TableCell>
                      {person.engineering_team_manager?.map((t, i) => (
                        <Button
                          key={i}
                          startIcon={<Groups />}
                          component={RLink}
                          to={`/team/${t}`}
                        >
                          {t}
                        </Button>
                      ))}
                    </TableCell>
                  </TableRow>
                )}
                {person.director_eng_teams && (
                  <TableRow>
                    <TableCell>Director of</TableCell>
                    <TableCell>
                      {person.director_eng_teams?.map((t, i) => (
                        <Button
                          key={i}
                          startIcon={<Groups />}
                          component={RLink}
                          to={`/team/${t}`}
                        >
                          {t}
                        </Button>
                      ))}
                    </TableCell>
                  </TableRow>
                )}
                {person.vp_eng_teams && (
                  <TableRow>
                    <TableCell>VP of</TableCell>
                    <TableCell>
                      {person.vp_eng_teams?.map((t, i) => (
                        <Button
                          key={i}
                          startIcon={<Groups />}
                          component={RLink}
                          to={`/team/${t}`}
                        >
                          {t}
                        </Button>
                      ))}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>Domain </TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      color="primary"
                      variant="outlined"
                      label={person.domain_from_engineering_teams || 'N/A'}
                    />
                  </TableCell>
                </TableRow>
                {person.github_user && (
                  <TableRow>
                    <TableCell>Github Username</TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        startIcon={<GitHubIcon />}
                        component={Link}
                        target="_blank"
                        href={`https://github.com/${person.github_user}`}
                      >
                        {person.github_user}
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
                {person.github_teams && (
                  <TableRow>
                    <TableCell>Github Teams</TableCell>
                    <TableCell>
                      {person.github_teams?.map((s, i) => (
                        <Button
                          key={i}
                          size="small"
                          startIcon={<GitHubIcon />}
                          component={Link}
                          target="_blank"
                          href={`https://github.com/orgs/gopuff/teams/${s}`}
                        >
                          {s}
                        </Button>
                      ))}
                    </TableCell>
                  </TableRow>
                )}
                {schedules && schedules.length > 0 && (
                  <TableRow>
                    <TableCell>Pagerduty Schedules</TableCell>
                    <TableCell>
                      {schedules?.map((s, i) => (
                        <Button
                          key={i}
                          size="small"
                          startIcon={<PagerdutyIcon />}
                          component={Link}
                          href={`https://gopuff.pagerduty.com/schedules/${s.id}`}
                        >
                          {s.name}
                        </Button>
                      ))}
                    </TableCell>
                  </TableRow>
                )}
                {escalations && escalations.length > 0 && (
                  <TableRow>
                    <TableCell>Pagerduty Escalations</TableCell>
                    <TableCell>
                      {escalations?.map((s, i) => (
                        <Button
                          key={i}
                          size="small"
                          sx={{ padding: 0 }}
                          startIcon={<PagerdutyIcon />}
                          component={Link}
                          href={`https://gopuff.pagerduty.com/escalation_policies/${s.id}`}
                        >
                          {s.name}
                        </Button>
                      ))}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>Employee since</TableCell>
                  <TableCell>
                    {person.start_date || 'N/A'} ({joined_date})
                  </TableCell>
                </TableRow>
              </BasicTable>
            </CardContent>
          </Card>
        </span>
      )}
    </span>
  );
}
