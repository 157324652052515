import { formatDistanceToNow } from 'date-fns';

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => -descendingComparator(a, b, orderBy)
    : (a, b) => descendingComparator(a, b, orderBy);
}

// Alpha-Numeric sort
export function descendingComparator(a, b, orderBy) {
  const prev = a[orderBy] ? a[orderBy].toString() : '';
  const curr = b[orderBy] ? b[orderBy].toString() : '';

  return prev.localeCompare(curr, undefined, {
    numeric: true,
    sensitivity: 'base',
  });
}

/** @param {string} dateString */
export function parseDateWithoutYear(dateString) {
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const today = new Date();
  const targetDay = daysOfWeek.indexOf(dateString.substr(0, 3));
  const targetTime = dateString.substr(4);

  const nextDate = new Date();
  nextDate.setUTCHours(parseInt(targetTime.substr(0, 2)));
  nextDate.setUTCMinutes(parseInt(targetTime.substr(3)));
  nextDate.setUTCSeconds(0);
  nextDate.setUTCMilliseconds(0);

  while (nextDate.getUTCDay() !== targetDay) {
    nextDate.setUTCDate(nextDate.getUTCDate() + 1);
  }

  if (nextDate < today) {
    nextDate.setUTCDate(nextDate.getUTCDate() + 7);
  }

  return nextDate;
}

/**
 * @param {string} targetDate
 * @returns {any}
 */
export function getRemainingTime(targetDate) {
  const targetUTCDate = new Date(parseDateWithoutYear(targetDate));
  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = targetUTCDate.getTime() - currentDate.getTime();

  // Calculate the remaining days, hours, and minutes
  const remainingDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const remainingHours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const remainingMinutes = Math.floor(
    (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
  );

  return {
    days: remainingDays,
    hours: remainingHours,
    minutes: remainingMinutes,
  };
}

/**
 * @param {Date} utcDate
 * @returns {string}
 */
export function convertUTCtoLocal(utcDate) {
  // Create a new Date object with the UTC date
  var date = new Date(utcDate);

  // Get the timezone offset in minutes
  var offset = date.getTimezoneOffset();

  // Adjust the date by adding the offset in minutes
  date.setMinutes(date.getMinutes() - offset);

  // Return the adjusted date
  return String(date);
}

/**
 * @param {string} string
 * @returns
 */
export function findMinMax(string) {
  // Split the string into an array of values
  const values = string.split(',');

  // Initialize min and max variables with the first value in the array
  let min = Number(values[0]);
  let max = Number(values[0]);

  // Iterate through the values and update min and max if necessary
  for (let i = 1; i < values.length; i++) {
    const currentValue = Number(values[i]);
    if (currentValue < min) {
      min = currentValue;
    }
    if (currentValue > max) {
      max = currentValue;
    }
  }
  // Return the min and max values as an object
  return { min, max };
}

/**
 * @param {number} num
 * @param {number} digits
 * @returns {string}
 */
export function nFormatter(num, digits) {
  const lookup = [
    { value: 1e18, symbol: 'E' },
    { value: 1e15, symbol: 'P' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'G' },
    { value: 1e6, symbol: 'M' },
    { value: 1e3, symbol: 'k' },
    { value: 1, symbol: '' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().find(function (item) {
    return num >= item.value;
  });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
}

/**
 * @param {string} dateString
 * @returns {string}
 */
export function relativeDateFormat(dateString) {
  return formatDistanceToNow(new Date(dateString), {
    includeSeconds: true,
  });
}

/**
 * @deprecated Use relativeDateFormat() instead
 * @param {string} created_ts
 * @returns {string}
 */
export function timeAgo(created_ts) {
  const today = new Date();
  const createdOn = new Date(created_ts);
  const msInDay = 24 * 60 * 60 * 1000;
  const msInMin = 60 * 1000;
  const msInHr = 60 * 60 * 1000;
  const diff = +today - +createdOn;
  const days = diff / msInDay;
  const mins = diff / msInMin;
  const hrs = diff / msInHr; // Math.round(days.toFixed(2)*24)
  if (days > 1) {
    return `${days.toFixed(0)}d`;
  }
  if (hrs > 1) {
    return `${Math.round(Number(hrs.toFixed(2)))}hr(s)`;
  }
  return `${Math.round(Number(mins.toFixed(2)))}mins`;
}
