// @ts-nocheck
import {
  InputLabel,
  NativeSelect,
  TextField,
  Alert,
  AlertTitle,
  Button,
  LinearProgress,
  Typography,
} from '@mui/material';
import React from 'react';
import { useParams } from 'react-router-dom';
import PolylineIcon from '@mui/icons-material/Polyline';
import { useAuth } from 'oidc-react';
import usePerson from '../hooks/usePerson';
import useTemplates from '../hooks/useTemplates';
import Config from '../shared/Config';

const { apiBaseUrl } = Config();

export default function TemplatePage(params) {
  const auth = useAuth();
  const { email } = auth.userData.profile;
  const { person, loading: personLoading } = usePerson(email);
  const [success, setSuccess] = React.useState('');
  const [failed, setFailed] = React.useState('');
  const [wf_running, launchWorkflow] = React.useState(false);
  const { name, templatename } = useParams();
  const { items, loading } = useTemplates();
  let current;
  let team = '';
  if (person && person['github_teams']) {
    team = person['github_teams'][0];
  }
  if (!loading) {
    current = items.find(
      (item) =>
        item.filename.replace('.yml', '').replace('.yaml', '') ===
          templatename && item.name == name
    );
  }
  return (
    <div>
      {loading ? (
        <div>
          <Typography variant="h4" component="h1" sx={{ p: 1.5 }}>
            Fetching {templatename}
          </Typography>
          <LinearProgress />
        </div>
      ) : (
        <div>
          <Typography variant="h4" component="h1" sx={{ p: 1.5 }}>
            {current.name}
          </Typography>
          <Typography component="h6" sx={{ marginBottom: 5 }}>
            {current.filename}
          </Typography>
        </div>
      )}
      {!loading && !personLoading && (
        <div>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              launchWorkflow(true);
              setSuccess('');
              setFailed('');
              const { repo } = current;
              const inputs = Object.keys(current.inputs).reduce(
                (acc, key) => ({
                  ...acc,
                  [key]: document.getElementById(`field_${key}`).value,
                }),
                {}
              );
              let data = {};
              if (repo == 'sre-service-generator') {
                data = {
                  template: templatename,
                  name: name,
                  repo: repo,
                  inputs: {
                    context: JSON.stringify(inputs),
                    project_name: inputs.project_name,
                    template: name,
                    team: team,
                  },
                };
              } else {
                data = {
                  template: templatename,
                  name: name,
                  repo: repo,
                  inputs,
                };
              }
              const headers = {
                'Content-Type': 'application/json',
                Accept: 'application/json',
              };
              const access_token = window.sessionStorage.getItem('token');
              headers.Authorization = `Bearer ${access_token}`;
              const endpointUrl = `${apiBaseUrl}/api/template`;

              fetch(endpointUrl, {
                method: 'POST',
                headers,
                body: JSON.stringify(data),
              })
                .then((response) => {
                  if (response.ok) {
                    setSuccess('Yes');
                    launchWorkflow(true);
                  } else {
                    response.json().then((data) => {
                      setFailed(data.message);
                    });
                    launchWorkflow(false);
                  }
                })
                .catch((error) => {
                  console.error('Error:', error);
                  setFailed('error contacting puffdirectory API');
                  launchWorkflow(false);
                });
            }}
          >
            {Object.keys(current.inputs).map((key) => (
              <div key={key}>
                {current.inputs[key].type === 'choice' ? (
                  <div>
                    <InputLabel id={`form-label-${key}`}>{key}</InputLabel>
                    <NativeSelect
                      labelid={`form-label-${key}`}
                      id={`field_${key}`}
                      defaultValue={
                        !personLoading && person?.engineering_teams[0]
                      }
                      label={key}
                      fullWidth
                      sx={{ marginBottom: 5 }}
                    >
                      {current.inputs[key].options.map((choice) => (
                        <option key={choice} value={choice}>
                          {choice}
                        </option>
                      ))}
                    </NativeSelect>
                  </div>
                ) : (
                  <div>
                    <InputLabel id={`form-label-${key}`}>{key}</InputLabel>
                    <TextField
                      label={current.inputs[key].description}
                      required={current.inputs[key].required}
                      defaultValue={current.inputs[key].default}
                      variant="standard"
                      size="small"
                      fullWidth
                      id={`field_${key}`}
                      sx={{ marginBottom: 5 }}
                    />
                  </div>
                )}
              </div>
            ))}

            {!success && (
              <div>
                {wf_running && <LinearProgress />}
                <Button
                  type="submit"
                  disabled={wf_running}
                  variant="contained"
                  size="large"
                  fullWidth
                  startIcon={<PolylineIcon />}
                >
                  {templatename.includes('create')
                    ? 'Create Service'
                    : 'Run Workflow'}
                </Button>
                {wf_running && <LinearProgress />}
              </div>
            )}
          </form>
          {success && (
            <Alert severity="success" sx={{ marginTop: 2 }}>
              <AlertTitle>Success</AlertTitle>
              <strong>Template created successfully !</strong>{' '}
              <a
                href={`https://github.com/gopuff/${current.repo}/actions/workflows/${current.filename}`}
              >
                Check the workflow status here.
              </a>
            </Alert>
          )}
          {failed && (
            <Alert severity="error">
              <AlertTitle>Failed</AlertTitle>
              Error when creating template : {failed}{' '}
            </Alert>
          )}
        </div>
      )}
    </div>
  );
}
