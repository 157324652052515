import { Box, Link } from '@mui/material';
import React from 'react';

export default function AboutPage() {
  return (
    <span>
      <Box sx={{ my: 3 }}>
        Please go to
        <Link
          href="https://gopuff.atlassian.net/wiki/spaces/GD/pages/2668068893/PuffDirectory+Overview"
          target="_blank"
          rel="noreferrer"
          sx={{ mx: 1 }}
        >
          PuffDirectory Overview
        </Link>
        for more info.
      </Box>
    </span>
  );
}
