// @ts-nocheck
// https://github.com/vasturiano/react-force-graph
import React, { Component, useRef } from 'react';
import ForceGraph2D from 'react-force-graph-2d';
import { List, ListItemText, Grid, Button, Typography } from '@mui/material';
import { Settings } from '@mui/icons-material';
import { statusColor, resourceLifecycleMap } from '../shared/serviceHandler';
import { Link } from 'react-router-dom';
import ResourceList from '../components/ResourceList';

function Graph(data) {
  const height = data.data.nodes.length * 9.67 + 180;
  const fgRef = useRef();
  return (
    <ForceGraph2D
      ref={fgRef}
      graphData={data.data}
      cooldownTicks={100}
      height={height}
      backgroundColor="#fff"
      width={800}
      dagMode="lr"
      dagLevelDistance={60}
      onEngineStop={() => fgRef.current.zoomToFit(100)}
      linkDirectionalParticles={1}
      linkDirectionalParticleSpeed={(d) => (5 - d.tier) * 0.001}
      linkDirectionalParticleWidth={1}
      nodeRelSize={20}
      linkDirectionalArrowLength={2}
      linkDirectionalArrowRelPos={0.5}
      nodeCanvasObject={(node, ctx, globalScale) => {
        const label = node.id;
        const fontSize = 10 / globalScale;
        ctx.font = `${fontSize}px Sans-Serif`;
        const textWidth = ctx.measureText(label).width;
        const bckgDimensions = [textWidth, fontSize].map(
          (n) => n + fontSize * 0.1
        ); // some padding
        ctx.fillStyle = 'rgba(255, 255, 255, 1)';
        ctx.fillRect(
          node.x - bckgDimensions[0] / 2,
          node.y - bckgDimensions[1] / 2,
          ...bckgDimensions
        );
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = node.color;
        ctx.fillText(label, node.x, node.y);
        node.__bckgDimensions = bckgDimensions; // to re-use in nodePointerAreaPaint
      }}
      nodePointerAreaPaint={(node, color, ctx) => {
        ctx.fillStyle = color;
        const bckgDimensions = node.__bckgDimensions;
        bckgDimensions &&
          ctx.fillRect(
            node.x - bckgDimensions[0] / 2,
            node.y - bckgDimensions[1] / 2,
            ...bckgDimensions
          );
      }}
    />
  );
}

class DependencyComponent extends Component {
  componentDidMount() {}

  render() {
    const upstream_arr = [];
    const upstream_arr_uat = [];
    const data = { nodes: [], links: [] };
    const data_uat = { nodes: [], links: [] };
    const { service } = this.props;
    const { env } = this.props;
    let env_var = '';
    if (env === 'uat') {
      env_var = '_uat';
    }
    const filteredItems = service.infra_dependencies?.filter((item) => {
      const lifecycle = resourceLifecycleMap[env];
      return item.lifecycle === lifecycle;
    });
    if (service) {
      const thisService = service.service_name || 'unknown';
      data.nodes.push({
        id: thisService,
        group: 2,
        color: 'red',
        value: service.tier,
      });
      // UAT
      data_uat.nodes.push({
        id: thisService,
        group: 2,
        color: 'red',
        value: service.tier,
      });
      for (const key in service.upstream_uat) {
        upstream_arr_uat.push(service.upstream_uat[key].service_name);
        data_uat.nodes.push({
          id: service.upstream_uat[key].service_name,
          group: 4,
          color: 'blue',
          tier: service.tier,
        });
        data_uat.links.push({
          source: service.upstream_uat[key].service_name,
          target: thisService,
          value: 1,
          tier: service.upstream_uat[key].tier,
        });
      }
      for (const key in service.downstream_uat) {
        if (
          !upstream_arr_uat.includes(service.downstream_uat[key].service_name)
        ) {
          data_uat.nodes.push({
            id: service.downstream_uat[key].service_name,
            group: 6,
            color: 'green',
            tier: service.downstream_uat[key].tier,
          });
          data_uat.links.push({
            source: thisService,
            target: service.downstream_uat[key].service_name,
            value: 1,
            tier: service.downstream_uat[key].tier,
          });
        }
      }
      // PROD
      for (const key in service.upstream) {
        upstream_arr.push(service.upstream[key].service_name);
        data.nodes.push({
          id: service.upstream[key].service_name,
          group: 4,
          color: 'blue',
          tier: service.tier,
        });
        data.links.push({
          source: service.upstream[key].service_name,
          target: thisService,
          value: 1,
          tier: service.upstream[key].tier,
        });
      }
      for (const key in service.downstream) {
        if (!upstream_arr.includes(service.downstream[key].service_name)) {
          data.nodes.push({
            id: service.downstream[key].service_name,
            group: 6,
            color: 'green',
            tier: service.downstream[key].tier,
          });
          data.links.push({
            source: thisService,
            target: service.downstream[key].service_name,
            value: 1,
            tier: service.downstream[key].tier,
          });
        }
      }
    }
    return (
      <Grid container spacing={0} columns={18}>
        <Grid item xs={5}>
          <List>
            <Typography variant="h6" component="div" gutterBottom>
              Upstream ({service[`upstream${env_var}`]?.length || 0})
            </Typography>
            {service[`upstream${env_var}`]
              ?.sort((a, b) => a.service_name.localeCompare(b.service_name))
              .map((s, idx) => (
                <ListItemText
                  primaryTypographyProps={{ fontSize: 12 }}
                  sx={{ margin: 0, padding: 0 }}
                  key={idx}
                >
                  <Button
                    size="small"
                    sx={{ margin: 0, padding: 0 }}
                    startIcon={
                      <Settings
                        sx={{
                          color: statusColor[s.status],
                          display: { xs: 'none', sm: 'block' },
                        }}
                      />
                    }
                    component={Link}
                    to={`/service/${s.service_name}`}
                  >
                    {s.service_name} ({s.tier || '-'})
                  </Button>
                </ListItemText>
              ))}
          </List>
        </Grid>
        <Grid item xs={5}>
          <List>
            <Typography variant="h6" component="div" gutterBottom>
              Downstream ({service[`downstream${env_var}`]?.length || 0})
            </Typography>
            {service[`downstream${env_var}`]
              ?.sort((a, b) => a.service_name.localeCompare(b.service_name))
              .map((s, idx) => (
                <ListItemText
                  primaryTypographyProps={{ fontSize: 12 }}
                  sx={{ margin: 0, padding: 0 }}
                  key={idx}
                >
                  <Button
                    size="small"
                    sx={{ margin: 0, padding: 0 }}
                    startIcon={
                      <Settings
                        sx={{
                          color: statusColor[s.status],
                          display: { xs: 'none', sm: 'block' },
                        }}
                      />
                    }
                    component={Link}
                    to={`/service/${s.service_name}`}
                  >
                    {s.service_name} ({s.tier || '-'})
                  </Button>
                </ListItemText>
              ))}
          </List>
        </Grid>

        <Grid item xs={8}>
          <List>
            <Typography variant="h6" component="div" gutterBottom>
              Infrastructure ({filteredItems?.length || 0})
            </Typography>
            <ResourceList items={service.infra_dependencies} env={env} />
          </List>
        </Grid>
        <div id="graph">
          {env === 'uat' ? <Graph data={data_uat} /> : <Graph data={data} />}
        </div>
      </Grid>
    );
  }
}

export default DependencyComponent;
