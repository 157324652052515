import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import useFeedback from '../hooks/useFeedback';

function FeedbackForm({ fullName }) {
  const { response, sendFeedback } = useFeedback();
  const [formVisible, setFormVisible] = useState(false);
  const [name, setName] = useState('');
  const [anonymous, setAnonymous] = useState(false);
  const [message, setMessage] = useState('');
  const [validationResponse, setValidationResponse] = useState('');
  const [validationColor, setValidationColor] = useState('red');
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const windowWidth = useRef([window.innerWidth]);
  const style = {
    right: 24,
    bottom: 24,
    position: 'fixed',
    p: 2,
  };
  const textStyle = {
    width: '100%',
    mt: 2,
    fontSize: 12,
  };
  const handleChange = (e, setFunction) => {
    setFunction(e.target.value);
  };
  // Need to use effect since response from feedback is async
  useEffect(() => {
    if (!response.status) {
      setValidationResponse(() => '');
    } else if (response.status === 200 && response.data.status === 'ok') {
      setValidationResponse(() => 'Your request was submitted!');
      setValidationColor(() => 'green');
      setMessage(() => '');
    } else {
      setValidationResponse(
        () =>
          'There was an error submitting your request. Please try again later.'
      );
      setValidationColor(() => 'red');
    }
  }, [response]);
  // Need to use effect for rendering name
  useEffect(() => {
    setName(fullName);
  }, [fullName]);

  return (
    <>
      {formVisible && (
        <Card
          component="form"
          sx={{
            ...style,
            backgroundColor: prefersDarkMode ? 'black' : 'white',
            bottom: 120,
            border: '1px solid grey',
            borderRadius: 1,
            width:
              windowWidth.current[0] > 600 ? 400 : windowWidth.current[0] / 2,
            zIndex: '1',
          }}
        >
          <Typography>Suggest a feature!</Typography>
          <TextField
            disabled
            onChange={(e) => handleChange(e, setName)}
            label="Name"
            sx={textStyle}
            value={anonymous ? 'Anonymous' : name}
          />
          <FormControlLabel
            control={<Checkbox onClick={() => setAnonymous(!anonymous)} />}
            label="Be anonymous"
          />
          <TextField
            multiline
            onChange={(e) => handleChange(e, setMessage)}
            label="Feature Request"
            required
            rows={6}
            sx={textStyle}
            value={message}
          />
          <Button
            sx={{ ...textStyle, border: 1 }}
            onClick={() => {
              const currentName = anonymous ? 'Anonymous' : name;
              if (!currentName) {
                setValidationResponse(
                  '*Please set your name before submitting a request.'
                );
                setValidationColor('red');
              } else if (!message) {
                setValidationResponse(
                  '*Please make a feature request before submitting.'
                );
                setValidationColor('red');
              } else {
                sendFeedback({ name: currentName, message });
              }
            }}
          >
            Submit Request
          </Button>
          <label
            style={{
              fontSize: 14,
              visibility: validationResponse ? 'visible' : 'hidden',
              color: validationColor,
            }}
          >
            {validationResponse}
          </label>
        </Card>
      )}
      <Button
        onClick={() => setFormVisible(!formVisible)}
        sx={{ ...style, border: '1px solid grey' }}
      >
        {formVisible ? (
          <CloseIcon sx={{ fontSize: 38, color: '#0099FF' }} />
        ) : (
          <ChatBubbleOutlineRoundedIcon
            sx={{ fontSize: 38, color: '#0099FF' }}
          />
        )}
      </Button>
    </>
  );
}

export default FeedbackForm;
