import React from 'react';
import {
  Link,
  Box,
  Button,
  Card,
  CardContent,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import OutboundIcon from '@mui/icons-material/Outbound';
import useResource from '../hooks/useResource';
import ResourceFields from '../components/ResourceFields';
import ResourceIcon from '../components/ResourceIcon';

export default function ResourcePage() {
  let external_url;
  const { name } = useParams();
  const { resource, loading } = useResource(name);
  if (!loading) {
    external_url = `https://portal.azure.com/#@gopuff.com/resource${resource.id}`;
  }
  return (
    <span>
      {loading ? (
        <span>
          <LinearProgress />
          loading resource <strong>{name}</strong>...
        </span>
      ) : (
        <span>
          <Card variant="outlined" sx={{ minWidth: 325 }}>
            <CardContent>
              <Box display="flex" alignItems="center" gap={1}>
                <ResourceIcon
                  name={resource.type}
                  sx={{
                    width: 32,
                    height: 32,
                    marginRight: 2,
                    p: 0.5,
                  }}
                />
                <Typography variant="h5" component="div" gutterBottom>
                  <b>{resource.name}</b>
                  {resource?.id && (
                    <Button
                      size="small"
                      endIcon={
                        <OutboundIcon
                          sx={{
                            marginLeft: -4,
                            width: 20,
                            height: 20,
                          }}
                        />
                      }
                      component={Link}
                      href={external_url}
                      target="blank"
                    />
                  )}
                </Typography>
              </Box>
              <ResourceFields resource={resource} />
            </CardContent>
          </Card>
          {/* <EntityDetails entity={resource} expanded={false}></EntityDetails> */}
        </span>
      )}
    </span>
  );
}
