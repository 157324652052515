// @ts-nocheck
import {
  LinearProgress,
  CardContent,
  Checkbox,
  Typography,
  Chip,
  FormControl,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import * as React from 'react';
// Timeline
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { zonedTimeToUtc } from 'date-fns-tz';
import { formatDistanceToNow } from 'date-fns';
import { useState } from 'react';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import GradingIcon from '@mui/icons-material/Grading';
import NotificationImportantSharpIcon from '@mui/icons-material/NotificationImportantSharp';
import useEvent from '../hooks/useEvents';

export default function EventPage(props) {
  const { service, environment } = props;
  // Filtering
  const [release_checked, setReleaseChecked] = useState(true);
  const [incident_checked, setIncidentChecked] = useState(true);
  const [audit_checked, setAuditChecked] = useState(true);
  const handleChecked = (event) => {
    if (event.target.id === 'releases') setReleaseChecked(event.target.checked);
    if (event.target.id === 'incidents')
      setIncidentChecked(event.target.checked);
    if (event.target.id === 'audits') setAuditChecked(event.target.checked);
  };
  let event_type = [];
  // Append "hello" to event_type if release_checked is true
  if (release_checked) event_type.push('release');
  if (incident_checked) event_type.push('incident');
  if (audit_checked) event_type.push('audit');
  if (event_type.length > 0) {
    event_type = event_type.join(',');
  } else {
    event_type = null;
  }

  const { event, ev_loading } = useEvent(service.service_name, event_type);
  const [filteredEvents, setFilteredEvents] = useState([]);

  React.useEffect(() => {
    if (event.events) {
      const filtered = event.events.filter(
        (ev) => ev.metadata.env === environment
      );
      setFilteredEvents(filtered);
    }
  }, [environment, event.events]);

  function extract_info(data) {
    // RELEASE
    if (data.event_type === 'release') {
      let rollback_str = '';
      if (data.metadata.rollback === 'yes') {
        rollback_str = (
          <pre style={{ fontSize: '12px', color: 'red' }}>
            {' '}
            🚨 Rollback deployment
          </pre>
        );
      }
      let { version } = data.metadata;
      if (data.metadata.version && data.metadata.version.length > 20) {
        version = `${data.metadata.version.slice(0, 20)}...`;
      }
      return (
        <span>
          <strong>Release</strong>{' '}
          <Chip
            size="small"
            color="primary"
            variant="outlined"
            label={data.metadata.env?.toUpperCase() || 'N/A'}
          />{' '}
          <small>{version}</small>
          {rollback_str}
        </span>
      );
    }
    // AUDIT
    if (data.event_type === 'audit') {
      let k = Object.keys(data.metadata)[0];
      if (k === 'user') {
        k = Object.keys(data.metadata)[1];
      }
      let txt = data.metadata[k];
      if (data.metadata[k].length > 57) {
        txt = `${data.metadata[k].slice(0, 57)}...`;
      }
      return (
        <span>
          <strong>Audit</strong>{' '}
          <Chip
            size="small"
            color="primary"
            variant="outlined"
            label={data.metadata.user.replace('@gopuff.com', '')}
          />{' '}
          <pre style={{ whiteSpace: 'pre-wrap', fontSize: '10px' }}>
            {k} &gt; {txt}
          </pre>
        </span>
      );
    }
    // INCIDENT
    if (data.event_type === 'incident') {
      let { title } = data.metadata;
      if (data.metadata.title.length > 58) {
        title = `${data.metadata.title.slice(0, 58)}...`;
      }
      const inc_url = `https://gopuffsquad.slack.com/channels/incident-${data.metadata.inc_id}`;
      return (
        <span>
          <strong>Incident</strong> {data.metadata.severity} (
          <a href={inc_url}>#incident-{data.metadata.inc_id}</a>)
          <pre style={{ fontSize: '12px' }}>
            <Chip
              size="small"
              color="success"
              variant="outlined"
              label={data.metadata.status}
            />
            <br />
            {title}
            <br />
            <strong>Impact</strong>:{' '}
            {Math.round(parseInt(data.metadata.impact) / 60)} min
          </pre>
        </span>
      );
    }
    return data.event_type;
  }
  // Tabs
  const timelineIcon = {
    release: <WysiwygIcon color="secondary" />,
    audit: <GradingIcon />,
    incident: <NotificationImportantSharpIcon />,
  };
  const timelineIconColor = {
    release: '#00a4ff',
    audit: '#0c7744',
    incident: '#b00201',
  };

  return (
    <span>
      <CardContent style={{ padding: '5px' }}>
        <span style={{ paddingLeft: '10px', paddingBottom: '10px' }}>
          Summary of Releases, Incidents, Audits for the service, all times are
          in EST.
        </span>
        <br />
        <FormControl sx={{ pl: 1 }} component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              value="top"
              control={
                <Checkbox
                  defaultChecked
                  id="releases"
                  onChange={handleChecked}
                />
              }
              label="Releases"
              size="small"
              labelPlacement="end"
              disabled={ev_loading}
            />
            <FormControlLabel
              value="start"
              control={
                <Checkbox
                  defaultChecked
                  id="incidents"
                  onChange={handleChecked}
                />
              }
              label="Incidents"
              labelPlacement="end"
              disabled={ev_loading}
            />
            <FormControlLabel
              value="bottom"
              control={
                <Checkbox defaultChecked id="audits" onChange={handleChecked} />
              }
              label="Audits"
              labelPlacement="end"
              disabled={ev_loading}
            />
          </FormGroup>
        </FormControl>
        {ev_loading ? (
          <p>
            Pulling events for {service.service_name} <LinearProgress />
          </p>
        ) : (
          <span>
            <Timeline
              sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                  flex: 0.4,
                },
              }}
            >
              {filteredEvents?.length === 0 ? (
                <span>(No events found for {service.service_name})</span>
              ) : (
                filteredEvents?.map((t, i) => (
                  <span key={i}>
                    <TimelineItem>
                      <TimelineOppositeContent
                        sx={{ fontSize: '.8em', p: 1 }}
                        color="textSecondary"
                      >
                        {formatDistanceToNow(zonedTimeToUtc(t.created, 'UTC'))}{' '}
                        ago{' '}
                        <Typography sx={{ fontSize: '.8em' }}>
                          [
                          {`${new Date(
                            zonedTimeToUtc(t.created, 'UTC')
                          ).toLocaleDateString()} ${new Date(
                            zonedTimeToUtc(t.created, 'UTC')
                          ).toLocaleTimeString()}`}
                          ]
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot
                          sx={{
                            backgroundColor: timelineIconColor[t.event_type],
                          }}
                        >
                          {timelineIcon[t.event_type]}
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ p: 1 }}>
                        {extract_info(t)}
                      </TimelineContent>
                    </TimelineItem>
                  </span>
                ))
              )}
            </Timeline>
          </span>
        )}
      </CardContent>
    </span>
  );
}
