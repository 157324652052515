import React from 'react';
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
  useMediaQuery,
} from '@mui/material';
import { red } from '@mui/material/colors';

export function ThemeProvider({ children }) {
  const runEnv = process.env.REACT_APP_CONFIG;
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  /** @type {ThemeOptions} */
  const defaultThemeConfig = {
    palette: {
      mode: prefersDarkMode ? 'dark' : 'light',
      primary: {
        main: runEnv === 'prod' ? '#00A4FF' : '#148f76',
      },
      secondary: {
        main: '#ffffff',
      },
      error: {
        main: red.A400,
      },
    },
  };
  const theme = createTheme(defaultThemeConfig);

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
