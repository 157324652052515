import React from 'react';
import { Link as RLink } from 'react-router-dom';
import {
  CircularProgress,
  ListItemButton,
  ListItemIcon,
  Button,
  TableCell,
  TableRow,
  List,
  ListItemText,
} from '@mui/material';
import { BasicTable } from './BasicTable';
import ResourceIcon from './ResourceIcon';
import { resourceLifecycleMap } from '../shared/serviceHandler';
import { Link } from 'react-router-dom';

function ResourceList({ loading = false, items, env }) {
  const filteredItems = items?.filter((item) => {
    const lifecycle = resourceLifecycleMap[env];
    return item.lifecycle === lifecycle;
  });

  return filteredItems?.map((resource, i) => (
    <ListItemText
      primaryTypographyProps={{ fontSize: 12 }}
      sx={{ margin: 0, padding: 0 }}
      key={i}
    >
      <Button
        size="small"
        sx={{ margin: 0, padding: 0 }}
        startIcon={
          <ResourceIcon name={resource.type} sx={{ width: 20, height: 20 }} />
        }
        component={Link}
        to={`/resource/${resource.name}`}
      >
        {resource.name}
      </Button>
    </ListItemText>
  ));
}

export default ResourceList;
