import { Card, LinearProgress, TextField, Typography } from '@mui/material';
import React from 'react';
import SortableTable from '../components/SortableTable';
import usePeople from '../hooks/usePeople';

const columns = [
  {
    id: 'username',
    display: 'full_name',
    route: true,
    resource: 'person',
    numeric: false,
    disablePadding: false,
    label: 'Full Name',
  },
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: 'Title',
  },
  {
    id: 'engineering_teams',
    route: true,
    resource: 'team',
    numeric: false,
    hide_mobile: true,
    disablePadding: false,
    label: 'Team',
  },
];

export default function PeoplePage() {
  const { items, loading } = usePeople();
  const itemsFiltered = items.filter(
    (i) => i.email !== 'ryan.drummer@gopuff.com'
  );
  const [searchItems, setSearchItems] = React.useState([]);
  const [searchActive, setSearchActive] = React.useState(false);

  const handleInputChange = async (e) => {
    const len = e.target.value.length;
    if (e.target.value === '') {
      console.log('clear input', e.target.value);
      setSearchActive(false);
      setSearchItems([]);
    }
    if (len > 1) {
      setSearchActive(true);
      console.log('search:', e.target.value);
      const filtered = itemsFiltered.filter(
        (i) =>
          i.full_name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0
      );
      console.log({ filtered });
      setSearchItems(filtered);
    }
  };

  return (
    <span>
      <Typography variant="h6" component="h1" sx={{ p: 1.5 }}>
        People ({items.length})
      </Typography>
      <Card style={{ padding: '0px' }} variant="outlined" sx={{ padding: 2 }}>
        <form noValidate autoComplete="off">
          <TextField
            size="small"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
            }}
            id="outlined-search"
            label="Filter people"
            type="search"
            variant="outlined"
            onChange={handleInputChange}
          />
        </form>
        {loading ? (
          <span>
            <LinearProgress />
            loading...
          </span>
        ) : (
          ''
        )}
        {searchItems?.length > 0 && (
          <span>
            <SortableTable id="people" rows={searchItems} columns={columns} />
          </span>
        )}
        {searchActive && searchItems?.length === 0 && (
          <div>No search results</div>
        )}
        {!searchActive ? (
          <SortableTable id="people" rows={itemsFiltered} columns={columns} />
        ) : null}
      </Card>
    </span>
  );
}
