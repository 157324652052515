import React from 'react';

// Copied svg from github main page
function SlashIcon(params) {
  const { color } = params;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      aria-hidden="true"
    >
      <path
        fill="none"
        stroke={color}
        opacity=".7"
        d="M3.5.5h12c1.7 0 3 1.3 3 3v13c0 1.7-1.3 3-3 3h-12c-1.7 0-3-1.3-3-3v-13c0-1.7 1.3-3 3-3z"
      />
      <path fill={color} d="M11.8 6L8 15.1h-.9L10.8 6h1z" />
    </svg>
  );
}

export default SlashIcon;
