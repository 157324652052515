import { useEffect, useState } from 'react';
import Config from '../shared/Config';

const { apiBaseUrl } = Config();
const endpointUrl = `${apiBaseUrl}/api/esearch`;
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const searchNameFn = {
  'tech-staff': (o) => o.source.full_name,
  services: (o) => o.source.service_name,
  dependencies: (o) => o.source.name,
  'engineering-team': (o) => o.source.team_name,
};

export const searchPathFn = {
  'tech-staff': (o) => `/person/${o.source.email?.split('@')[0]}`,
  services: (o) => `/service/${o.source.service_name}`,
  dependencies: (o) => `/resource/${o.source.name}`,
  'engineering-team': (o) => `/team/${o.source.team_name}`,
};

let cancel = new AbortController();

export function useSearch() {
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState([]);
  const [searchLoading, setLoading] = useState(false);
  const access_token = window.sessionStorage.getItem('token');
  headers.Authorization = `Bearer ${access_token}`;

  useEffect(() => {
    cancel && cancel.abort();
    cancel = new AbortController();
    search && setLoading(true);
    search &&
      fetch(`${endpointUrl}?q=${search}`, {
        signal: cancel.signal,
        headers,
      })
        .then((r) => r.json())
        .then((r) => {
          if (Array.isArray(r)) {
            // filter out and sort based on each index
            const teams = r
              .filter((i) => i.index === 'engineering-team')
              .sort((a, b) =>
                b.source.team_name?.localeCompare(a.source.team_name)
              );
            const svc = r.filter((i) => i.index === 'services');
            const people = r
              .filter((i) => i.index === 'tech-staff')
              .sort((a, b) =>
                a.source.last_name?.localeCompare(b.source.last_name)
              );
            const dependencies = r
              .filter((i) => i.index === 'dependencies')
              .filter((i) => i.source.lifecycle === 'Production')
              .sort((a, b) => b.source.type?.localeCompare(a.source.type));
            // Concatenate all the arrays, preserving the order
            const filtered = [].concat(teams, svc, people, dependencies);
            setOptions(filtered);
          }
          setLoading(false);
        })
        .catch((err) => console.log(err));
  }, [search]);

  return { searchLoading, options, search, setSearch, setOptions };
}
