import React from 'react';
import { Info } from '@mui/icons-material';
import {
  Button,
  Box,
  Card,
  CardContent,
  LinearProgress,
  TableCell,
  TableRow,
  List,
  ListItemText,
  ListItemIcon,
  Typography,
  Avatar,
  IconButton,
  Chip,
  Tooltip,
} from '@mui/material';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import WarningAmber from '@mui/icons-material/WarningAmber';
import { Link, useParams } from 'react-router-dom';
import { Settings } from '@mui/icons-material';
import { BasicTable } from '../components/BasicTable';
import useTeam from '../hooks/useTeam';
import { EditableField } from '../components/EditableField';
import { EditableArrayField } from '../components/EditableArrayField';
import { EditableLinkField } from '../components/EditableLinkField';
import { EditableSlackChannel } from '../components/EditableSlackChannel';
import { OnCall } from '../components/OnCall';

export default function TeamPage(params) {
  const { teamname } = useParams();
  const { team, loading, updateTeam } = useTeam(teamname);
  const statusColor = {
    Active: '#25b225',
    Experimental: '#ce44ec',
    Deprecated: '#da4103',
  };

  let score_breakdown = {};
  if (team.score_breakdown) {
    score_breakdown = team.score_breakdown;
  }
  let rules = [];
  if ('breakdown' in score_breakdown) {
    rules = score_breakdown.breakdown[0].rules.sort((a, b) => {
      return b.weight - a.weight;
    });
    rules = rules.filter((rule) => {
      return rule.score_contribution == 0;
    });
  }
  let score_color = 'black';
  if (team.total_score <= 25) {
    score_color = 'red';
  } else if (team.total_score <= 50) {
    score_color = 'orange';
  } else if (team.total_score <= 75) {
    score_color = 'green';
  }
  return (
    <span>
      {loading ? (
        <span>
          <LinearProgress />
          loading...
        </span>
      ) : (
        <span>
          <Card variant="outlined" sx={{ minWidth: 325 }}>
            <CardContent sx={{ p: 0 }}>
              <Typography
                sx={{ ml: 1 }}
                variant="h5"
                component="div"
                gutterBottom
              >
                <strong>{team.team_name}</strong>
              </Typography>
              <BasicTable>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell>
                    <EditableField
                      canEdit={team.can_edit}
                      item={team}
                      isMultiline
                      updateAction={updateTeam}
                      fieldname="team_description"
                      fieldvalue={team.team_description}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Keywords</TableCell>
                  <TableCell>
                    <EditableArrayField
                      canEdit={team.can_edit}
                      item={team}
                      updateAction={updateTeam}
                      fieldname="area_of_focus"
                      fieldvalue={team?.area_of_focus}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Team Slack</TableCell>
                  <TableCell>
                    <EditableSlackChannel
                      canEdit={team?.can_edit}
                      item={team}
                      updateAction={updateTeam}
                      fieldname="team_slack"
                      fieldvalue={team?.team_slack}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Alerts Channel</TableCell>
                  <TableCell>
                    <EditableSlackChannel
                      canEdit={team?.can_edit}
                      item={team}
                      updateAction={updateTeam}
                      fieldname="alerts_slack"
                      fieldvalue={team?.alerts_slack}
                    />
                    <Tooltip title="For notification purposes (with puff-notifications) we will use this channel to send notifications to the team. If this is not set, the 'Team Slack' slack channel is used as fallback.">
                      <IconButton color="primary" size="small">
                        <Info sx={{ width: 16, height: 16 }} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Wiki</TableCell>
                  <TableCell>
                    <EditableLinkField
                      canEdit={team?.can_edit}
                      item={team}
                      updateAction={updateTeam}
                      fieldname="wiki"
                      fieldvalue={team?.wiki}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Domain/Type</TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      color="success"
                      variant="outlined"
                      label={team.domain}
                    />
                    {team['sub-domain'] ? (
                      <Chip
                        size="small"
                        color="success"
                        variant="outlined"
                        label={team['sub-domain']}
                      />
                    ) : (
                      'N/A'
                    )}
                    <Chip
                      size="small"
                      color="success"
                      variant="outlined"
                      label={team.team_type}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Manager</TableCell>
                  <TableCell>
                    {Array.isArray(team.engineering_manager) ? (
                      <div>
                        {team.engineering_manager?.map((person) => (
                          <div key={person.email}>
                            <Button
                              startIcon={
                                <Avatar
                                  alt={person.name}
                                  src={person.slack_photo}
                                  sx={{
                                    width: 25,
                                    height: 25,
                                  }}
                                />
                              }
                              component={Link}
                              to={`/person/${person.email.replace(
                                '@gopuff.com',
                                ''
                              )}`}
                            >
                              {person?.name}
                            </Button>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <Button
                        startIcon={
                          <Avatar
                            alt={team.engineering_manager?.full_name}
                            src={team.engineering_manager?.slack_photo}
                            sx={{
                              width: 25,
                              height: 25,
                            }}
                          />
                        }
                        component={Link}
                        to={`/person/${team.engineering_manager?.email.replace(
                          '@gopuff.com',
                          ''
                        )}`}
                      >
                        {team.engineering_manager?.name || 'N/A'}
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
                {
                  <TableRow>
                    <TableCell>Score</TableCell>
                    <TableCell sx={{ color: `${score_color}` }}>
                      {team.total_score}/100
                    </TableCell>
                  </TableRow>
                }
                {rules.length > 0 ? (
                  <TableRow>
                    <TableCell>Errors & Warnings</TableCell>
                    <TableCell>
                      <List sx={{ padding: 0 }}>
                        {rules?.map((rule, i) => (
                          <ListItemText
                            primaryTypographyProps={{
                              fontSize: 14,
                            }}
                            key={i}
                          >
                            <ListItemIcon
                              style={{
                                top: '2px',
                                position: 'relative',
                                minWidth: '30px',
                              }}
                            >
                              <Tooltip title={`-${rule.weight * 100}`}>
                                {rule.weight > 0.15 ? (
                                  <ErrorOutline
                                    sx={{
                                      color: 'red',
                                      width: 13,
                                      height: 13,
                                    }}
                                  />
                                ) : (
                                  <WarningAmber
                                    sx={{
                                      color: 'orange',
                                      width: 13,
                                      height: 13,
                                    }}
                                  />
                                )}
                              </Tooltip>
                            </ListItemIcon>
                            {rule.name} is not set.
                          </ListItemText>
                        ))}
                      </List>
                    </TableCell>
                  </TableRow>
                ) : null}
              </BasicTable>
              <BasicTable>
                <TableRow>
                  <TableCell>
                    <b>People</b> ({team.eng_team_members?.length})
                  </TableCell>
                </TableRow>
                {team.eng_team_members?.map((p) => (
                  <TableRow key={p.email}>
                    <TableCell
                      sx={{
                        padding: '2px',
                      }}
                      colSpan={2}
                    >
                      <Button
                        startIcon={
                          <Avatar
                            alt={p.full_name}
                            src={p.slack_photo}
                            sx={{
                              width: 25,
                              height: 25,
                            }}
                          />
                        }
                        component={Link}
                        to={`/person/${p.email.replace('@gopuff.com', '')}`}
                      >
                        {p?.name}
                      </Button>
                      {p.title}
                    </TableCell>
                  </TableRow>
                ))}
              </BasicTable>
              <BasicTable>
                <TableRow>
                  <TableCell>
                    <b>Services</b> ({team.services ? team.services.length : 0})
                    and current <b>oncall</b>
                  </TableCell>
                </TableRow>
                {team.services
                  ?.sort((a, b) => {
                    const statusOrder = [
                      'Active',
                      'Experimental',
                      'Deprecated',
                    ];
                    const statusCompare =
                      statusOrder.indexOf(a.status) -
                      statusOrder.indexOf(b.status);
                    if (statusCompare !== 0) {
                      return statusCompare;
                    }
                    return a.service_name
                      .toLocaleLowerCase()
                      .localeCompare(b.service_name.toLocaleLowerCase());
                  })
                  .map((s, idx) => (
                    <TableRow key={idx}>
                      <TableCell
                        sx={{
                          padding: '0px',
                        }}
                        colSpan={2}
                      >
                        <Box display="flex" justifyContent="space-between">
                          <Box display="flex" justifyContent="flex-start">
                            <Button
                              startIcon={
                                <Settings
                                  sx={{
                                    color: statusColor[s.status],
                                  }}
                                />
                              }
                              component={Link}
                              to={`/service/${s.service_name}`}
                            >
                              {s.service_name} (<strong>{s.tier || '-'}</strong>
                              )
                            </Button>
                          </Box>
                          {s.status === 'Active' && (
                            <Box display="flex" justifyContent="flex-end">
                              {s.oncall?.map((person, idx) => (
                                <OnCall key={idx} person={person} />
                              ))}
                            </Box>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </BasicTable>
            </CardContent>
          </Card>
        </span>
      )}
    </span>
  );
}
