import React, { useState } from 'react';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import { BasicTable } from './BasicTable';

export function EntityDetails({ entity, expanded }) {
  const [open, setOpen] = useState(expanded);
  const textFields = Object.keys(entity).filter(
    (k) => typeof entity[k] === 'string'
  );
  const arrayFields = Object.keys(entity).filter((k) =>
    Array.isArray(entity[k])
  );

  return (
    <BasicTable>
      <TableRow onClick={() => setOpen(!open)}>
        <TableCell>
          <b>Details</b>
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <BasicTable>
                {textFields.map((k, i) => (
                  <TableRow key={`${k}_${i}`}>
                    <TableCell>{k.replace('_', ' ')}</TableCell>
                    <TableCell>{entity[k]}</TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell>
                    <b>Lists</b>
                  </TableCell>
                </TableRow>
                {arrayFields.map((k, i) => (
                  <TableRow key={`${k}_${i}`}>
                    <TableCell>{k}</TableCell>
                    <TableCell>{JSON.stringify(entity[k])}</TableCell>
                  </TableRow>
                ))}
              </BasicTable>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </BasicTable>
  );
}
