import React from 'react';
import { Groups, Language, Settings, Info } from '@mui/icons-material';
import {
  Link,
  Typography,
  Button,
  Chip,
  List,
  ListItemButton,
  ListItemIcon,
  TableCell,
  Tooltip,
  Box,
  IconButton,
  Grid,
  TableRow,
} from '@mui/material';

import { Link as RLink } from 'react-router-dom';
import OutboundIcon from '@mui/icons-material/Outbound';
import { BasicTable } from './BasicTable';
import ResourceIcon from './ResourceIcon';
import {
  parseDateWithoutYear,
  getRemainingTime,
  convertUTCtoLocal,
} from '../shared/data-utils';

/**
 * @param {object} Props
 * @param {any} Props.resource
 */
function ResourceFields({ resource }) {
  const statusColor = {
    Active: '#25b225',
    Experimental: '#ce44ec',
    Deprecated: '#da4103',
  };
  let maintenance = '';
  let maintenancelocaltime = '';
  resource.tags?.forEach((tag) => {
    const tagsplit = tag.split(':');
    if (tagsplit[0] === 'maintenance' && tagsplit[1] !== 'Disabled') {
      maintenance = tagsplit[1] + ':' + tagsplit[2];
    }
    if (tagsplit[0] === 'maintenance' && tagsplit[1] === 'Disabled') {
      maintenance = 'Disabled';
    }
  });

  return (
    <BasicTable>
      <TableRow>
        <TableCell>Lifecycle</TableCell>
        <TableCell>
          <Chip
            size="small"
            variant="outlined"
            label={resource?.lifecycle || 'N/A'}
          />
        </TableCell>
      </TableRow>
      {resource.id?.includes('/subscriptions/') && (
        <TableRow>
          <TableCell>Subscription</TableCell>
          <TableCell>
            {resource?.subscription ? (
              <Chip
                size="small"
                color="success"
                variant="outlined"
                label={resource?.subscription}
              />
            ) : (
              'N/A'
            )}
          </TableCell>
        </TableRow>
      )}
      {resource.id?.includes('/subscriptions/') && (
        <TableRow>
          <TableCell>Resource Group</TableCell>
          <TableCell>
            <Chip size="small" label={resource?.resource_group} />
            <Button
              size="small"
              endIcon={
                <OutboundIcon
                  sx={{
                    marginLeft: -4,
                    width: 20,
                    height: 20,
                  }}
                />
              }
              component={Link}
              href={`https://portal.azure.com/#@gopuff.com/resource${
                resource.id?.split('providers')[0]
              }`}
              target="blank"
            />
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        <TableCell>Type</TableCell>
        <TableCell>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ResourceIcon name={resource.type} sx={{ width: 20, height: 20 }} />
            <Typography variant="body2" style={{ marginLeft: '8px' }}>
              {resource?.type}
            </Typography>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Team</TableCell>
        <TableCell sx={{ pb: 0, pt: 0, mt: 0, mb: 0 }}>
          {resource.owner &&
            resource.owner?.map((t, i) => (
              <p key={i} style={{ margin: '0px' }}>
                <Button
                  size="small"
                  key={i}
                  sx={{ padding: 0, margin: 0 }}
                  startIcon={<Groups />}
                  component={RLink}
                  to={`/team/${t}`}
                >
                  {t}
                </Button>
              </p>
            ))}
          {!resource.owner && 'N/A'}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Services</TableCell>
        <TableCell sx={{ pb: 0, pt: 0, mt: 0, mb: 0 }}>
          {resource.services?.length > 0 && (
            <List>
              {resource.services?.map((s, i) => (
                <p key={i} style={{ margin: '0px', marginRight: '5px' }}>
                  <Button
                    size="small"
                    key={i}
                    sx={{ padding: 0, margin: 0, paddingRight: 2 }}
                    startIcon={
                      <Settings
                        sx={{
                          color: statusColor[s.status],
                        }}
                      />
                    }
                    component={RLink}
                    to={`/service/${s.service_name}`}
                  >
                    {s.service_name}
                  </Button>
                  {s.tier} {'●'.repeat(s.tier)}
                </p>
              ))}
            </List>
          )}
          {!resource.services && 'N/A'}
        </TableCell>
      </TableRow>
      {resource.links && (
        <TableRow>
          <TableCell>Links</TableCell>
          <TableCell>
            <List sx={{ padding: 0 }}>
              {resource.links?.map((link, i) => (
                <ListItemButton
                  style={{ padding: '2px' }}
                  target="_blank"
                  rel="noreferrer"
                  key={link}
                  href={`https://${link
                    .replace('https://', '')
                    .replace('http://', '')}`}
                >
                  <ListItemIcon style={{ minWidth: '30px' }}>
                    <Language color="primary" sx={{ width: 16, height: 16 }} />
                  </ListItemIcon>
                  {link.replace('https://', '')}
                </ListItemButton>
              ))}
            </List>
          </TableCell>
        </TableRow>
      )}
      {maintenance && (
        <TableRow>
          <TableCell>Maintenance</TableCell>
          <TableCell>
            {maintenance === 'Disabled' ? (
              'Disabled'
            ) : (
              <>
                Every{' '}
                <Tooltip
                  title={`Local timezone: ${convertUTCtoLocal(
                    parseDateWithoutYear(maintenance)
                  )}`}
                >
                  <span style={{ textDecoration: 'underline' }}>
                    {maintenance} UTC
                  </span>
                </Tooltip>{' '}
                (in {getRemainingTime(maintenance).days}d,{' '}
                {getRemainingTime(maintenance).hours}hrs,{' '}
                {getRemainingTime(maintenance).minutes}min)
              </>
            )}
          </TableCell>
        </TableRow>
      )}
      {resource.cost && (
        <TableRow>
          <TableCell>
            Cost
            <Tooltip title="Month to date, amortized dollar amount (with a full breakdown) spent on Azure for this resource (there might be extra costs that are not showing up here).">
              <IconButton color="primary" size="small">
                <Info sx={{ width: 16, height: 16 }} />
              </IconButton>
            </Tooltip>
          </TableCell>
          <TableCell>
            <Box>
              <Grid container spacing={2}>
                {Object.entries(resource.cost_breakdown).map(([key, value]) => (
                  <React.Fragment key={key}>
                    <Grid item xs={6}>
                      <Typography>{key}:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>$ {value}</Typography>
                    </Grid>
                  </React.Fragment>
                ))}
                {Object.entries(resource.cost_breakdown).length > 1 && (
                  <>
                    <Grid item xs={6}>
                      <Typography>
                        <b>Total Cost</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography style={{ borderTop: '1px solid black' }}>
                        $ {resource.cost}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </TableCell>
        </TableRow>
      )}
      <TableRow>
        <TableCell>Tags</TableCell>
        <TableCell>
          {resource.tags?.map((tag, i) => (
            <Chip key={tag} label={tag} size="small" sx={{ margin: '2px' }} />
          ))}
        </TableCell>
      </TableRow>
    </BasicTable>
  );
}

export default ResourceFields;
