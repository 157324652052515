import React, { useEffect, useRef } from 'react';
import drawChart from './drawChart';

function DonutChart({ podList }) {
  const ref = useRef(null);

  function getPodStatus(pod) {
    for (const condition of pod.status.conditions) {
      if (
        condition.type === 'ContainersReady' &&
        condition.status === 'False'
      ) {
        return 'ContainerCreating';
      }
    }

    if (pod.metadata.deletion_timestamp) {
      return 'Terminating';
    }
    return pod.status.phase;
  }

  useEffect(() => {
    const statusCounts = {};

    podList.forEach((item) => {
      const status = getPodStatus(item);
      statusCounts[status] = (statusCounts[status] || 0) + 1;
    });

    const result = Object.entries(statusCounts).map(([status, count]) => ({
      name: status,
      value: count,
    }));
    if (ref.current) {
      drawChart(ref.current, result);
    }
  }, [ref, podList]);

  return (
    <div className="container">
      <div className="graph" ref={ref} />
    </div>
  );
}

export default React.memo(DonutChart);
