/// <reference path="../../typedefs.js" />
import React from 'react';
import axios from 'axios';
import Config from '../shared/Config';
import { handlerApiError } from '../shared/errorHandler';

const { apiBaseUrl } = Config();
const endpointUrl = `${apiBaseUrl}/api/people`;
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

let cache = null;

export default function usePeople() {
  const access_token = window.sessionStorage.getItem('token');
  headers.Authorization = `Bearer ${access_token}`;
  /** @type {useState<Person[]>} */
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const getTeams = (config) => {
    config.url = `${endpointUrl}?verbose=true`;
    axios(config)
      .then((response) => {
        const { data } = response;
        console.log('getPeople: fetch', { data });
        setItems(data);
        cache = data;
        setLoading(false);
      })
      .catch((err) => handlerApiError(err));
  };

  React.useEffect(() => {
    const config = { url: endpointUrl, headers };
    if (cache) {
      setItems(cache);
      setLoading(false);
    } else {
      getTeams(config);
    }
  }, []);

  return { loading, items };
}
