// @ts-nocheck
import * as d3 from 'd3';

const drawChart = (element, data) => {
  function getColor(status) {
    if (status === 'Running') {
      return '#0baeff';
    }
    if (status === 'Terminating') {
      return '#e9e9ed';
    }
    if (status === 'ContainerCreating') {
      return '#2583ba';
    }
    return '#2583ba';
  }

  const width = 200;
  const height = 200;
  const radius = Math.min(width, height) / 2;
  const donutWidth = 20;
  const legendRectSize = 18;
  const legendSpacing = 4;

  d3.select(element).select('svg').remove(); // Remove the old svg
  // Create new svg
  const svg = d3
    .select(element)
    .append('svg')
    .attr('preserveAspectRatio', 'xMidYMid meet')
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', `translate(${width / 2},${height / 2})`);

  const arcGenerator = d3
    .arc()
    .innerRadius(radius - donutWidth)
    .outerRadius(radius);

  const pieGenerator = d3
    .pie()
    .value((d) => d.value)
    .sort(null);

  const arcs = svg.selectAll().data(pieGenerator(data)).enter();

  arcs
    .append('path')
    .attr('d', arcGenerator)
    .style('fill', (d, i) => getColor(d.data.name))
    .attr('transform', 'translate(0, 0)');

  arcs
    .append('text')
    .attr('text-anchor', 'middle')
    .text((d) => `${d.data.value}`)
    .style('fill', '#fff')
    .style('font-size', '14px')
    .style('font-weight', 'bold')
    .attr('transform', (d) => {
      const [x, y] = arcGenerator.centroid(d);
      return `translate(${x}, ${y})`;
    });

  const legend = svg
    .selectAll('.legend')
    .data(data)
    .enter()
    .append('g')
    .attr('class', 'legend')
    .style('font-size', '11px')
    .attr('transform', (d, i) => {
      const height = legendRectSize + legendSpacing;
      const offset = (height * data.length) / 2;
      const horz = -2 * legendRectSize;
      const vert = i * height - offset;
      return `translate(${horz},${vert})`;
    });

  legend
    .append('rect')
    .attr('width', legendRectSize)
    .attr('height', legendRectSize)
    .style('fill', (d) => getColor(d.name))
    .style('stroke', (d) => getColor(d.name));

  legend
    .append('text')
    .attr('x', legendRectSize + legendSpacing)
    .attr('y', legendRectSize - legendSpacing)
    .text((d) => d.name);
};
export default drawChart;
