import React from 'react';
import { Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import SlashIcon from './SlashIcon';
import SearchDialog from './SearchDialog';

function SearchBar() {
  const [open, setOpen] = React.useState(false);
  const showDialog = () => {
    setOpen(!open);
  };
  // handle / to search
  React.useEffect(() => {
    const handleSearch = (e) => {
      if (e.key === '/') {
        e.preventDefault();
        showDialog();
      }
    };

    document.addEventListener('keydown', handleSearch);

    return () => {
      document.removeEventListener('keydown', handleSearch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <span>
      <Button
        sx={{
          color: 'white',
          borderColor: 'white',
          textTransform: 'none',
          '&:hover': {
            borderColor: '#d1d5db',
            backgroundColor: '#4AC0EE',
          },
        }}
        variant="outlined"
        startIcon={<SearchIcon />}
        endIcon={<SlashIcon color="white" />}
        onClick={showDialog}
      >
        Search catalog...
      </Button>

      <SearchDialog open={open} setOpen={setOpen} />
    </span>
  );
}

export default SearchBar;
