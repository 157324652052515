/// <reference path="../../typedefs.js" />
import React from 'react';
import axios from 'axios';
import Config from '../shared/Config';
import { handlerApiError } from '../shared/errorHandler';

const { apiBaseUrl } = Config();
const endpointUrl = `${apiBaseUrl}/api/service`;
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const cache = {};

/** @param {string} serviceName */
export default function useServices(serviceName) {
  const access_token = window.sessionStorage.getItem('token');
  headers.Authorization = `Bearer ${access_token}`;
  const [loading, setLoading] = React.useState(true);
  /** @type {useState<Service>} */
  const [service, setService] = React.useState({ service_name: '' });

  React.useEffect(() => {
    const fetchData = async () => {
      const config = { url: `${endpointUrl}/${serviceName}`, headers };
      axios(config)
        .then((response) => {
          const { data } = response;
          console.log(`useService: ${serviceName}`, { data });
          setService(data);
          cache[serviceName] = data;

          setLoading(false);
        })
        .catch((err) => {
          /** @type {Service} */
          const data = { error: err.response, service_name: '' };
          setLoading(false);
          setService(data);
          handlerApiError(err);
        });
    };

    if (cache[serviceName]) {
      setService(cache[serviceName]);
      setLoading(false);
    } else {
      fetchData();
    }
  }, [serviceName]);

  /**
   * @param {Service} service
   * @param {any} json
   */
  const updateService = (service, json) => {
    const config = {
      url: `${endpointUrl}/${service.service_name}`,
      headers,
      method: 'put',
      data: json,
    };
    return axios(config);
  };

  return { loading, service, updateService };
}
