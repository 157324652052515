import * as React from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Avatar,
  Chip,
  CardHeader,
  Link,
} from '@mui/material';
import { People, Search, Settings, Groups } from '@mui/icons-material';
import { useSearch } from '../hooks/useSearch';
import ResourceIcon from './ResourceIcon';
import { Link as RLink } from 'react-router-dom';

const statusColor = {
  Active: '#25b225',
  Experimental: '#ce44ec',
  Deprecated: '#da4103',
};
import { formatTeamSlug } from '../shared/serviceHandler';

function SearchDialog(params) {
  const { open, setOpen } = params;
  const { options, searchLoading, setSearch, setOptions } = useSearch();

  const [services, setServices] = React.useState([]);
  const [people, setPeople] = React.useState([]);
  const [resources, setResources] = React.useState([]);
  const [recentSearches, setRecentSearches] = React.useState([]);
  const boxStyle = {
    marginLeft: '20px',
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderBottom: 1,
    borderColor: 'grey.300',
    '&:hover': { backgroundColor: 'grey.100' },
  };
  const dialogStyle =
    options.length === 0 && recentSearches.length === 0
      ? { height: { xs: 296, sm: 'auto' } }
      : {};
  const handleClose = () => {
    setOptions([]);
    setOpen(false);
  };

  const handleSearch = (e) => {
    if (e.target.value) {
      setSearch(e.target.value);
    }
  };
  const serviceJSX = (option) => (
    <Box
      key={option.source.service_name}
      sx={boxStyle}
      component={Link}
      href={`/service/${option.source.service_name}`}
      onClick={(e) => handleClick(e, option)}
    >
      <Settings
        sx={{
          marginLeft: -2,
          marginRight: 1,
          color: statusColor[option.source.status],
        }}
      />
      <CardHeader
        style={{ padding: '0px' }}
        avatar={
          <Avatar
            alt={option.source.deploy_target}
            src={
              option.source.deploy_target
                ? `/assets/${option.source.deploy_target
                    .replace('/', '_')
                    .replace(' ', '_')}.png`
                : '/assets/unknown_deploy.png'
            }
            sx={{ width: 24, height: 24 }}
          />
        }
      />
      {option.source.service_name} ({option.source.tier || '-'})
      <Chip
        sx={{ ml: 3, display: { xs: 'none', sm: 'flex' } }}
        size="small"
        icon={<Groups />}
        label={formatTeamSlug(option.source.team?.[0])}
        variant="outlined"
      />
    </Box>
  );

  const resourceJSX = (option, index) => (
    <Box
      key={`${option.source.name}.${index}`}
      sx={boxStyle}
      component={Link}
      href={`/resource/${option.source.name}`}
      onClick={(e) => handleClick(e, option)}
    >
      <ResourceIcon
        sx={{ marginLeft: -2, marginRight: 1 }}
        name={option.source.type}
      />
      {option.source.name}
    </Box>
  );
  const peopleJSX = (option) => (
    <Box
      key={`${option.source.username}`}
      sx={boxStyle}
      component={Link}
      href={`/person/${option.source.username}`}
      onClick={(e) => handleClick(e, option)}
    >
      <Avatar
        alt={option.source.full_name}
        src={option.source.slack_photo}
        sx={{ width: 25, height: 25, mr: 1 }}
      />
      {option.source.last_name}, {option.source.first_name}
      <Chip
        size="small"
        variant="outlined"
        sx={{
          fontSize: '0.6rem',
          lineHeight: '0.5rem',
          padding: '0px 0px',
          borderRadius: '8px',
          marginLeft: 1,
        }}
        label={option.source.title}
      />
      <Chip
        sx={{ ml: 3, display: { xs: 'none', sm: 'flex' } }}
        size="small"
        icon={<Groups />}
        label={formatTeamSlug(option.source.engineering_teams?.[0])}
        variant="outlined"
      />
    </Box>
  );

  // On search change
  React.useEffect(() => {
    setServices(options.filter((o) => o.index === 'services'));
    setPeople(options.filter((o) => o.index === 'tech-staff'));
    setResources(options.filter((o) => o.index === 'dependencies'));
  }, [options]);

  // Load local storage for recent search
  React.useEffect(() => {
    let recentSearchStr = localStorage.getItem('recentSearch');
    if (recentSearchStr) {
      let recentSearch = JSON.parse(recentSearchStr);
      // remove duplicates
      const seen = {};
      recentSearch = recentSearch.filter((s) => {
        if (JSON.stringify(s.source) in seen) {
          return false;
        }
        seen[JSON.stringify(s.source)] = true;
        return true;
      });
      // cap recent searches to 7
      recentSearch = recentSearch.slice(0, 7);
      setRecentSearches(recentSearch);
    }
  }, []);

  const handleClick = (e, option) => {
    // shorten option
    if (option.index === 'services') {
      const { service_name, tier, status, deploy_target, team } = option.source;
      option.source = { service_name, tier, status, deploy_target, team };
    } else if (option.index === 'dependencies') {
      const { name, type } = option.source;
      option.source = { name, type };
    } else if (option.index === 'tech-staff') {
      const {
        first_name,
        last_name,
        type,
        title,
        username,
        slack_photo,
        engineering_teams,
      } = option.source;
      option.source = {
        first_name,
        last_name,
        type,
        title,
        username,
        slack_photo,
        engineering_teams,
      };
    }
    localStorage.setItem(
      'recentSearch',
      JSON.stringify([...[option], ...recentSearches])
    );
  };
  return (
    <span>
      <Dialog
        sx={dialogStyle}
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            borderBottom: 1,
            borderColor: 'grey.300',
          }}
        >
          <IconButton sx={{ height: '100%' }}>
            <Search sx={{ fontSize: '32px', color: 'rgb(0, 164, 255)' }} />
          </IconButton>

          <TextField
            variant="outlined"
            InputProps={{
              style: {
                fontSize: '20px',
              },
              endAdornment: searchLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : null,
            }}
            sx={{
              flexGrow: 1,
              width: '100%',
              border: 'none',
              '& fieldset': { border: 'none' },
              fontSize: '28px',
            }}
            onChange={handleSearch}
            placeholder="Search..."
            autoFocus
          />
          <Button onClick={handleClose}>esc</Button>
        </Box>
        {options.length === 0 && recentSearches.length > 0 && (
          <Box sx={{ overflow: 'auto', maxHeight: 600 }}>
            <DialogTitle>Recent</DialogTitle>
            <DialogContent>
              {recentSearches.map((option, index) => {
                if (option.index === 'services') {
                  return serviceJSX(option);
                }
                if (option.index === 'tech-staff') {
                  return peopleJSX(option);
                }
                if (option.index === 'dependencies') {
                  return resourceJSX(option, index);
                }
                return <></>;
              })}
            </DialogContent>
          </Box>
        )}

        <Box sx={{ overflow: 'auto', maxHeight: 600 }}>
          {services.length > 0 && (
            <>
              <DialogTitle>Services</DialogTitle>
              <DialogContent>
                {services.map((option) => serviceJSX(option))}
              </DialogContent>
            </>
          )}
          {resources.length > 0 && (
            <>
              <DialogTitle>Resources</DialogTitle>
              <DialogContent>
                {resources.map((option, index) => resourceJSX(option, index))}
              </DialogContent>
            </>
          )}
          {people.length > 0 && (
            <>
              <DialogTitle>People</DialogTitle>
              <DialogContent>
                {people.map((option) => peopleJSX(option))}
              </DialogContent>
            </>
          )}
        </Box>
      </Dialog>
    </span>
  );
}

export default SearchDialog;
