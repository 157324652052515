// @ts-nocheck
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { styled, alpha } from '@mui/material/styles';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Collapse,
  FormGroup,
  FormControlLabel,
  Switch,
  Menu,
  IconButton,
  Tab,
  LinearProgress,
  Tooltip,
  Alert,
  MenuItem,
  Select,
  TextField,
  Skeleton,
  Modal,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  CardContent,
  Button,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogContentText,
  AlertTitle,
  Grid,
  Divider,
  Snackbar,
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TerminalIcon from '@mui/icons-material/Terminal';
import axios from 'axios';
import Config from '../shared/Config';
import PodOverview from '../components/PodOverview';
import { handlerApiError } from '../shared/errorHandler';
import {
  ShellTextField,
  terminalStyle,
  stylePod,
  getPodStatus,
  getPodContainers,
  humanifyAge,
  getMotd,
  getK8sCluster,
  shortenPodName,
  getShortImage,
  getContainerStatus,
} from '../shared/troubleshootingHandler';
import { isOwnerOrAdmin, statusColor } from '../shared/serviceHandler';
import usePerson from '../hooks/usePerson';
import { useAuth } from 'oidc-react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import MemoryIcon from '@mui/icons-material/Memory';
import DownloadIcon from '@mui/icons-material/Download';

const { troubleshootBaseUrl } = Config();
const endpointUrl = `${troubleshootBaseUrl}/api`;
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};
const access_token = window.sessionStorage.getItem('token');
headers.Authorization = `Bearer ${access_token}`;

const DialogContentLoader = styled(DialogContent)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ExpandMore = styled((props) => {
  return <IconButton {...props} />;
})(({ theme }) => ({
  transform: 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function TroubleshootingPage({
  service,
  environment,
  setSelectEnvDisabled,
}) {
  // All interval times are in seconds
  const MAX_ATTEMPTS = 10;
  const PODS_REFRESH_INTERVAL = 5;
  const PODS_METRICS_REFRESH_INTERVAL = 10;
  const RETRY_INTERVAL = 1.5;
  const preRef = React.useRef(null);
  const logsPreRef = React.useRef(null);
  const inputRef = React.useRef(null);
  const auth = useAuth();
  const email = auth.userData.profile.email;
  const { person, loading } = usePerson(email);
  const [podList, setPodList] = React.useState([]);
  const [podMetrics, setPodMetrics] = React.useState({});
  const [dlgRolloutRestart, setOpenDlgRolloutRestart] = React.useState(false);
  const [dlgKillPod, setOpenDlgKillPod] = React.useState(false);
  // Terminal
  const [openTerminal, setOpenTerminal] = React.useState(false);
  /** @type {useState<object>} */
  const [terminalParams, setTerminalParams] = React.useState({});
  const [terminalCmd, setTerminalCmd] = React.useState('');
  /** @type {useState<any>} */
  const [terminalOutput, setTerminalOutput] = React.useState(getMotd(service));
  const [loadingTerminalCmd, setLoadingTerminalCmd] = React.useState(false);
  // Logs
  const [logsOutput, setLogsOutput] = React.useState([]);
  const [logsParams, setLogsParams] = React.useState([]);
  const [logsLoading, setLogsLoading] = React.useState(false);
  const [openLogs, setOpenLogs] = React.useState(false);
  // Various states
  const [resourceType, setResourceType] = React.useState('requests');

  const [failing, setFailing] = React.useState(false);
  const [failingMsg, setFailingMsg] = React.useState('');
  const [firstLaunch, setFirstLaunch] = React.useState(true);
  const [replicas, setReplicas] = useState(podList?.length);
  const [error, setError] = useState('');
  const [dlgReplicas, setOpenDlgReplicas] = React.useState(false);
  const [possibleContainers, setPossibleContainers] = React.useState([]);
  const [container, setContainer] = React.useState('');
  const [selectedPod, setSelectedPod] = React.useState();
  /** @type {useState<object>} */
  const [createEphemeralOutput, setCreateEphemeralOutput] = React.useState({});
  const [openEphemeralDiag, setOpenEphemeralDiag] = React.useState(false);
  const [openEphemeralLoader, setOpenEphemeralLoader] = React.useState(false);
  /** @type {useState<object>} */
  const [ephemeralInfo, setEphemeralInfo] = React.useState({});
  const [ephExecLoading, setEphExecLoading] = React.useState(false);
  /** @type {useState<string>} */
  const [ephExecOutput, setEphExecOutput] = React.useState('');
  /** @type {useState<string>} */
  const [ephemeralCmd, setEphemeralCmd] = React.useState('');
  /** @type {useState<object>} */
  const [reportReady, setReportReady] = React.useState({
    ready: false,
    error: false,
  });

  const [generatingReport, setGeneratingReport] = React.useState(false);
  const [loadingReport, setLoadingReport] = React.useState(false);

  const handleOpenEphDiag = (pod_name, namespace, container) => {
    setOpenEphemeralDiag(true);
    setOpenEphemeralLoader(true);
    executeCommand(service, 'create_ephemeral', {
      cmd: '',
      pod_name,
      namespace,
      container,
    });
  };

  const handleCloseEphDiag = (event, reason) => {
    if (reason && reason == 'backdropClick') return;
    setOpenEphemeralDiag(false);
    setGeneratingReport(false);
    setReportReady({ ready: false, error: false });
  };
  // Tabs
  const [tabValue, setTabValue] = React.useState('1');
  const tabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  // Container
  const [podExpand, setPodExpand] = React.useState({});

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // ------------------------------ ALL REACT useEffect(s)
  React.useEffect(() => {
    jobsMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (terminalCmd)
      executeCommand(service, 'shell_command', {
        cmd: terminalCmd,
        pod_name: terminalParams.pod_name,
        namespace: terminalParams.namespace,
        container: container,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terminalCmd]);

  React.useEffect(() => {
    // Scroll to the bottom of the pre element when it updates
    if (preRef.current !== null)
      preRef.current.scrollTop = preRef.current.scrollHeight;
  }, [preRef.current?.innerHTML]);

  React.useEffect(() => {
    // Scroll to the bottom of the pre element when it updates
    if (logsPreRef.current !== null)
      logsPreRef.current.scrollTop = logsPreRef.current.scrollHeight;
  }, [logsPreRef.current?.innerHTML]);

  React.useEffect(() => {
    // Call this only once, when the page loads, and starts two update loops
    if (failing) {
      return;
    }
    if (firstLaunch) {
      executeCommand(service, 'get_pods');
    } else {
      console.log(
        `Refresh pod loops for ${service.service_name} in ${environment}`,
        firstLaunch
      );
      const getPodsIntervalId = setInterval(() => {
        executeCommand(service, 'get_pods');
      }, PODS_REFRESH_INTERVAL * 1000);
      const podMetricsInterval = setInterval(() => {
        executeCommand(service, 'get_pod_metrics');
      }, PODS_METRICS_REFRESH_INTERVAL * 1000);
      return () => {
        clearInterval(getPodsIntervalId);
        clearInterval(podMetricsInterval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environment, failing, firstLaunch]);

  React.useEffect(() => {
    setPodList([]);
    setFailing(false);
    setFailingMsg('');
    setFirstLaunch(true);
  }, [environment]);

  React.useEffect(() => {
    setSelectEnvDisabled(!failing && podList.length === 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [podList]);

  // ------------------------------- END OF REACT EFFECTS

  const handleSwitchChange = (event) => {
    setResourceType(event.target.checked ? 'limits' : 'requests');
  };

  const changeContainer = (event) => {
    console.log(`Changing container to ${event.target.value}`);
    setContainer(event.target.value);
  };

  const handleTerminalCommand = (event) => {
    if (event.key === 'Enter') {
      setTerminalCmd(event.target.value);
      setLoadingTerminalCmd(true);
      setTerminalOutput([
        ...terminalOutput,
        <span
          key={event.target.value}
          style={{ color: 'white', fontWeight: 'bold' }}
        >
          {event.target.value}
          <br />
        </span>,
      ]);
      inputRef.current.value = '';
    }
  };
  const handleEphemeralCommand = (event) => {
    if (event.target.value !== '') {
      setEphemeralCmd(event.target.value);
      setEphExecOutput('');
    }
  };

  function getPodMetricsPercentage(pod, type) {
    // type could be either "cpu" or "memory", resourceType could be either "requests" or "limits"
    // Ki = kibibytes, Mi = mebibytes, Gi = gibibytes
    if (type !== 'cpu' && type !== 'memory') return 'n/a';
    if (!pod.spec.containers) return 'n/a';
    if (!podMetrics[pod.metadata.name]) return 'n/a';
    let requested = pod.spec.containers[0].resources[resourceType];
    if (!requested) return 'n/a';
    requested = requested[type];
    if (requested?.endsWith('m')) {
      requested = parseFloat(requested.replace('m', '')) / 1000.0;
    } else if (requested?.endsWith('Mi')) {
      requested = parseFloat(requested.replace('Mi', '')) * 1024.0;
    }
    // perform conversions
    let usage = podMetrics[pod.metadata.name][0].usage[type];
    if (usage?.endsWith('n')) {
      usage = parseFloat(usage.replace('n', '')) / 1000000000;
    } else if (usage?.endsWith('Ki')) {
      usage = parseFloat(usage.replace('Ki', ''));
    } else if (usage?.endsWith('Mi')) {
      // 1 Mi = 1024 Ki -> Convert to kibibytes
      usage = parseFloat(usage.replace('Mi', '')) * 1024;
    }
    let percent = Math.round((usage / requested) * 100);
    if (isNaN(percent)) {
      return 'n/a';
    }
    return percent;
  }

  const getMessage = async (job_id, attempt = 1) => {
    const config = { url: `${endpointUrl}/message/${job_id}`, headers };
    if (attempt >= MAX_ATTEMPTS) {
      console.log(`ERR: Max attempts reached for job ${job_id}, aborting.`);
      setPodList([]);
      setFailing(true);
      setFailingMsg(
        `Unable to receive messages from puff-agent service in ${environment}. Make sure the agent is working there.`
      );
      return;
    }
    console.log(
      `${attempt}/${MAX_ATTEMPTS}) Polling: (${job_id}) (env: ${environment}) `
    );
    try {
      const response = await axios(config.url, config);
      const { data } = response;
      if (Object.keys(data).length > 0) {
        if (data.success === true) {
          // console.log(`(Success) ${data.cmd}`, data);
          setFirstLaunch(false);
          let username;
          if (!loading) {
            username = person.first_name.toLowerCase();
          } else {
            username = 'user';
          }
          // We got a shell command, make sure to refresh the terminal
          if (data.cmd === 'shell_command') {
            if (data.output.stdout) {
              setTerminalOutput([
                ...terminalOutput,
                `${data.output.stdout}\n[${username}@${terminalParams.pod_name}]$ `,
              ]);
            }
            if (data.output.stderr) {
              setTerminalOutput([
                ...terminalOutput,
                <span key={job_id} style={{ color: 'red' }}>
                  {data.output.stderr}
                  <br />
                </span>,
                `[${username}@${terminalParams.pod_name}]$ `,
              ]);
            }
            setLoadingTerminalCmd(false);
            setTerminalCmd('');
            inputRef.current.focus();
          }
          // PodList, update the table with pods.
          if (data.output.kind === 'PodList') {
            setPodList(data.output.items);
          }
          // Getting logs
          if (data.cmd === 'get_logs') {
            setLogsOutput(data.output.logs);
            setOpenLogs(true);
            setLogsLoading(false);
          }
          if (data.output.kind === 'PodMetricsList') {
            // Store metrics in an object, keyed by pod name
            let metrics = {};
            for (const pod of data.output.items) {
              metrics[pod.metadata.name] = pod.containers;
            }
            setPodMetrics(metrics);
          }

          if (data.cmd === 'create_ephemeral') {
            setCreateEphemeralOutput(data.output);
            setOpenEphemeralLoader(false);
          }
          if (data.cmd === 'shell_command_with_ephemeral') {
            setEphExecOutput(data.output);
            setEphExecLoading(false);
          }
          if (data.cmd === 'jcmd_command') {
            setReportReady({
              ready: true,
              error: false,
              link: data.output.stdout.link,
              filename: data.output.stdout.filename,
            });

            setGeneratingReport(false);
          }
          return;
        } else if (data.success === false) {
          console.log('ERR: Message came back with an error: ', data);
          if (firstLaunch) {
            setFailing(true);
            setFailingMsg(
              `error ${data.status} when calling ${data.cmd} on puff-agent: ${data.output.message}`
            );
          }
          if (data.cmd === 'get_logs') {
            setLogsLoading(false);
            setLogsOutput('');
          }
          if (data.cmd === 'create_ephemeral') {
            setCreateEphemeralOutput({
              stderr: 'Failed to create the ephemeral container',
            });
            setOpenEphemeralLoader(false);
          }
          if (data.cmd === 'jcmd_command') {
            setGeneratingReport(false);
            setReportReady({
              ready: false,
              error: true,
            });
          }
          if (data.cmd === 'shell_command_with_ephemeral') {
            setEphExecOutput({
              stderr:
                'Failed to run shell command using the ephemeral container',
            });
            setEphExecLoading(false);
          }
          return;
        }
      }
      setTimeout(() => {
        getMessage(job_id, attempt + 1);
      }, RETRY_INTERVAL * 1000);
    } catch (err) {
      handlerApiError(err);
    }
  };

  const jobsMetrics = async () => {
    const config = { url: `${endpointUrl}/jobs_metrics`, headers };
    axios(config.url, config).then((response) => {
      const { data } = response;
      const cluster = getK8sCluster(service);
    });
  };

  const executeCommand = async (service, command, params = {}) => {
    let job_id;
    const config = { url: `${endpointUrl}/job`, headers };
    const cluster = getK8sCluster(service);
    const data = {
      env: environment,
      params,
      cluster: cluster,
      namespace: service.k8s_namespace,
      deployment: service.k8s_deployment[0],
      service: service.service_name,
      cmd: command,
    };
    axios.post(config.url, data, config).then((response) => {
      const { data } = response;
      job_id = data.job_id;
      console.log(
        `Executing: cmd: ${data.cmd}(${JSON.stringify(
          data.params
        )}) on ${cluster}@${environment}`
      );
      // Job enqueued, puff-agent should execute it and post the message back, for retrieval.
      getMessage(job_id);
    });
  };

  const handleInputChange = (event) => {
    setReplicas(event.target.value);
    setError('');
  };

  const handleSetReplicas = () => {
    if (replicas >= 0) {
      setOpenDlgReplicas(false);
      executeCommand(service, 'update_replicas', {
        replicas: Number(replicas),
      });
    } else {
      setError('Replicas must be a positive number');
    }
  };

  const handleDownloadReport = async () => {
    console.log('downloading heap memory report');
    setLoadingReport(true);
    try {
      const resp = await axios({
        method: 'GET',
        url: reportReady.link,
        responseType: 'blob',
      });
      // Create a link element to download the file
      const href = window.URL.createObjectURL(new Blob([resp.data]));
      const anchorElement = document.createElement('a');
      anchorElement.href = href;
      anchorElement.download = reportReady.filename;
      document.body.appendChild(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
      setLoadingReport(false);
      setReportReady({ ready: false, error: false });
    } catch (error) {
      console.error('Error downloading file:', error);
      setLoadingReport(false);
      setReportReady({ ready: false, error: true });
    }
  };

  const downloadReportAction = (
    <React.Fragment>
      {loadingReport ? (
        <LoadingButton loading={loadingReport} />
      ) : (
        <IconButton
          size="small"
          aria-label="download"
          color="inherit"
          onClick={handleDownloadReport}
        >
          <DownloadIcon fontSize="small" />
        </IconButton>
      )}
    </React.Fragment>
  );

  return (
    <CardContent style={{ padding: '5px' }}>
      <>
        <Dialog open={dlgReplicas} onClose={() => setOpenDlgReplicas(false)}>
          <DialogTitle>Scale up/down the deployment</DialogTitle>
          <DialogContent>
            How many replicas do you want for the deployment{' '}
            <strong>{service.k8s_deployment}</strong> ?
            <TextField
              id="replicas"
              label="Replicas"
              type="number"
              size="small"
              fullWidth
              style={{ marginTop: '20px' }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: 0,
              }}
              defaultValue={podList?.length}
              onChange={handleInputChange}
              error={error !== ''}
              helperText={error}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDlgReplicas(false)}>Cancel</Button>
            <Button onClick={handleSetReplicas}>Set Replicas</Button>
          </DialogActions>
        </Dialog>
      </>
      <Box
        display="flex"
        alignItems="center"
        sx={{ float: 'right', marginTop: '10px', marginBottom: '10px' }}
      >
        {failing ? (
          <Alert
            severity="error"
            action={
              <Button
                size="small"
                color="inherit"
                startIcon={<RestartAltIcon />}
                onClick={() => {
                  console.log(`Refreshing ${environment}`);
                  setPodList([]);
                  setFailing(false);
                  setFailingMsg('');
                  setFirstLaunch(true);
                }}
              >
                Refresh
              </Button>
            }
          >
            {failingMsg}
          </Alert>
        ) : (
          podList.length > 0 && (
            <>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={resourceType === 'limits'}
                      onChange={handleSwitchChange}
                      color="primary"
                    />
                  }
                  label={resourceType === 'limits' ? 'Limits' : 'Request'}
                />
              </FormGroup>
              <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="outlined"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{ float: 'right', display: { xs: 'none', sm: 'flex' } }}
              >
                Actions
              </Button>
            </>
          )
        )}

        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'menu',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            disabled={!isOwnerOrAdmin(service)}
            onClick={() => setOpenDlgRolloutRestart(true)}
            disableRipple
          >
            <CachedRoundedIcon />
            Rollout Restart
          </MenuItem>
          <MenuItem
            onClick={() => setOpenDlgReplicas(true)}
            disabled={!isOwnerOrAdmin(service)}
            disableRipple
          >
            <ContentCopyRoundedIcon />
            Scale Replicas
          </MenuItem>
        </StyledMenu>

        <Dialog
          open={dlgRolloutRestart}
          onClose={() => setOpenDlgRolloutRestart(false)}
        >
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            Are you sure you want to perform a Rollout Restart of{' '}
            <strong>{service.service_name}</strong> in{' '}
            <strong>{environment}</strong>?
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDlgRolloutRestart(false)}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                executeCommand(service, 'rollout_restart');
                setOpenDlgRolloutRestart(false);
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      {/* Tabs for Troubleshooting */}
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={tabChange} aria-label="lab API tabs example">
              <Tab label="Pods" value="1" />
              <Tab
                label={
                  getK8sCluster(service) === 'splat' ? 'Rollout' : 'Deployment'
                }
                value="2"
              />
              <Tab disabled label="Ingress" value="3" />
            </TabList>
          </Box>
          {/* Pods Tab */}
          <TabPanel sx={{ p: 0 }} value="1">
            {podList.length === 0 && !failing && (
              <span>
                <Skeleton animation="wave" />
                <Skeleton variant="rectangular" animation="wave" height={60} />
              </span>
            )}
            {podList.length > 0 && !failing && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell style={{ padding: '2px' }}>
                      Pods ({podList.length || '0'})
                    </TableCell>
                    <TableCell
                      style={{ padding: '2px' }}
                      sx={{ display: { xs: 'none', sm: 'table-cell' }, p: 1 }}
                    >
                      Ready
                    </TableCell>
                    <TableCell
                      style={{ padding: '2px' }}
                      sx={{ display: { xs: 'none', sm: 'table-cell' }, p: 1 }}
                    >
                      Restarts
                    </TableCell>
                    <TableCell style={{ padding: '2px' }}>Status</TableCell>
                    <TableCell
                      style={{ padding: '2px' }}
                      sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                    >
                      <Tooltip
                        title={`CPU over ${
                          resourceType === 'requests' ? 'Request' : 'Limits'
                        }`}
                        placement="top"
                      >
                        <span>
                          %CPU/{resourceType === 'requests' ? 'R' : 'L'}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      style={{ padding: '2px' }}
                      sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                    >
                      <Tooltip
                        title={`Memory over ${
                          resourceType === 'requests' ? 'Request' : 'Limits'
                        }`}
                        placement="top"
                      >
                        <span>
                          %MEM/{resourceType === 'requests' ? 'R' : 'L'}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={{ padding: '2px' }}>Age</TableCell>
                    <TableCell style={{ padding: '2px' }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {podList.map((pod, index) => (
                    <>
                      <TableRow
                        size="small"
                        component={undefined}
                        style={stylePod(getPodStatus(pod))}
                        key={index}
                      >
                        <TableCell style={{ padding: '2px' }}>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => {
                              setPodExpand((prevState) => ({
                                ...prevState,
                                [pod.metadata.name]:
                                  !podExpand[pod.metadata.name],
                              }));
                            }}
                            style={{ padding: '1px' }}
                          >
                            {podExpand[pod.metadata.name] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell
                          sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                          style={{ padding: '2px' }}
                        >
                          <small>{pod.metadata.name}</small>
                        </TableCell>
                        <TableCell
                          sx={{ display: { xs: 'table-cell', sm: 'none' } }}
                          style={{ padding: '2px' }}
                        >
                          <small>{shortenPodName(pod.metadata.name)}</small>
                        </TableCell>

                        <TableCell
                          style={{ padding: '2px' }}
                          sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                        >
                          {pod.status.container_statuses.reduce(
                            (acc, container) => acc + (container.ready ? 1 : 0),
                            0
                          )}
                          /{pod.status.container_statuses.length}
                        </TableCell>
                        <TableCell
                          style={{ padding: '2px' }}
                          sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                        >
                          {pod.status.container_statuses.reduce(
                            (acc, container) =>
                              acc +
                              (container.restart_count
                                ? container.restart_count
                                : 0),
                            0
                          )}
                        </TableCell>
                        <TableCell style={{ padding: '2px' }}>
                          {getPodStatus(pod)}
                        </TableCell>
                        <TableCell
                          style={{ padding: '2px' }}
                          sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                        >
                          {podMetrics[pod.metadata.name] ? (
                            getPodMetricsPercentage(pod, 'cpu')
                          ) : getPodStatus(pod) === 'Completed' ? (
                            '-'
                          ) : (
                            <CircularProgress size={14} />
                          )}
                        </TableCell>
                        <TableCell
                          style={{ padding: '2px' }}
                          sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                        >
                          {podMetrics[pod.metadata.name] ? (
                            getPodMetricsPercentage(pod, 'memory')
                          ) : getPodStatus(pod) === 'Completed' ? (
                            '-'
                          ) : (
                            <CircularProgress size={14} />
                          )}
                        </TableCell>
                        <TableCell style={{ padding: '2px' }}>
                          {humanifyAge(pod.metadata.creation_timestamp)}
                        </TableCell>
                        <TableCell style={{ padding: '2px' }}>
                          <span>
                            {isOwnerOrAdmin(service) &&
                              getPodStatus(pod) === 'Running' && (
                                <Button
                                  size="small"
                                  style={{
                                    maxWidth: '30px',
                                    maxHeight: '30px',
                                    minWidth: '30px',
                                    minHeight: '30px',
                                  }}
                                  startIcon={<TerminalIcon />}
                                  aria-label="ssh"
                                  onClick={() => {
                                    setOpenTerminal(true);
                                    setPossibleContainers(
                                      getPodContainers(pod)
                                    );
                                    // If istio-proxy is there, don't use it for the default container
                                    const tmp_containers =
                                      getPodContainers(pod);
                                    const index =
                                      tmp_containers.indexOf('istio-proxy');
                                    if (index !== -1) {
                                      tmp_containers.splice(index, 1);
                                    }
                                    setContainer(tmp_containers[0]);
                                    setTerminalOutput([
                                      getMotd(service),
                                      `[${person.email.replace(
                                        '@gopuff.com',
                                        ''
                                      )}@${pod.metadata.name}]$ `,
                                    ]);
                                    setTerminalParams({
                                      pod_name: pod.metadata.name,
                                      namespace: pod.metadata.namespace,
                                      container: getPodContainers(pod)[0],
                                    });
                                  }}
                                />
                              )}
                            <Modal
                              open={openTerminal}
                              onClose={() => {
                                setOpenTerminal(false);
                                setTerminalParams({});
                              }}
                              aria-labelledby="SSH Terminal"
                              aria-describedby="SSH Terminal for connecting to the pod"
                            >
                              <Box sx={terminalStyle}>
                                <Typography
                                  sx={{
                                    display: { xs: 'none', sm: 'block' },
                                  }}
                                  id="modal-modal-title"
                                  component="h3"
                                >
                                  {terminalParams.pod_name}
                                </Typography>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {possibleContainers.length > 1 && (
                                    <Select
                                      disabled={
                                        !failing && podList.length === 0
                                      }
                                      sx={{ marginRight: 3 }}
                                      size="small"
                                      value={container}
                                      onChange={changeContainer}
                                    >
                                      {possibleContainers.map((container) => (
                                        <MenuItem
                                          key={container}
                                          value={container}
                                        >
                                          {container}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  )}
                                  <Typography
                                    sx={{
                                      display: { xs: 'none', sm: 'block' },
                                      mt: 2,
                                    }}
                                    id="modal-modal-description"
                                  >
                                    Connected to the pod via SSH, container:{' '}
                                    <strong>
                                      {container || possibleContainers[0]}
                                    </strong>
                                  </Typography>
                                </div>
                                <pre
                                  ref={preRef}
                                  style={{
                                    marginBottom: '0px',
                                    padding: '5px',
                                    width: '100%',
                                    height: '600px',
                                    backgroundColor: 'black',
                                    fontSize: '14px',
                                    color: '#d7d7d7',
                                    overflow: 'auto',
                                  }}
                                >
                                  {terminalOutput}
                                </pre>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  <AccountCircle
                                    sx={{
                                      color: 'action.active',
                                      mr: 1,
                                      my: 0.5,
                                    }}
                                  />
                                  <ShellTextField
                                    disabled={loadingTerminalCmd}
                                    size="small"
                                    fullWidth
                                    autoComplete="off"
                                    InputProps={{}}
                                    inputRef={inputRef}
                                    sx={{ height: '40px' }}
                                    id="shell-input"
                                    onKeyPress={handleTerminalCommand}
                                    variant="filled"
                                  />
                                  {loadingTerminalCmd && (
                                    <LinearProgress size={5} />
                                  )}
                                  {!loadingTerminalCmd && (
                                    <span
                                      style={{ paddingBottom: '5px' }}
                                    ></span>
                                  )}
                                </Box>
                              </Box>
                            </Modal>

                            <Modal
                              open={openLogs}
                              onClose={() => {
                                setOpenLogs(false);
                                setTerminalParams({});
                              }}
                              aria-labelledby="Pod Logs"
                              aria-describedby="Logs for the kubernetes pod"
                            >
                              <Box sx={terminalStyle}>
                                <Typography
                                  sx={{
                                    display: { xs: 'none', sm: 'block' },
                                  }}
                                  id="modal-modal-title"
                                  variant="h7"
                                  component="h3"
                                >
                                  {terminalParams.pod_name}
                                </Typography>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  {possibleContainers.length > 1 && (
                                    <Select
                                      disabled={
                                        !failing && podList.length === 0
                                      }
                                      sx={{ marginRight: 3 }}
                                      size="small"
                                      value={container}
                                      onChange={changeContainer}
                                    >
                                      {possibleContainers.map((container) => (
                                        <MenuItem
                                          key={container}
                                          value={container}
                                        >
                                          {container}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  )}
                                  <Button
                                    disabled={logsLoading}
                                    startIcon={<ManageSearchIcon />}
                                    aria-label="logs"
                                    onClick={() => {
                                      executeCommand(service, 'get_logs', {
                                        pod_name: logsParams.pod_name,
                                        namespace: logsParams.namespace,
                                        container: container,
                                      });
                                      setLogsOutput([]);
                                      setLogsLoading(true);
                                    }}
                                  >
                                    Get logs
                                  </Button>
                                  <Typography
                                    sx={{
                                      display: { xs: 'none', sm: 'block' },
                                    }}
                                    id="modal-modal-description"
                                  >
                                    {' '}
                                    in <strong>
                                      {logsParams.pod_name}
                                    </strong>{' '}
                                    pod, container:{' '}
                                    <strong>
                                      {container || possibleContainers[0]}
                                    </strong>
                                  </Typography>
                                </div>
                                {logsLoading && (
                                  <LinearProgress sx={{ marginBottom: -0.5 }} />
                                )}
                                <p
                                  ref={logsPreRef}
                                  style={{
                                    marginBottom: '0px',
                                    padding: '5px',
                                    width: '100%',
                                    height: '600px',
                                    backgroundColor: 'black',
                                    fontSize: '12px',
                                    color: '#fff',
                                    overflow: 'auto',
                                    whiteSpace: 'nowrap',
                                    fontFamily:
                                      'Roboto Mono,Menlo,Liberation Mono,Courier,monospace',
                                  }}
                                >
                                  {logsOutput?.map((log, i) => (
                                    <span key={i}>
                                      {typeof log === 'object' ? (
                                        <>
                                          {'{'}
                                          {Object.keys(log).map((key, idx) => (
                                            <span key={key}>
                                              <span
                                                style={{ color: '#28df28' }}
                                              >
                                                &quot;{key}&quot;:&nbsp;
                                              </span>
                                              <span style={{ color: 'white' }}>
                                                {JSON.stringify(log[key])}
                                                {idx !==
                                                  Object.keys(log).length - 1 &&
                                                  ', '}
                                              </span>
                                            </span>
                                          ))}
                                          {'}'}
                                          <br />
                                        </>
                                      ) : (
                                        log
                                      )}
                                    </span>
                                  ))}
                                </p>
                              </Box>
                            </Modal>

                            <Tooltip title="Read logs from the highlighted pod (default container)">
                              <Button
                                disabled={logsLoading}
                                size="small"
                                style={{
                                  maxWidth: '30px',
                                  maxHeight: '30px',
                                  minWidth: '30px',
                                  minHeight: '30px',
                                }}
                                startIcon={<ManageSearchIcon />}
                                aria-label="logs"
                                onClick={() => {
                                  setPossibleContainers(getPodContainers(pod));
                                  setLogsParams({
                                    pod_name: pod.metadata.name,
                                    namespace: pod.metadata.namespace,
                                    container,
                                  });
                                  // If istio-proxy is there, don't use it for the default container
                                  const tmp_containers = getPodContainers(pod);
                                  const index =
                                    tmp_containers.indexOf('istio-proxy');
                                  if (index !== -1) {
                                    tmp_containers.splice(index, 1);
                                  }
                                  setContainer(tmp_containers[0]);
                                  setLogsOutput([]);
                                  setOpenLogs(true);
                                }}
                              />
                            </Tooltip>
                            <Tooltip title="Debug using sre-debugger container">
                              <Button
                                size="small"
                                style={{
                                  maxWidth: '30px',
                                  maxHeight: '30px',
                                  minWidth: '30px',
                                  minHeight: '30px',
                                }}
                                startIcon={<TroubleshootIcon />}
                                aria-label="debug"
                                onClick={() => {
                                  // If istio-proxy is there, don't use it for the default container
                                  const tmp_containers = getPodContainers(pod);
                                  const index =
                                    tmp_containers.indexOf('istio-proxy');
                                  if (index !== -1) {
                                    tmp_containers.splice(index, 1);
                                  }

                                  setEphemeralInfo({
                                    name: pod.metadata.name,
                                    namespace: pod.metadata.namespace,
                                    container: tmp_containers[0],
                                  });

                                  handleOpenEphDiag(
                                    pod.metadata.name,
                                    pod.metadata.namespace,
                                    tmp_containers[0]
                                  );
                                }}
                              />
                            </Tooltip>

                            <BootstrapDialog
                              fullWidth
                              maxWidth="lg"
                              onClose={handleCloseEphDiag}
                              aria-labelledby="ephemeral-debug-dialog"
                              open={openEphemeralDiag}
                            >
                              <BootstrapDialogTitle
                                id="ephemeral-debug-dialog"
                                onClose={handleCloseEphDiag}
                              >
                                Troubleshooting container
                              </BootstrapDialogTitle>

                              {openEphemeralLoader ? (
                                <DialogContentLoader dividers>
                                  <CircularProgress />
                                </DialogContentLoader>
                              ) : (
                                <>
                                  <DialogContent dividers>
                                    {createEphemeralOutput.stderr ? (
                                      <Alert severity="error">
                                        <AlertTitle>
                                          {createEphemeralOutput.stderr}
                                        </AlertTitle>
                                      </Alert>
                                    ) : (
                                      <>
                                        <Alert severity="success">
                                          <AlertTitle>
                                            {createEphemeralOutput.stdout}
                                          </AlertTitle>
                                          You can also debug from your terminal
                                          using this command:
                                          <pre style={{ margin: '0px' }}>
                                            kubectl -n {pod.metadata.namespace}{' '}
                                            attach -it {pod.metadata.name} -c
                                            sre-ephemeral
                                          </pre>
                                        </Alert>
                                        <Grid
                                          style={{
                                            marginTop: '20px',
                                            marginBottom: '20px',
                                          }}
                                          container
                                          spacing={1}
                                          justifyContent="center"
                                          alignItems="center"
                                        >
                                          <Grid item xs={11}>
                                            <TextField
                                              id="command"
                                              label="Command"
                                              variant="outlined"
                                              size="small"
                                              fullWidth
                                              onChange={handleEphemeralCommand}
                                            />
                                          </Grid>
                                          <Grid item xs={1}>
                                            <LoadingButton
                                              loading={ephExecLoading}
                                              loadingPosition="start"
                                              startIcon={
                                                <PlayCircleOutlineOutlinedIcon />
                                              }
                                              variant="outlined"
                                              color="primary"
                                              size="small"
                                              aria-label="execute"
                                              onClick={() => {
                                                executeCommand(
                                                  service,
                                                  'shell_command_with_ephemeral',
                                                  {
                                                    cmd: ephemeralCmd,
                                                    pod_name:
                                                      ephemeralInfo.name,
                                                    namespace:
                                                      ephemeralInfo.namespace,
                                                    container:
                                                      ephemeralInfo.container,
                                                  }
                                                );
                                                setEphExecOutput('');
                                                setEphExecLoading(true);
                                              }}
                                            >
                                              <span>Run</span>
                                            </LoadingButton>
                                          </Grid>
                                        </Grid>
                                        {ephExecOutput && (
                                          <Box
                                            style={{
                                              width: '100%',
                                              backgroundColor: '#f5f5f5',
                                              padding: '16px',
                                              marginTop: '15px',
                                              marginBottom: '15px',
                                              borderRadius: '8px',
                                            }}
                                          >
                                            <pre
                                              style={{
                                                marginBottom: '0px',
                                                padding: '5px',
                                                fontSize: '14px',
                                                overflow: 'auto',
                                              }}
                                            >
                                              {ephExecOutput.stdout}
                                            </pre>
                                          </Box>
                                        )}
                                        {service.tags.includes('java') && (
                                          <>
                                            <Typography
                                              variant="body1"
                                              style={{ marginBottom: '15px' }}
                                            >
                                              Useful operations
                                            </Typography>
                                            <LoadingButton
                                              loading={generatingReport}
                                              loadingPosition="start"
                                              startIcon={<MemoryIcon />}
                                              variant="outlined"
                                              color="primary"
                                              size="small"
                                              aria-label="heapdump"
                                              onClick={() => {
                                                executeCommand(
                                                  service,
                                                  'jcmd_command',
                                                  {
                                                    pod_name:
                                                      ephemeralInfo.name,
                                                    namespace:
                                                      ephemeralInfo.namespace,
                                                    container:
                                                      ephemeralInfo.container,
                                                  }
                                                );
                                                setGeneratingReport(true);
                                              }}
                                            >
                                              <span>Heap Dump</span>
                                            </LoadingButton>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </DialogContent>
                                </>
                              )}
                            </BootstrapDialog>

                            {isOwnerOrAdmin(service) &&
                              getPodStatus(pod) !== 'Terminating' && (
                                <Button
                                  color="error"
                                  size="small"
                                  style={{
                                    maxWidth: '30px',
                                    maxHeight: '30px',
                                    minWidth: '30px',
                                    minHeight: '30px',
                                  }}
                                  startIcon={<DeleteIcon />}
                                  aria-label="delete"
                                  onClick={() => {
                                    setSelectedPod(pod.metadata.name);
                                    setOpenDlgKillPod(true);
                                  }}
                                />
                              )}

                            <Dialog
                              open={dlgKillPod}
                              onClose={() => setOpenDlgKillPod(false)}
                            >
                              <DialogTitle>Confirmation</DialogTitle>
                              <DialogContent>
                                Are you sure you want to kill pod:{' '}
                                <strong>{selectedPod}</strong> ?
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={() => setOpenDlgKillPod(false)}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={() => {
                                    executeCommand(service, 'kill_pod', {
                                      pod_name: selectedPod,
                                    });
                                    setOpenDlgKillPod(false);
                                  }}
                                >
                                  Confirm
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={9}
                        >
                          <Collapse
                            in={podExpand[pod.metadata.name]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell style={{ padding: '2px' }} />
                                  <TableCell style={{ padding: '2px' }}>
                                    <strong>Container</strong>
                                  </TableCell>
                                  <TableCell style={{ padding: '2px' }}>
                                    <strong>Image</strong>
                                  </TableCell>
                                  <TableCell style={{ padding: '2px' }}>
                                    <strong>Restarts</strong>
                                  </TableCell>
                                  <TableCell style={{ padding: '2px' }}>
                                    <strong>Status</strong>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {pod.spec.containers.map((container, idx) => (
                                  <TableRow
                                    component={undefined}
                                    size="small"
                                    key={`${idx}_${container.name}`}
                                  >
                                    <TableCell />
                                    <TableCell style={{ padding: '2px' }}>
                                      {container.name}
                                    </TableCell>
                                    <TableCell style={{ padding: '2px' }}>
                                      <Tooltip title={container.image}>
                                        {getShortImage(
                                          container.image,
                                          service.repo_name
                                        )}
                                      </Tooltip>
                                    </TableCell>
                                    <TableCell style={{ padding: '2px' }}>
                                      {pod.status.container_statuses.map(
                                        (status) =>
                                          status.name == container.name && (
                                            <span
                                              key={`${status.name}_${container.name}`}
                                            >
                                              {status.restart_count}
                                            </span>
                                          )
                                      )}
                                    </TableCell>
                                    <TableCell style={{ padding: '2px' }}>
                                      {pod.status.container_statuses.map(
                                        (status) =>
                                          status.name == container.name && (
                                            <span
                                              key={`${status.name}_${container.name}`}
                                            >
                                              {getContainerStatus(status)}
                                            </span>
                                          )
                                      )}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            )}
          </TabPanel>
          {/* Deployment/Rollout Tab */}
          <TabPanel sx={{ p: 0 }} value="2">
            <PodOverview podList={podList} service={service} />
          </TabPanel>
          {/* Ingress/Service Tab */}
          <TabPanel sx={{ p: 0 }} value="3"></TabPanel>
        </TabContext>
      </Box>
      <Snackbar
        open={reportReady.ready || reportReady.error}
        autoHideDuration={10000}
      >
        {reportReady.error ? (
          <Alert
            severity="error"
            onClose={() => {
              setReportReady({ ready: false, error: false });
            }}
          >
            {'"Heap Memory report couldn\'t be generated"'}
          </Alert>
        ) : (
          <Alert
            severity="success"
            sx={{ width: '100%' }}
            action={downloadReportAction}
          >
            Heap Memory report is ready
          </Alert>
        )}
      </Snackbar>
    </CardContent>
  );
}
