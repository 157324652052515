// @ts-nocheck
import { Box, Button, TextField, Alert, Accordion } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import * as React from 'react';
import useCreateService from '../hooks/useCreateService';
import useTeams from '../hooks/useTeams';

let serviceFieldsInfo = [
  {
    field: 'service_name',
    name: 'Service Name',
    ex: 'E.g. puffdirectory',
    required: true,
  },
  {
    field: 'description',
    name: 'Description',
    ex: 'E.g. REST API to access information about Gopuff Teams, Services and Resources',
    required: true,
  },
  {
    field: 'repo_name',
    name: 'Repo Name',
    ex: 'E.g. sre-puffdirectory',
    required: true,
  },
  {
    field: 'service_pagerduty',
    name: 'Service Pagerduty',
    ex: 'E.g. https://gopuff.pagerduty.com/service-directory/P5S90XU',
    required: true,
  },
  {
    field: 'team',
    name: 'Team',
    ex: 'E.g. sre',
    required: true,
    type: 'select',
    options: 'dynamic',
  },
];

let serviceFieldsInfoOptional = [
  {
    field: 'area_of_focus',
    name: 'Area of Focus',
    ex: 'E.g. service discovery (optional)',
  },
  {
    field: 'app_group',
    name: 'App Group',
    ex: 'E.g. Infrastructure (optional)',
  },
  {
    field: 'business_vertical',
    name: 'Business Vertical',
    ex: 'E.g. Infrastructure (optional)',
  },
  {
    field: 'deploy_target',
    name: 'Deploy Target',
    ex: 'E.g. AKS (optional)',
  },
  { field: 'tags', name: 'Tags', ex: 'E.g. python,rest (optional)' },
  {
    field: 'dashboard',
    name: 'Dashboard',
    ex: 'E.g. https://app.datadoghq.com/dashboard/j9t-6ha-rre/puffdirectory-teamsre (optional)',
  },
  {
    field: 'documentation',
    name: 'Documentation',
    ex: 'E.g. https://gopuff.atlassian.net/wiki/spaces/GD/pages/2668068893/PuffDirectory+Overview (optional)',
  },
  {
    field: 'owner',
    name: 'Owner',
    ex: 'E.g. giorgio.diguardia@gopuff.com (optional)',
  },
  {
    field: 'openapi',
    name: 'Open Api',
    ex: 'E.g. https://puffdirectory.sre.gopuff.com/api (optional)',
  },
  { field: 'runbooks', name: 'Runbooks', ex: '(optional)' },
  {
    type: 'select',
    field: 'status',
    name: 'Status',
    ex: 'E.g. Active/Experimental',
    options: ['Experimental', 'Active'],
  },
];
export default function AddExistingServicePage(params) {
  const { response, sendCreateService } = useCreateService();
  const submitExistingService = (event) => {
    //const createService = useCreateService();
    event.preventDefault();
    let allFields = serviceFieldsInfo.concat(serviceFieldsInfoOptional);
    let payload = {};
    for (let field of allFields) {
      let currField = document.querySelector(`#${field.field}`);
      if (currField.value != '') {
        payload[field.field] = currField.value;
      }
    }
    sendCreateService(payload);
  };
  const fieldStyling = { width: '100%', my: 1 };
  const fieldsJSX = (field) => {
    if (field.type && field.type == 'select') {
      let options = [];
      if (field.options == 'dynamic') {
        if (field.field == 'team') {
          const { items } = useTeams();
          options = items.map((team) => {
            return (
              <option key={team.team_name} value={team.team_name}>
                {team.team_name}
              </option>
            );
          });
        } else {
          console.log(
            'field.options == dynamic but no implementation to fetch this dynamically.'
          );
        }
      } else {
        options =
          field.options &&
          field.options.map((o) => {
            return (
              <option key={o} value={o}>
                {o}
              </option>
            );
          });
      }
      return (
        <FormControl fullWidth sx={fieldStyling}>
          <InputLabel id={field.field + 'label'} sx={{ ml: -2 }}>
            {field.name}
          </InputLabel>
          <NativeSelect variant="standard" id={field.field}>
            <option></option>
            {options}
          </NativeSelect>
          <FormHelperText sx={{ ml: 0 }}>{field.ex}</FormHelperText>
        </FormControl>
      );
    } else {
      return (
        <TextField
          key={field.field}
          required={field.required ? true : false}
          id={field.field}
          sx={fieldStyling}
          label={field.name}
          variant="standard"
          helperText={field.ex}
          defaultValue={field.default ? field.default : ''}
        />
      );
    }
  };

  return (
    <form onSubmit={submitExistingService}>
      <Box>
        <h3>Add Existing Service</h3>
      </Box>
      <Accordion expanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content"
          id="panel-header"
        >
          <Typography variant="h6">Required Fields</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {serviceFieldsInfo &&
            serviceFieldsInfo.map((field) => {
              return fieldsJSX(field);
            })}
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ my: 3 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content"
          id="panel-header"
        >
          <Typography variant="h6">Optional Fields</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {serviceFieldsInfoOptional &&
            serviceFieldsInfoOptional.map((field) => {
              return fieldsJSX(field);
            })}
        </AccordionDetails>
      </Accordion>
      <Button variant="contained" type="submit">
        Submit
      </Button>
      {response.response &&
        (response.response.data.success ? (
          <Alert sx={{ my: 1 }} id="alert" severity="success">
            {response.response.data.message}
          </Alert>
        ) : (
          <Alert sx={{ my: 1 }} id="alert" severity="error">
            {response.response.data.message}
          </Alert>
        ))}
    </form>
  );
}
