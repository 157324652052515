import { Circle } from '@mui/icons-material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  MenuItem,
  Select,
  Tab,
  TableCell,
  TableRow,
  Typography,
  Alert,
} from '@mui/material';
import axios from 'axios';
import { useAuth } from 'oidc-react';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { BasicTable } from '../components/BasicTable';
import ResourceList from '../components/ResourceList';
import ServiceFields from '../components/ServiceFields';
import useService from '../hooks/useService';
import Config from '../shared/Config';
import {
  isPuffdirAdmin,
  isTroubleshootingAdmin,
  resourceLifecycleMap,
  statusColor,
} from '../shared/serviceHandler';
import { getK8sCluster } from '../shared/troubleshootingHandler';
import EventsPage from './EventsPage';
import ObservabilityPage from './ObservabilityPage';
import TroubleshootingPage from './TroubleshootingPage';
import LanguageIcon from '../components/LanguageIcon';

const { troubleshootBaseUrl } = Config();
const endpointUrl = `${troubleshootBaseUrl}/api`;
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};
const access_token = window.sessionStorage.getItem('token');
headers.Authorization = `Bearer ${access_token}`;

export default function ServicePage() {
  const auth = useAuth();

  const [environment, setEnvironment] = React.useState('prod');

  const [possibleEnvironments, setPossibleEnvironments] = React.useState([
    'prod',
  ]);
  const [loadingEnvs, setLoadingEnvs] = React.useState(true);
  const [selectEnvDisabled, setSelectEnvDisabled] = React.useState(false);

  const { servicename } = useParams();
  const { service, loading, updateService } = useService(servicename);

  const anchorMap = {
    info: '1',
    events: '2',
    observability: '3',
    troubleshooting: '4',
  };
  const valueMap = {
    1: 'info',
    2: 'events',
    3: 'observability',
    4: 'troubleshooting',
  };

  // Tabs
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    window.location.hash = '#' + valueMap[newValue];
  };

  // when page loads, set tab to anchor
  React.useEffect(() => {
    if (window.location.hash.slice(1) in anchorMap) {
      let anchorValue = anchorMap[window.location.hash.slice(1)];
      setValue(anchorValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPossibleEnvs = async () => {
    const config = { url: `${endpointUrl}/jobs_metrics`, headers };
    if (!loading) {
      try {
        const response = await axios(config.url, config);
        const { data } = response;
        const cluster = getK8sCluster(service);
        if (data.clusters[cluster]) {
          setPossibleEnvironments(data.clusters[cluster]);
        } else {
          setPossibleEnvironments([]);
        }
        // setEnvironment(data.clusters[cluster][0])
        setLoadingEnvs(false);
      } catch (error) {
        // Handle the network error here
        console.error('Network error:', error);
        // You can also set an error state or display an error message to the user
      }
    }
  };

  React.useEffect(() => {
    if (!Object.hasOwn(service, 'error')) {
      fetchPossibleEnvs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  if (Object.hasOwn(service, 'error')) {
    return (
      <Card variant="outlined" sx={{ minWidth: 325 }}>
        <Box sx={{ typography: 'body1' }}>
          <Alert severity="error">
            {service.error.status === 404
              ? `ERROR: ${servicename} not found. Check service name and try again!`
              : `ERROR: (${service.error.status}) ${service.error.statusText}`}
          </Alert>
        </Box>
      </Card>
    );
  }

  const infra_prod = service.infra_dependencies?.filter((item) => {
    const lifecycle = resourceLifecycleMap['prod'];
    return item.lifecycle === lifecycle;
  });
  const infra_uat = service.infra_dependencies?.filter((item) => {
    const lifecycle = resourceLifecycleMap['uat'];
    return item.lifecycle === lifecycle;
  });

  const changeEnvironment = (event) => {
    setEnvironment(event.target.value);
  };

  return (
    <span>
      {loading && (
        <Card variant="outlined" sx={{ minWidth: 325 }}>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label="Info" value="1" />
                  <Tab label="Events" value="2" />
                  <Tab label="Observability" value="3" />
                  {(isPuffdirAdmin(auth) || isTroubleshootingAdmin(auth)) && (
                    <Tab label="Troubleshooting" value="4" />
                  )}
                </TabList>
              </Box>
              <TabPanel value="1">
                <Typography variant="h5" component="div" gutterBottom>
                  <strong>loading</strong>
                  <LinearProgress />
                </Typography>
              </TabPanel>
            </TabContext>
          </Box>
        </Card>
      )}
      {!loading && (
        <span>
          <Card variant="outlined" sx={{ minWidth: 325 }}>
            <Box display="flex" alignItems="center">
              <Typography style={{ padding: '15px' }} variant="h5">
                <Circle
                  sx={{
                    height: '15px',
                    width: '15px',
                    marginRight: '10px',
                    color: statusColor[service.status],
                  }}
                />
                <strong>{service.service_name}</strong>{' '}
              </Typography>
              <Typography
                variant="body2"
                component="div"
                gutterBottom
              ></Typography>
              {service.language && (
                <LanguageIcon
                  name={service.language}
                  sx={{
                    width: 32,
                    height: 32,
                    p: 0.5,
                  }}
                />
              )}
              {!loadingEnvs && (
                <Select
                  disabled={selectEnvDisabled}
                  sx={{
                    marginLeft: '10px',
                  }}
                  size="small"
                  value={environment}
                  onChange={changeEnvironment}
                >
                  {possibleEnvironments.map((env) => (
                    <MenuItem key={env} value={env}>
                      {env}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Info" value="1" />
                    <Tab label="Events" value="2" />
                    <Tab label="Observability" value="3" />
                    {(isPuffdirAdmin(auth) || isTroubleshootingAdmin(auth)) && (
                      <Tab
                        disabled={
                          !(
                            service.status !== 'Deprecated' &&
                            service.k8s_deployment &&
                            service.k8s_namespace
                          )
                        }
                        label="Troubleshooting"
                        value="4"
                      />
                    )}
                  </TabList>
                </Box>
                <TabPanel sx={{ p: 0 }} value="1">
                  <CardContent sx={{ p: 0.5 }}>
                    <ServiceFields
                      service={service}
                      updateService={updateService}
                      environment={environment}
                    />
                    <ResourceList
                      items={service.infra_dependencies}
                      env={environment}
                    />
                    {(service.upstream?.length ||
                      0 + service.downstream?.length ||
                      0) > 0 && (
                      <BasicTable>
                        {environment === 'prod' && (
                          <TableRow>
                            <TableCell>
                              <b>Dependencies (Prod)</b> (
                              {service?.upstream?.length || '0'} upstream,{' '}
                              {service?.downstream?.length || '0'} downstream,{' '}
                              {infra_prod?.length || '0'} infra)
                            </TableCell>
                          </TableRow>
                        )}
                        {environment === 'uat' && (
                          <TableRow>
                            <TableCell>
                              <b>Dependencies (UAT)</b> (
                              {service?.upstream_uat?.length || '0'} upstream,{' '}
                              {service?.downstream_uat?.length || '0'}{' '}
                              downstream, {infra_uat?.length || '0'} infra)
                            </TableCell>
                          </TableRow>
                        )}
                      </BasicTable>
                    )}
                    {/*<EntityDetails entity={service}></EntityDetails>*/}
                  </CardContent>
                </TabPanel>
                <TabPanel style={{ padding: '5px' }} value="2">
                  <EventsPage service={service} environment={environment} />
                </TabPanel>
                <TabPanel style={{ padding: '5px' }} value="3">
                  <ObservabilityPage
                    service={service}
                    environment={environment}
                  />
                </TabPanel>
                <TabPanel style={{ padding: '5px' }} value="4">
                  <TroubleshootingPage
                    service={service}
                    environment={environment}
                    setSelectEnvDisabled={setSelectEnvDisabled}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </Card>
        </span>
      )}
    </span>
  );
}
