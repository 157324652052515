import React from 'react';
import { Avatar } from '@mui/material';

function ResourceIcon({ name, sx = {} }) {
  name = name?.toLowerCase().replace(' ', '');
  const defaultSx = {
    width: 18,
    height: 18,
  };
  const icon_url = `/assets/icons/${name}.svg`;
  return (
    <Avatar
      alt={name}
      title={name}
      src={icon_url}
      sx={{ ...defaultSx, ...sx }}
    />
  );
}

export default ResourceIcon;
