export default function useTitle(pageTitle) {
  // Set the document title based on the current window title or pageTitle if provided.
  // If needed this can be overriden for a specific page by providing pageTitle value, e.g. useTitle('thisTitle').
  const title_suffix = 'Puffdirectory';
  try {
    // Set the document title based on the current pageTitle after converting it to title case
    let title;

    if (pageTitle === '/') {
      title = 'Home - ';
    } else {
      title =
        pageTitle
          .split('/')[1]
          .toLowerCase()
          .replace(/\b\w/g, (s) => s.toUpperCase()) + ' - ';
    }
    document.title = title + `${title_suffix}`;
  } catch (e) {
    console.error(e);
    document.title = title_suffix;
  }
}
