// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import {
  Tooltip,
  Typography,
  Chip,
  Box,
  InputLabel,
  Link,
  Paper,
  InputBase,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
} from '@mui/material';
import { CrisisAlert, FilterAlt, MoreVert } from '@mui/icons-material';

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import useEvent from '../hooks/useEvents';

export default function ReleasesPage() {
  const [fullRows, setFullRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const twoDaysAgo = new Date(new Date() - 1000 * 60 * 60 * 24 * 2);
  const now = new Date();
  const [startDate, setStartDate] = useState(twoDaysAgo.toISOString());
  const [endDate, setEndDate] = useState(now.toISOString());

  const [filterValue, setFilterValue] = useState('');
  const [isDownload, setIsDownload] = useState(false);
  const { event } = useEvent(
    '',
    'release',
    'prod',
    true,
    startDate,
    endDate,
    isDownload
  );

  // <---copied from mui for dropdown anchor
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  // ------>
  useEffect(() => {
    if (event.events) {
      const newRows = event.events.map((release, index) => {
        release.created = release.created.split(' ').join('T'); // change to T for chrome iOS
        return {
          id: index,
          created: release.created,
          ago: formatDistanceToNow(
            zonedTimeToUtc(release.created, 'UTC')
          ).replace('about ', '~ '), // database stores in EST
          service_name: release.metadata.service_name,
          env: release.metadata.env,
          rollback: release.metadata.rollback,
          cluster: release.metadata.cluster,
          team: release.metadata.team,
          author_name: release.metadata.author_name,
          type: release.metadata.type,
          version: release.metadata.version,
          diff: release.metadata.diff,
        };
      });
      setFullRows(newRows);
    }
  }, [event]);

  useEffect(() => {
    // filter by search
    if (filterValue) {
      setFilteredRows(
        fullRows.filter((row) => {
          const rowJoin = JSON.stringify(row).toLowerCase();
          const regex = new RegExp(filterValue, 'g');
          return rowJoin.match(regex);
        })
      );
    } else {
      setFilteredRows(fullRows);
    }
  }, [fullRows, filterValue]);

  useEffect(() => {
    // if rendered by download, download file
    if (isDownload) {
      download();
      setIsDownload(false);
    }
    // want to trigger download after everything is rendered, filteredrows is the last step
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredRows]);

  const download = () => {
    const element = document.createElement('a');
    const formattedReleases = formatReleases(filteredRows);
    element.setAttribute(
      'href',
      `data:text/plain;charset=utf-8,${encodeURIComponent(formattedReleases)}`
    );
    let fileName = 'releases';
    if (filterValue) {
      fileName += `-${filterValue}`;
    }
    if (startDate) {
      fileName += `-${startDate.split('T')[0]}`;
    }
    if (endDate) {
      fileName += `-${endDate.split('T')[0]}`;
    }
    fileName += '.csv';
    element.setAttribute('download', fileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);
  };

  const handleFilter = (event) => {
    setFilterValue(event.target.value.toLowerCase());
  };

  const handleStartDate = (date) => {
    const startDate = date.format();
    setStartDate(startDate);
  };

  const handleEndDate = (date) => {
    const endDate = date.format();
    setEndDate(endDate);
  };

  const formatReleases = (releases) => {
    const arr = [];
    const headers = [
      'Service Name',
      'Env',
      'Team',
      'Created At',
      'Ago',
      'Author',
      'Version',
      'Changes',
    ];
    arr.push(headers.join(','));
    releases.forEach((release) => {
      const row = [
        release.service_name,
        release.env,
        release.team,
        release.created,
        release.ago,
        release.author_name,
        release.version,
        release.diff,
      ];
      arr.push(row.join(','));
    });
    return arr.join('\n');
  };

  // styling for mobile and web

  const headerStyle = { my: 2, textAlign: { xs: 'center', md: 'left' } };
  const searchStyle = {
    p: '6px 4px',
    display: 'flex',
    alignItems: 'center',
    float: 'left',
    m: { xs: '2% 1% 2% 1%', md: '0' },
    width: { xs: '98%', md: '300px' },
  };
  const dateStyle = {
    m: { xs: '2% 1% 2% 1%', sm: '0% 1% 0% 1%', md: '0 0 1 1' },
    width: { xs: '98%', sm: '45%', md: 250 },
  };
  const dateBoxStyle = {
    float: { md: 'right' },
    width: { xs: '100%', md: '61%' },
  };
  const dropDownStyle = {
    height: 56,
    mx: { xs: '1%', md: '0' },
    width: { xs: '1%', md: '0' },
    display: { xs: 'none', sm: 'inline' },
  };

  return (
    <>
      {' '}
      <Typography variant="h5" sx={headerStyle}>
        Releases{' '}
        <Tooltip title="Releases marked as env: prod">
          <Chip size="small" color="info" variant="outlined" label="Prod" />
        </Tooltip>
      </Typography>
      <Paper component="form" sx={searchStyle}>
        <InputBase
          sx={{ mx: 1, flex: 1 }}
          placeholder="Filter Releases"
          inputProps={{ 'aria-label': 'Filter Releases' }}
          onChange={handleFilter}
        />
        <IconButton type="button" sx={{ p: '10px' }} aria-label="filter">
          <FilterAlt />
        </IconButton>
      </Paper>
      <Box sx={dateBoxStyle}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            sx={dateStyle}
            label="Start Date"
            value={startDate ? dayjs(startDate) : ''}
            onChange={handleStartDate}
            format="L hh:mm a"
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            sx={dateStyle}
            label="End Date"
            value={endDate ? dayjs(endDate) : ''}
            onChange={handleEndDate}
            format="L hh:mm a"
          />
        </LocalizationProvider>

        <IconButton onClick={handleClick} sx={dropDownStyle}>
          <MoreVert />
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem
            key="Download"
            onClick={(e) => {
              setIsDownload(true);
              handleClose(e);
            }}
          >
            Download (.csv)
          </MenuItem>
        </Menu>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="right">Env</TableCell>
              <TableCell align="right">Service Name</TableCell>
              <TableCell
                align="right"
                sx={{
                  display: { xs: 'none', sm: 'table-cell' },
                }}
              >
                Team
              </TableCell>
              <TableCell align="right">Created</TableCell>
              <TableCell
                align="right"
                sx={{
                  display: { xs: 'none', sm: 'table-cell' },
                }}
              >
                Author
              </TableCell>
              <TableCell align="right">Version</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.slice(0, 100).map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0,
                  },
                }}
              >
                <TableCell align="right">{row.env}</TableCell>
                <TableCell
                  component="a"
                  href={`/service/${row.service_name}`}
                  align="right"
                >
                  {row.rollback && row.rollback !== 'no' ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}
                    >
                      <Tooltip title="This was a Rollback Deployment">
                        <CrisisAlert
                          sx={{
                            color: 'red',
                            fontSize: '18px',
                          }}
                        />
                      </Tooltip>
                      {row.service_name}
                    </Box>
                  ) : (
                    row.service_name
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'table-cell',
                    },
                  }}
                  component="a"
                  href={`/team/${row.team}`}
                  align="right"
                >
                  {row.team}
                </TableCell>
                <Tooltip
                  title={`${row.created.replace('T', ' ').slice(0, 19)} EST`}
                >
                  <TableCell align="right">{`${row.ago} ago`}</TableCell>
                </Tooltip>
                <TableCell
                  align="right"
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'table-cell',
                    },
                  }}
                >
                  {row.author_name ? row.author_name.replace(/_/g, ' ') : 'N/A'}
                </TableCell>
                {row.diff && (
                  <Tooltip title={row.version}>
                    <TableCell align="right">
                      <Link href={row.diff} target="_blank">
                        {row.version && row.version.length > 15
                          ? `...${row.version.slice(-6)}`
                          : row.version}
                      </Link>
                    </TableCell>
                  </Tooltip>
                )}
                {!row.diff && (
                  <TableCell align="right">
                    {row.version && row.version.length > 15
                      ? `...${row.version.slice(-6)}`
                      : row.version}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <InputLabel>
        *Results are only confined to 10000 rows (only displays most recent 100
        events), please download to see more results.
      </InputLabel>
    </>
  );
}
