import { faro } from '@grafana/faro-web-sdk';

export function handlerApiError(httpException, setErrorMsg) {
  console.error(httpException);
  faro.api.pushError(new Error(httpException));
  const msg = httpException.response?.data?.message || httpException.message;
  if (setErrorMsg) {
    setErrorMsg(msg);
  }
}
