import React from 'react';
import axios from 'axios';
import Config from '../shared/Config';
import { handlerApiError } from '../shared/errorHandler';

const { apiBaseUrl } = Config();
const endpointUrl = `${apiBaseUrl}/api/events`;
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const cache = {};

/**
 * @param {string} serviceName
 * @param {string} event_type
 * @param {string} env
 * @param {boolean} include_team
 * @param {string} startDate
 * @param {string} endDate
 * @param {boolean} isDownload
 * @returns
 */
export default function useEvents(
  serviceName,
  event_type,
  env = '',
  include_team = false,
  startDate = '',
  endDate = '',
  isDownload = false
) {
  const access_token = window.sessionStorage.getItem('token');
  headers.Authorization = `Bearer ${access_token}`;
  const [ev_loading, setLoading] = React.useState(true);
  const [event, setEvent] = React.useState({});

  React.useEffect(() => {
    if (event_type && event_type.length === 0) {
      console.log('empty');
      setEvent({});
      setLoading(false);
      return;
    }
    const fetchData = async () => {
      let counter = 0; // limit number of api calls
      let currentData = {};
      do {
        counter++;
        // iterate through all results if isDownload
        let nextToken = '';
        if (isDownload && currentData.nextToken) {
          nextToken = currentData.nextToken;
        }
        let envStr = '';
        if (env && env.length > 0) {
          envStr = `&env=${env}`;
        }
        const config = {
          url: `${endpointUrl}?service=${serviceName}&event_type=${event_type}&include_team=${include_team}&start_date=${startDate}&end_date=${endDate}${envStr}&nextToken=${nextToken}`,
          headers,
        };
        try {
          const response = await axios(config);
          const { data } = response;
          // concat previous results if downloading
          if (
            isDownload &&
            currentData.events &&
            currentData.events.length > 0
          ) {
            data.events = currentData.events.concat(data.events);
          }
          currentData = data;
        } catch (err) {
          handlerApiError(err);
        }
      } while (isDownload && currentData.nextToken && counter < 5);
      setEvent(currentData);
      cache[`ev_${serviceName}_${event_type}`] = currentData;
      setLoading(false);
    };

    if (cache[`ev_${serviceName}_${event_type}`]) {
      setEvent(cache[`ev_${serviceName}_${event_type}`]);
      setLoading(false);
    } else {
      fetchData();
    }
  }, [
    serviceName,
    event_type,
    env,
    include_team,
    startDate,
    endDate,
    isDownload,
  ]);

  return { ev_loading, event };
}
