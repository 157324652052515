import React from 'react';
import { Avatar, Box, Button } from '@mui/material';
import { Link as RLink } from 'react-router-dom';

export function OnCall({ person, shortname = false }) {
  return (
    <Button
      size="small"
      key={person?.email}
      startIcon={
        <Avatar
          alt={person?.full_name}
          src={person?.slack_photo}
          sx={{
            width: 25,
            height: 25,
          }}
        />
      }
      component={RLink}
      to={`/person/${person?.email?.replace('@gopuff.com', '')}`}
    >
      <Box display="inline">
        {person?.name ? (
          <>
            <Box display="inline">{person?.name.split(' ')[0]}</Box>
            <Box
              sx={{
                display: {
                  xs: 'none',
                  sm: 'contents',
                },
              }}
            >
              {' ' + person?.name.split(' ').slice(1).join(' ')}
            </Box>
          </>
        ) : (
          'N/A'
        )}
      </Box>
    </Button>
  );
}
