import React from 'react';
import { TextField, Link } from '@mui/material';
import { styled } from '@mui/material/styles';

export function getK8sCluster(service) {
  return service.k8s_cluster;
}

export function getMotd(service) {
  const now = new Date();
  const motd = [
    `Last login: ${now.toString()}\n`,
    '                            ________\n',
    `   ____ _____  ____  __  __/ __/ __/     Service:     ${service.service_name}\n `,
    ' / __ `/ __ \\/ __ \\/ / / / /_/ /_       Cluster:     ' +
      getK8sCluster(service) +
      '\n',
    ' / /_/ / /_/ / /_/ / /_/ / __/ __/       Deployment:  ' +
      service.k8s_deployment +
      '\n',
    ' \\__, /\\____/\\____/\\__,_/_/ /_/____      Namespace:   ' +
      service.k8s_namespace +
      '\n',
    '/____/     /_/  / ___// __ \\/ ____/ \n',
    '                \\__ \\/ /_/ / __/    \n',
    '               ___/ / _, _/ /___    \n',
    '              /____/_/ |_/_____/    \n',
    ' \n',
    'Welcome to the puffdirectory shell\n',
  ];
  return motd;
}

export function humanifyAge(ts) {
  const currentTime = new Date().getTime();
  const datetime = new Date(ts).getTime();
  const seconds = Math.round((currentTime - datetime) / 1000);
  if (seconds < 60) {
    return `${seconds}s`;
  }
  if (seconds >= 60 && seconds < 3600) {
    return `${Math.floor(seconds / 60)}m${seconds % 60}s`;
  }
  if (seconds >= 3600 && seconds < 86400) {
    return `${Math.floor(seconds / 3600)}h${Math.floor(
      (seconds % 3600) / 60
    )}m`;
  }
  if (seconds >= 86400) {
    return `${Math.floor(seconds / 86400)}d${Math.floor(
      (seconds % 86400) / 3600
    )}h`;
  }
  return Math.round((currentTime - datetime) / 1000);
}

export function getPodContainers(pod) {
  const containers = [];

  // Check if the pod has any containers
  if (pod.spec.containers) {
    // Loop through each container in the pod
    for (const container of pod.status.container_statuses) {
      // Check if the container is running
      if (container.ready && container.started && container.state.running) {
        // Add the container name to the array
        containers.push(container.name);
      }
    }
  }
  return containers;
}

export function getShortImage(image, repoName) {
  // A commit in git always has a hash that contains 40 characters
  // the short-form of commit hashes should be the first 12 characters.
  const img = image.split('/').pop();
  const parts = img.split(':');
  const ghBaseUrl = 'https://github.com/gopuff';

  return (
    <Link
      target="_blank"
      href={
        parts[1].length == 40
          ? ghBaseUrl + '/' + repoName + '/commit/' + parts[1]
          : ghBaseUrl + '/' + repoName + '/releases/tag/' + parts[1]
      }
    >
      {parts[0] + ':' + parts[1].slice(0, 12)}
    </Link>
  );
}

export function getPodStatus(pod) {
  if (!pod || !pod.status.container_statuses) {
    return 'Unknown';
  }
  for (const containerStatus of pod.status.container_statuses) {
    if (
      containerStatus.state.waiting &&
      containerStatus.state.waiting.reason !== 'ContainerCreating'
    ) {
      return containerStatus.state.waiting.reason;
    }
  }

  for (const condition of pod.status.conditions) {
    if (
      condition.type === 'ContainersReady' &&
      condition.status === 'False' &&
      condition.reason === 'PodCompleted'
    ) {
      return 'Completed';
    }
    if (condition.type === 'ContainersReady' && condition.status === 'False') {
      return 'ContainerCreating';
    }
  }

  if (pod.metadata.deletion_timestamp) {
    return 'Terminating';
  }
  return pod.status.phase;
}

export function getContainerStatus(container) {
  //pod.status.container_statuses[] (.state)
  if (!container) {
    return 'Unknown';
  }
  if (container.state.running && container.ready) {
    return 'Running';
  }
  if (container.state.waiting) {
    return container.state.waiting.reason;
  }
  if (container.state.terminated) {
    return container.state.terminated.reason;
  }
  if (container.state.running && !container.started) {
    return 'ContainerCreating';
  }
  if (container.state.running && !container.ready) {
    return 'ContainerCreating';
  }
  return 'Unknown';
}

export function stylePod(status) {
  if (status === 'CrashLoopBackOff' || status === 'ImagePullBackOff') {
    return { backgroundColor: 'rgb(255 233 233)' };
  }
  if (status === 'ContainerCreating' || status === 'PodInitializing') {
    return { backgroundColor: 'aliceblue' };
  }
  if (status === 'Completed') {
    return { backgroundColor: '#cde7f7' };
  }
  if (status === 'Terminating') {
    return { backgroundColor: '#fbecff' };
  }
  if (status === 'Pending') {
    return { backgroundColor: '#f3c9b9' };
  }
}

export const terminalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 1200,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 0.5,
  m: 0,
};

export function shortenPodName(podname) {
  const parts = podname.split('-');
  if (parts.length < 3) {
    return podname;
  }
  const first = parts.shift();
  const last = parts.pop();
  const middle = `${parts.join('-').slice(0, 4)}*`;
  return [first, middle, last].join('-');
}

export const ShellTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    overflow: 'hidden',
    borderRadius: 0,
    padding: '0px',
    fontFamily: 'monospace',
    backgroundColor: '#424242',
    color: '#fff',
    borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:hover': {
      backgroundColor: '#000',
    },
    '&.Mui-focused': {
      padding: '0px',
      margin: '0px',
      backgroundColor: '#000',
      borderColor: theme.palette.primary.main,
    },
    '&.MuiFilledInput-input': {
      paddingTop: '0px',
      margin: '0px',
      backgroundColor: '#000',
      borderColor: theme.palette.primary.main,
    },
  },
}));
