/// <reference path="../../typedefs.js" />
import React from 'react';
import axios from 'axios';
import Config from '../shared/Config';
import { handlerApiError } from '../shared/errorHandler';

const { apiBaseUrl } = Config();
const endpointUrl = `${apiBaseUrl}/api/team`;
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

const cache = {};

/** @param {string} teamName */
export default function useTeam(teamName) {
  const access_token = window.sessionStorage.getItem('token');
  headers.Authorization = `Bearer ${access_token}`;
  const [loading, setLoading] = React.useState(true);
  /** @type {useState<Team>} */
  const [team, setTeam] = React.useState({ team_name: '' });

  const getTeam = (config, teamName) => {
    config.url = `${endpointUrl}/${teamName}`;
    axios(config)
      .then((response) => {
        const { data } = response;
        console.log(`useTeam: ${teamName}`, { data });
        setTeam(data);
        cache[teamName] = data;
        setLoading(false);
      })
      .catch((err) => handlerApiError(err));
  };

  React.useEffect(() => {
    const config = { url: endpointUrl, headers };
    if (cache[teamName]) {
      setTeam(cache[teamName]);
      setLoading(false);
    } else {
      getTeam(config, teamName);
    }
  }, [teamName]);

  /**
   * @param {Team} team
   * @param {any} json
   * @returns
   */
  const updateTeam = (team, json) => {
    const config = {
      url: `${endpointUrl}/${team.team_slug}`,
      headers,
      method: 'put',
      data: json,
    };
    return axios(config);
  };

  return { loading, team, updateTeam };
}
