/// <reference path="../../typedefs.js" />
import React from 'react';
import axios from 'axios';
import Config from '../shared/Config';
import { handlerApiError } from '../shared/errorHandler';

const { apiBaseUrl } = Config();
const endpointUrl = `${apiBaseUrl}/api/service`;
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export default function useCreateService() {
  const access_token = window.sessionStorage.getItem('token');
  headers.Authorization = `Bearer ${access_token}`;
  const [response, setResponse] = React.useState({});

  /** @param {Service} json */
  const sendCreateService = async (json) => {
    const config = {
      url: endpointUrl,
      headers,
      method: 'post',
      data: json,
    };
    axios(config)
      .then((resp) => {
        setResponse(resp);
      })
      .catch((err) => {
        setResponse(err);
        handlerApiError(err);
      });
  };

  return { response, sendCreateService };
}
