import React from 'react';
import axios from 'axios';
import Config from '../shared/Config';
import { handlerApiError } from '../shared/errorHandler';

const { apiBaseUrl } = Config();
const endpointUrl = `${apiBaseUrl}/api/services?verbose=true`;
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};
let cache = null;

export default function useServices() {
  const access_token = window.sessionStorage.getItem('token');
  headers.Authorization = `Bearer ${access_token}`;
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchData = async () => {
      const config = { url: endpointUrl, headers };
      axios(config)
        .then((response) => {
          const { data } = response;
          console.log('useServices:', { data });
          setItems(data);
          cache = data;
          setLoading(false);
        })
        .catch((err) => handlerApiError(err));
    };

    if (cache) {
      setItems(cache);
      setLoading(false);
    } else {
      fetchData();
    }
  }, []);

  return { loading, items };
}
