import React from 'react';
import {
  ArrowRight,
  Groups,
  MiscellaneousServices,
  People,
  Storage,
} from '@mui/icons-material';
import {
  Card,
  Link,
  Box,
  List,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  MenuList,
  Typography,
  Stack,
} from '@mui/material';
import { useAuth } from 'oidc-react';
import { Link as RLink } from 'react-router-dom';
import PolylineIcon from '@mui/icons-material/Polyline';
import usePerson from '../hooks/usePerson';
import { formatTeamSlug, isPuffdirAdmin } from '../shared/serviceHandler';
import KeyIcon from '@mui/icons-material/Key';

// import { useSearch, searchNameFn, searchPathFn } from "../hooks/useSearch";

export default function HomePage() {
  const auth = useAuth();
  const { email } = auth.userData.profile;
  const { person, loading } = usePerson(email);

  // After 1st login, Redirect to entry page.
  if (sessionStorage.getItem('entry.path')) {
    const path = sessionStorage.getItem('entry.path');
    window.location.replace(path); // TODO; figure out why navigate(path) doesn't work here
    sessionStorage.removeItem('entry.path');
  }

  // if (auth && auth.userData) {
  //   fetch('https://backstage.gopuff.com/api/catalog/entities?filter=kind=component', {
  //     headers: {
  //       Authorization: `Bearer ${auth.userData.id_token}`,
  //     },
  //   }).then(r => r.json()).then(r => console.log('backstage', r)).catch(e => console.error(e));
  // }

  return (
    <span>
      {auth && auth.userData ? (
        <div>
          <Card sx={{ p: 1, minHeight: 400 }}>
            <h2>Welcome to Puffdirectory</h2>
            <p>
              Puffdirectory is a tool to aggregate resources in one place. Its
              purpose is to allow teams to manage their own services, groups,
              and other resources in a self-service fashion. It also works on
              mobile!
            </p>
            <p>
              For more information, please see{' '}
              <Link
                href="https://gopuff.atlassian.net/wiki/spaces/GD/pages/2668068893/PuffDirectory+Overview"
                target="_blank"
                rel="noreferrer"
              >
                the About page
              </Link>
              .
            </p>
            <Typography
              sx={{ textAlign: 'center' }}
              variant="h6"
              component="h2"
              gutterBottom
            >
              Team & Service Catalog
            </Typography>
            <MenuList
              sx={{
                maxWidth: 240,
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              {loading ? (
                <MenuItem key="loading" divider>
                  <ListItemIcon>
                    <Groups fontSize="large" color="primary" />
                  </ListItemIcon>
                  <ListItemText>Loading...</ListItemText>
                </MenuItem>
              ) : (
                person.engineering_teams?.map((team) => (
                  <MenuItem
                    key={team}
                    divider
                    component={RLink}
                    to={`/team/${team}`}
                  >
                    <ListItemIcon>
                      <Groups fontSize="large" color="primary" />
                    </ListItemIcon>
                    <ListItemText>{formatTeamSlug(team)}</ListItemText>
                  </MenuItem>
                ))
              )}
              <MenuItem divider component={RLink} to="/Teams">
                <ListItemIcon>
                  <Groups fontSize="large" color="primary" />
                </ListItemIcon>
                <ListItemText>Teams</ListItemText>
              </MenuItem>
              <MenuItem divider component={RLink} to="/People">
                <ListItemIcon sx={{ pl: 1 }}>
                  <ArrowRight fontSize="small" />
                  <People color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText> People</ListItemText>
              </MenuItem>
              <MenuItem divider component={RLink} to="/services">
                <ListItemIcon>
                  <MiscellaneousServices color="primary" fontSize="large" />
                </ListItemIcon>
                <ListItemText>Services</ListItemText>
              </MenuItem>
              <MenuItem divider component={RLink} to="/resources">
                <ListItemIcon sx={{ pl: 1 }}>
                  <ArrowRight fontSize="small" />
                  <Storage color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText> Resources</ListItemText>
              </MenuItem>
              <MenuItem divider component={RLink} to="/releases">
                <ListItemIcon sx={{ pl: 1 }}>
                  <Storage color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText> Releases</ListItemText>
              </MenuItem>
              {isPuffdirAdmin(auth) && (
                <MenuItem divider component={RLink} to="/api-keys">
                  <ListItemIcon sx={{ pl: 1 }}>
                    <KeyIcon color="primary" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText sx={{ color: '#d40000' }}>
                    API Keys Service
                  </ListItemText>
                </MenuItem>
              )}
            </MenuList>
            <Box
              width="60%"
              alignItems="center"
              sx={{
                maxWidth: 340,
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              <Stack spacing={1}>
                <Button
                  component={RLink}
                  to="/templates"
                  type="submit"
                  variant="outlined"
                  size="medium"
                  startIcon={<PolylineIcon />}
                  sx={{ mt: 5 }}
                >
                  Create New Service
                </Button>
                <Button
                  component={RLink}
                  to="/addexistingservice"
                  type="submit"
                  variant="outlined"
                  size="medium"
                  startIcon={<PolylineIcon />}
                  sx={{ mt: 5 }}
                >
                  Add Existing Service
                </Button>
              </Stack>
            </Box>
          </Card>
        </div>
      ) : (
        <h2>Login to Okta</h2>
      )}
    </span>
  );
}
