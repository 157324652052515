import { Card, LinearProgress, TextField, Typography } from '@mui/material';
import React from 'react';
import SortableTable from '../components/SortableTable';
import useServices from '../hooks/useServices';

const columns = [
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'service_name',
    route: true,
    resource: 'claim-service',
    numeric: false,
    disablePadding: false,
    label: 'Service Name',
  },
  {
    id: 'tier',
    numeric: true,
    disablePadding: false,
    label: 'Tier',
  },
  {
    id: 'repo_name',
    numeric: false,
    disablePadding: false,
    label: 'Repo',
  },
];

export default function OrphanServicesPage() {
  let { items, loading } = useServices();
  items = items.filter((i) => !i.team && i.status !== 'Deprecated');
  const [searchItems, setSearchItems] = React.useState([]);
  const [searchActive, setSearchActive] = React.useState(false);

  const handleInputChange = async (e) => {
    const len = e.target.value.length;
    if (e.target.value === '') {
      console.log('clear input', e.target.value);
      setSearchActive(false);
      setSearchItems([]);
    }
    if (len > 1) {
      setSearchActive(true);
      console.log('search:', e.target.value);
      const filtered = items.filter(
        (i) =>
          i.service_name.toLowerCase().indexOf(e.target.value.toLowerCase()) >=
          0
      );
      setSearchItems(filtered);
    }
  };

  return (
    <span>
      <Typography variant="h6" component="h1" sx={{ p: 1.5 }}>
        Orphan Services ({items.length})
      </Typography>
      <Card style={{ padding: '0px' }} variant="outlined" sx={{ padding: 0 }}>
        <form noValidate autoComplete="off">
          <TextField
            size="small"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
            }}
            id="outlined-search"
            label="Filter services"
            type="search"
            variant="outlined"
            onChange={handleInputChange}
          />
        </form>
        {loading ? (
          <span>
            <LinearProgress />
            loading...
          </span>
        ) : (
          ''
        )}
        {searchItems?.length > 0 && (
          <SortableTable
            id="orphan-services"
            rows={searchItems}
            columns={columns}
          />
        )}
        {searchActive && searchItems?.length === 0 && (
          <div>No search results</div>
        )}
        {!searchActive ? (
          <SortableTable id="orphan-services" rows={items} columns={columns} />
        ) : null}
      </Card>
    </span>
  );
}
