import { Chip, Grid, Tooltip, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import DonutChart from './DonutChart';
import LayersRoundedIcon from '@mui/icons-material/LayersRounded';
import ElectricBoltRoundedIcon from '@mui/icons-material/ElectricBoltRounded';
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import StorageIcon from '@mui/icons-material/Storage';
import { getK8sCluster } from '../shared/troubleshootingHandler';
import { useTheme } from '@emotion/react';

export default function PodOverview({ podList, service }) {
  const theme = useTheme();

  return (
    <>
      <>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={3}
        >
          <Grid
            item
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            md={4}
            xs={12}
          >
            <DonutChart podList={podList} />
          </Grid>

          <Grid
            container
            direction="column"
            justifyContent="space-around"
            alignItems="flex-start"
            item
            xs={12}
            md={8}
            spacing={1}
          >
            <Grid
              container
              item
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography variant="subtitle1" sx={{ color: '#666666' }}>
                CONTAINER:
              </Typography>
            </Grid>
            <Grid
              container
              item
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <LayersRoundedIcon />
              </Grid>
              {podList && podList.length > 0 && (
                <Grid item xs={11}>
                  <Typography
                    variant="body2"
                    sx={{ color: '#666666', wordBreak: 'break-all' }}
                  >
                    <strong>Image:</strong>{' '}
                    {podList[0].spec.containers[0].image}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              item
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <ElectricBoltRoundedIcon />
              </Grid>
              {podList && podList.length > 0 && (
                <Grid item xs={11}>
                  <Typography variant="body2" sx={{ color: '#666666' }}>
                    <strong>Ports:</strong>{' '}
                    {podList[0].spec.containers[0].ports
                      .map((port) => `${port.container_port}/${port.protocol}`)
                      .join(', ')}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <BookmarkRoundedIcon />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body2" sx={{ color: '#666666' }}>
                  <strong>Namespace:</strong>{' '}
                  {podList[0]?.metadata?.namespace || 'N/A'}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <StorageIcon />
              </Grid>
              <Grid item xs={11}>
                <Typography variant="body2" sx={{ color: '#666666' }}>
                  <strong>Cluster:</strong>
                  <Tooltip title="Kubernetes Cluster of this deployment">
                    <Chip
                      style={{
                        color: '#fff',
                        marginLeft: '5px',
                      }}
                      color="primary"
                      label={getK8sCluster(service) || 'N/A'}
                    />
                  </Tooltip>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    </>
  );
}
