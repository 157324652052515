import {
  Table,
  TableBody,
  AlertTitle,
  TableCell,
  Select,
  MenuItem,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputLabel,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  OutlinedInput,
  LinearProgress,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Modal,
  Chip,
  Alert,
  Tooltip,
} from '@mui/material';
import { Link } from 'react-router-dom';
import * as React from 'react';
import { useState } from 'react';
import { handlerApiError } from '../shared/errorHandler';
import { CopyToClipboardButton } from '../shared/CopyToClipboard';
import Config from '../shared/Config';
import axios from 'axios';
import { timeAgo } from '../shared/data-utils';
import DeleteIcon from '@mui/icons-material/Delete';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Person, Settings } from '@mui/icons-material';
import { isPuffdirAdmin } from '../shared/serviceHandler';
import { useAuth } from 'oidc-react';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { faro } from '@grafana/faro-web-sdk';

const { apikeysBaseUrl } = Config();
const endpointUrl = `${apikeysBaseUrl}/api`;
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};
const access_token = window.sessionStorage.getItem('token');
headers.Authorization = `Bearer ${access_token}`;
export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 5,
  m: 5,
};

const StyleTableCell = styled(TableCell)`
  padding: 8px;
  margin: 1px;
`;

function convertToLocal(date_str) {
  // Convert UTC date string to local date string
  const date = new Date(`${date_str} UTC`);
  const localDate = date.toLocaleString();
  return localDate;
}

export default function ApiKeysPage(props) {
  const auth = useAuth();
  const [keys, setKeys] = useState([]);
  const [filteredkeys, setfilteredKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [failing, setFailing] = useState(false);
  const [failingMsg, setFailingMsg] = useState('');
  const [open, setOpen] = useState(false);
  const [apiKeyName, setApiKeyName] = useState('');
  const [apiKeyServiceName, setApiKeyServiceName] = useState('');
  /** @type {useState<object>} */
  const [newKey, setNewKey] = useState({});
  const [dlgRotate, setDlgRotate] = useState(false);
  const [dlgDelete, setDlgDelete] = useState(false);
  const [dlgViewApi, setdlgViewApi] = useState(false);
  /** @type {useState<object>} */
  const [selectedKey, setSelectedKey] = useState({});
  const [serviceFilter, setServiceFilter] = useState('ALL');
  /** @type {useState<object>} */
  const [viewApiContent, setViewApiContent] = useState({});
  const [checkedAdmin, setCheckedAdmin] = React.useState(false);
  const [userScope, setUserScope] = React.useState('');

  const changeService = (event) => {
    const {
      target: { value },
    } = event;
    let filtered;
    if (value === 'ALL') {
      filtered = keys;
    } else {
      filtered = keys.filter((i) => i.service === value);
    }
    setServiceFilter(event.target.value);
    setfilteredKeys(filtered);
  };

  const handleChangeAdmin = (event) => {
    setCheckedAdmin(event.target.checked);
    setUserScope(event.target.checked ? 'admin' : '');
  };

  const handleTextFieldChange = (event) => {
    const regex = /^[a-z0-9\._@-]*$/; // regular expression to match allowed characters
    const inputValue = event.target.value.toLowerCase(); // convert input to lowercase
    if (regex.test(inputValue)) {
      setApiKeyName(inputValue); // input is valid, update state
    } else {
      // input is invalid, show error message or prevent further action
    }
  };

  const handleTextFieldServiceChange = (event) => {
    const regex = /^[a-z0-9\-]*$/; // regular expression to match allowed characters
    const inputValue = event.target.value.toLowerCase(); // convert input to lowercase
    if (regex.test(inputValue)) {
      setApiKeyServiceName(inputValue); // input is valid, update state
    } else {
      // input is invalid, show error message or prevent further action
    }
  };

  const handleOpen = () => {
    setApiKeyName('');
    setApiKeyServiceName('');
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    getKeys();
  }, []);

  function formatDate(d) {
    const inputDate = new Date(d);
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const year = inputDate.getFullYear();
    const month = months[inputDate.getMonth()];
    const day = inputDate.getDate();
    const hour = inputDate.getHours() % 12 || 12;
    const minute = inputDate.getMinutes();
    const ampm = inputDate.getHours() >= 12 ? 'pm' : 'am';

    const outputDateStr = `${month} ${day} ${year}, ${hour}:${minute} ${ampm}`;
    return outputDateStr;
  }

  const getKeys = () => {
    setLoading(true);
    setFailing(false);
    const config = { url: `${endpointUrl}/apikeys`, headers };
    axios(config)
      .then((response) => {
        const { data } = response;
        data.sort((a, b) => {
          const hasDotA = a.name.includes('.');
          const hasDotB = b.name.includes('.');

          if (hasDotA && !hasDotB) {
            return 1; // Move a to the end
          } else if (!hasDotA && hasDotB) {
            return -1; // Move b to the end
          } else {
            return 0; // Preserve the order
          }
        });
        setKeys(data);
        setfilteredKeys(data);
        setLoading(false);
      })
      .catch((err) => {
        setFailing(true);
        setLoading(false);
        if (err.message == 'Network Error') {
          setFailingMsg(
            `Could not connect to SRE Api Keys Service: ${apikeysBaseUrl}`
          );
        } else {
          const statusCode = err.response?.status;
          const errorMessage = err.response?.data?.message;
          setFailingMsg(`Error ${statusCode}: ${errorMessage}`);
        }
      });
  };

  const deleteApiKey = (keyId) => {
    setNewKey({});
    const config = { url: `${endpointUrl}/apikey?key_id=${keyId}`, headers };
    axios
      .delete(config.url, config)
      .then((response) => {
        const { data } = response;
        getKeys();
        setLoading(true);
      })
      .catch((err) => handlerApiError(err));
  };

  const rotateApiKey = (keyId) => {
    setNewKey({});
    const config = {
      url: `${endpointUrl}/apikey_rotate?key_id=${keyId}`,
      headers,
      data: { keyId },
    };
    axios
      .post(config.url, config.data, config)
      .then((response) => {
        const { data } = response;
        faro.api.pushLog(['rotateApiKey']);
        console.log('rotateApiKey ', { data });
        getKeys();
        setLoading(true);
      })
      .catch((err) => handlerApiError(err));
  };

  const createApiKey = (name, service, scope) => {
    setNewKey({});
    const username = auth.userData.profile.email.replace('@gopuff.com', '');
    const config = {
      url: `${endpointUrl}/apikey?name=${name}&service=${service}&scope=${scope}`,
      headers,
      data: {},
    };
    axios
      .post(config.url, config.data, config)
      .then((response) => {
        const { data } = response;
        setNewKey(data);
        getKeys();
        faro.api.pushLog(['createApiKey', { name, service, scope }]);
      })
      .catch((err) => handlerApiError(err));
  };

  const getKey = (keyId) => {
    setNewKey({});
    const config = { url: `${endpointUrl}/apikey/${keyId}`, headers };
    // Get a single key, for visualization purposes
    axios(config)
      .then((response) => {
        const { data } = response;
        setViewApiContent(data);
        setdlgViewApi(true);
      })
      .catch((err) => handlerApiError(err));
  };

  return (
    <>
      <Typography sx={{ mt: 2 }} variant="h5">
        API Keys Service ({filteredkeys.length})
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex">
          {!loading && (
            <FormControl sx={{ marginTop: 1, width: 150 }}>
              <InputLabel id="service">Filter by</InputLabel>
              <Select
                defaultValue="ALL"
                labelId="service"
                id="service"
                size="small"
                value={serviceFilter}
                onChange={changeService}
                input={<OutlinedInput label="Filter by" />}
              >
                <MenuItem value="ALL" selected>
                  ALL
                </MenuItem>
                {[...new Set(keys.map((obj) => obj.service))].map((svc) => (
                  <MenuItem key={svc} value={svc}>
                    {svc}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={handleOpen}
          >
            Create API Key
          </Button>
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create API Key</DialogTitle>
        <DialogContent>
          <Typography sx={{ mt: 2 }} variant="body2" color="text.secondary">
            Insert api key name and service (For a username key, use @username)
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <TextField
              sx={{ marginTop: 2 }}
              label="API Key Name"
              value={apiKeyName}
              onChange={handleTextFieldChange}
            />
            <TextField
              sx={{ marginTop: 2 }}
              label="Service"
              value={apiKeyServiceName}
              onChange={handleTextFieldServiceChange}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedAdmin}
                  onChange={handleChangeAdmin}
                  name="admin"
                  color="primary"
                />
              }
              label="Admin"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              createApiKey(apiKeyName, apiKeyServiceName, userScope);
              setOpen(false);
            }}
            color="primary"
          >
            Create API Key
          </Button>
        </DialogActions>
      </Dialog>
      {newKey.key_id && (
        <Alert severity="success">
          <AlertTitle>API Key {newKey.name} created successfully</AlertTitle>
          <p>Api ID: {newKey.key_id}</p>
          <p>
            Api Secret: <strong>{newKey.key_secret}</strong>
          </p>
        </Alert>
      )}
      {!isPuffdirAdmin(auth) && (
        <Alert severity="warning">
          You need to be a puffdirectory admin to access this page, contact SRE
          for more details.
        </Alert>
      )}
      {failing && <Alert severity="error">{failingMsg}</Alert>}
      {loading && <LinearProgress sx={{ mt: -0.5 }} />}
      {!failing && isPuffdirAdmin(auth) && (
        <TableContainer sx={{ mb: 10 }} component={Paper}>
          <Table aria-label="api keys table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Service</TableCell>
                <TableCell
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'table-cell',
                    },
                  }}
                >
                  Key Secret
                </TableCell>
                <TableCell
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'table-cell',
                    },
                  }}
                >
                  Created On
                </TableCell>
                <TableCell>Last Access</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredkeys.map((key, i) => (
                <TableRow size="small" key={i} component={undefined}>
                  {key.name.includes('_xXxrotating') ? (
                    <Tooltip title="This key was recently rotated. If nobody is using this key anymore, it can be safely deleted.">
                      <StyleTableCell sx={{ color: 'red' }}>
                        {key.name.replace('_xXxrotating', '')}
                      </StyleTableCell>
                    </Tooltip>
                  ) : key.name?.includes?.('@') ? (
                    <StyleTableCell>
                      <Button
                        size="small"
                        startIcon={<Person sx={{ width: 15, height: 15 }} />}
                        component={Link}
                        sx={{ p: 0, m: 0 }}
                        style={{ fontSize: '11px' }}
                        to={`/person/${key.name.replace('@', '')}`}
                      >
                        {key.name.replace('@', '')}
                      </Button>
                    </StyleTableCell>
                  ) : (
                    <StyleTableCell>{key.name}</StyleTableCell>
                  )}
                  <StyleTableCell style={{ fontSize: '12px' }}>
                    <Button
                      size="small"
                      sx={{ p: 0, m: 0 }}
                      startIcon={<Settings sx={{ width: 15, height: 15 }} />}
                      style={{ fontSize: '11px' }}
                      component={Link}
                      to={`/service/${key.service}`}
                    >
                      {key.service}
                    </Button>
                  </StyleTableCell>
                  <StyleTableCell
                    style={{ fontFamily: 'monospace', fontSize: '12px' }}
                    sx={{
                      display: {
                        xs: 'none',
                        sm: 'table-cell',
                      },
                    }}
                  >
                    <Tooltip title="View API Credentials">
                      <IconButton
                        size="small"
                        aria-label="view"
                        onClick={() => {
                          getKey(key.key_id);
                        }}
                      >
                        <VisibilityIcon
                          color="primary"
                          style={{
                            maxWidth: '16px',
                            maxHeight: '16px',
                            minWidth: '16px',
                            minHeight: '16px',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    {key.key_secret.slice(26, 32)}
                  </StyleTableCell>
                  <Tooltip title={formatDate(key.created)}>
                    <StyleTableCell
                      sx={{
                        display: {
                          xs: 'none',
                          sm: 'table-cell',
                        },
                      }}
                    >
                      {formatDate(key.created).split(',')[0]}
                    </StyleTableCell>
                  </Tooltip>
                  {key.last_access ? (
                    <StyleTableCell>
                      {timeAgo(convertToLocal(key.last_access))} ago
                    </StyleTableCell>
                  ) : (
                    <StyleTableCell>Never</StyleTableCell>
                  )}
                  <StyleTableCell>
                    <Tooltip title="View API Credentials">
                      <IconButton
                        sx={{
                          display: {
                            xs: 'table-cell',
                            sm: 'none',
                          },
                        }}
                        size="small"
                        aria-label="view"
                      >
                        <VisibilityIcon
                          color="primary"
                          style={{
                            maxWidth: '16px',
                            maxHeight: '16px',
                            minWidth: '16px',
                            minHeight: '16px',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Dialog
                      open={dlgRotate}
                      onClose={() => setDlgRotate(false)}
                    >
                      <DialogTitle>API Key Rotation</DialogTitle>
                      <DialogContent>
                        Rotating the key creates a copy of the key, and tags the
                        old one as &quot;rotating&quot;. Are you sure you want
                        to rotate <strong>{selectedKey.name}</strong> ?
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setDlgRotate(false)}>
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            rotateApiKey(selectedKey.key_id);
                            setDlgRotate(false);
                          }}
                        >
                          Confirm
                        </Button>
                      </DialogActions>
                    </Dialog>

                    <Dialog
                      open={dlgDelete}
                      onClose={() => setDlgDelete(false)}
                    >
                      <DialogTitle>API Key Deletion</DialogTitle>
                      <DialogContent>
                        Deleting a key prevents clients connecting to it, make
                        sure that there was no recent access before deleting it.
                        Are you sure you want to permanently delete{' '}
                        <strong>{selectedKey.name} </strong> ?
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setDlgDelete(false)}>
                          Cancel
                        </Button>
                        <Button
                          onClick={() => {
                            deleteApiKey(selectedKey.key_id);
                            setDlgDelete(false);
                          }}
                        >
                          Confirm
                        </Button>
                      </DialogActions>
                    </Dialog>
                    {viewApiContent.name && (
                      <Modal
                        open={dlgViewApi}
                        onClose={() => setdlgViewApi(false)}
                        aria-labelledby="view-api-key"
                        aria-describedby="view-an-api-key-content"
                      >
                        <Box sx={modalStyle}>
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            API Key
                          </Typography>
                          <Table>
                            <TableRow>
                              <TableCell variant="head">Name</TableCell>
                              <TableCell>
                                {viewApiContent.name && (
                                  <Button
                                    size="small"
                                    startIcon={
                                      <Person sx={{ width: 15, height: 15 }} />
                                    }
                                    component={Link}
                                    sx={{ p: 0, m: 0 }}
                                    style={{ fontSize: '11px' }}
                                    to={`/person/${viewApiContent?.name
                                      ?.replace('@', '')
                                      .replace('_xXxrotating', '')}`}
                                  >
                                    {viewApiContent.name
                                      .replace('@', '')
                                      .replace('_xXxrotating', '')}
                                  </Button>
                                )}
                                {viewApiContent?.name.includes(
                                  '_xXxrotating'
                                ) && (
                                  <Chip
                                    style={{ float: 'right' }}
                                    color="warning"
                                    label={'Rotating'}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell variant="head">Key ID</TableCell>
                              <TableCell
                                style={{
                                  fontFamily: 'monospace',
                                }}
                              >
                                {viewApiContent.key_id}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell variant="head">Service</TableCell>
                              <TableCell
                                style={{
                                  fontFamily: 'monospace',
                                }}
                              >
                                <Button
                                  size="small"
                                  sx={{ padding: 0 }}
                                  startIcon={<Settings />}
                                  component={Link}
                                  to={`/service/${viewApiContent.service}`}
                                >
                                  {viewApiContent.service}
                                </Button>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell variant="head">Scope</TableCell>
                              <TableCell>
                                {viewApiContent.scope
                                  ? viewApiContent.scope
                                      .split(',')
                                      .map((element, index) => (
                                        <Chip
                                          size="small"
                                          key={index}
                                          label={element.trim()}
                                        />
                                      ))
                                  : ''}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell variant="head">Key Secret</TableCell>
                              <TableCell
                                style={{
                                  fontFamily: 'monospace',
                                  fontWeight: 'bold',
                                }}
                              >
                                {viewApiContent.key_secret}
                                <CopyToClipboardButton
                                  content={viewApiContent.key_secret}
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell variant="head">Created</TableCell>
                              <TableCell>
                                {formatDate(viewApiContent.created)}
                                <span style={{ float: 'right' }}>
                                  ({timeAgo(viewApiContent.created)} ago)
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell variant="head">Last Access</TableCell>
                              <TableCell>
                                {viewApiContent.last_access ? (
                                  <>
                                    {timeAgo(
                                      convertToLocal(viewApiContent.last_access)
                                    )}{' '}
                                    ago
                                  </>
                                ) : (
                                  <strong>Never</strong>
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell variant="head">Created by</TableCell>
                              <TableCell>
                                {viewApiContent.username ? (
                                  <Button
                                    size="small"
                                    sx={{ padding: 0 }}
                                    startIcon={<Person />}
                                    component={Link}
                                    to={`/person/${viewApiContent.username}`}
                                  >
                                    {viewApiContent.username}
                                  </Button>
                                ) : (
                                  'N/A'
                                )}
                              </TableCell>
                            </TableRow>
                          </Table>
                        </Box>
                      </Modal>
                    )}
                    <Tooltip title="Rotate API Key (keep this API key, but tag it, and creates a new one with the same name)">
                      <span>
                        <IconButton
                          size="small"
                          disabled={key.name.includes('_xXxrotating')}
                          aria-label="rotate"
                          onClick={() => {
                            setSelectedKey(key);
                            setDlgRotate(true);
                          }}
                        >
                          <ChangeCircleIcon
                            style={{
                              maxWidth: '16px',
                              maxHeight: '16px',
                              minWidth: '16px',
                              minHeight: '16px',
                            }}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Delete API Key">
                      <IconButton
                        size="small"
                        aria-label="delete"
                        onClick={() => {
                          setSelectedKey(key);
                          setDlgDelete(true);
                        }}
                      >
                        <DeleteIcon
                          style={{
                            maxWidth: '16px',
                            maxHeight: '16px',
                            minWidth: '16px',
                            minHeight: '16px',
                            color: 'red',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </StyleTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
