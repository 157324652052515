import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Container } from '@mui/material';
import { AuthProvider } from 'oidc-react';
import './App.css';
import { datadogRum } from '@datadog/browser-rum';
import ServicesPage from './pages/ServicesPage';
import ServicePage from './pages/ServicePage';
import TeamsPage from './pages/TeamsPage';
import TeamPage from './pages/TeamPage';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import Config from './shared/Config';
import SessionProvider from './Session';
import { RedirectPage } from './pages/RedirectPage';
import Copyright from './components/Copyright';
import PeoplePage from './pages/PeoplePage';
import PersonPage from './pages/PersonPage';
import ResourcesPage from './pages/ResourcesPage';
import ResourcePage from './pages/ResourcePage';
import ReleasesPage from './pages/ReleasesPage';

import AppNavigation from './components/AppNavigation';
import OrphanServicesPage from './pages/OrphanServicesPage';
import ClaimServicePage from './pages/ClaimServicePage';
import TemplatesPage from './pages/TemplatesPage';
import TemplatePage from './pages/TemplatePage';
import AddExistingServicePage from './pages/addExistingServicePage';
import ApiKeysPage from './pages/ApiKeysPage';

import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import { TracingInstrumentation } from '@grafana/faro-web-tracing';

const { apiBaseUrl } = Config();

const runEnv = process.env.REACT_APP_CONFIG;

var faro = initializeFaro({
  url: 'https://faro-collector-us-central2.grafana.net/collect/e77e676200217c684b1ea14c8d404ca6',
  app: {
    name: 'puffdirectory',
    version: '1.0.0',
    environment: runEnv,
  },

  instrumentations: [
    // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
    ...getWebInstrumentations(),

    // Initialization of the tracing package.
    // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
    new TracingInstrumentation(),
  ],
});

// TODO: migrate away from Datadog
datadogRum.init({
  applicationId: '332ef603-c58f-404b-8b50-7ab6684f92a6',
  clientToken: 'pub51c724e3491cedef48102a6f05f100a2',
  site: 'datadoghq.com',
  service: 'puffdirectory',
  env: runEnv,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 10,
  sessionReplaySampleRate: 10,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  allowedTracingUrls: [
    {
      match: (url) => url.startsWith(apiBaseUrl),
      propagatorTypes: ['tracecontext', 'b3'],
    },
  ],
});

datadogRum.startSessionReplayRecording();

export default function App() {
  const { openid } = Config();
  // For local env testing
  if (window.location.origin === 'http://localhost:3000') {
    openid.redirectUri = 'http://localhost:3000';
  }
  const oidcConfig = {
    onBeforeSignIn: () => {
      console.log('oidc onBeforeSignIn');
      const { pathname, search } = window.location;
      sessionStorage.setItem('entry.path', pathname + search);
    },
    onSignIn: () => {
      console.log('oidc onSignIn');
      // window.location.replace(window.sessionStorage.getItem("entry.pathname_search"))
    },
    authority: openid.authority,
    clientId: openid.clientId,
    redirectUri: window.location.origin, // openid.redirectUri
  };

  return (
    <div className="App">
      <AuthProvider {...oidcConfig}>
        <SessionProvider>
          <Router>
            <AppNavigation />
            <Container
              id="app-container"
              maxWidth="md"
              sx={{ mb: 2 }}
              disableGutters
            >
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/redirect" element={<RedirectPage />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route
                  path="/orphan-services"
                  element={<OrphanServicesPage />}
                />
                <Route
                  path="/claim-service/:servicename"
                  element={<ClaimServicePage />}
                />
                <Route path="/service/:servicename" element={<ServicePage />} />
                <Route path="/teams" element={<TeamsPage />} />
                <Route path="/team/:teamname" element={<TeamPage />} />
                <Route path="/people" element={<PeoplePage />} />
                <Route path="/person/:email" element={<PersonPage />} />
                <Route path="/resources" element={<ResourcesPage />} />
                <Route path="/resource/:name" element={<ResourcePage />} />
                <Route path="/releases" element={<ReleasesPage />} />
                <Route path="/templates" element={<TemplatesPage />} />
                <Route
                  path="/template/:name/:templatename"
                  element={<TemplatePage />}
                />
                <Route
                  path="/addexistingservice"
                  element={<AddExistingServicePage />}
                />
                <Route path="/api-keys" element={<ApiKeysPage />} />
              </Routes>
              <Copyright />
            </Container>
          </Router>
        </SessionProvider>
      </AuthProvider>
    </div>
  );
}
