import React, { useState } from 'react';
import { Cancel, Edit, PublishOutlined, Circle } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { handlerApiError } from '../shared/errorHandler';

export function EditableListField({
  fieldname,
  fieldvalue,
  listoptions,
  item,
  updateAction,
  canEdit,
}) {
  const [editvalue, setEditValue] = useState(fieldvalue);
  const [savedvalue, setSavedValue] = useState(fieldvalue);
  const [editMode, setEditMode] = useState(false);
  const [error, setErrorMsg] = useState('');
  const [saving, setSaving] = useState(false);
  const editComponent =
    canEdit === true ? (
      <IconButton
        color="primary"
        onClick={() => {
          setEditMode(true);
        }}
      >
        <Edit fontSize="small" />
      </IconButton>
    ) : (
      ''
    );
  const statusColor = {
    Active: '#25b225',
    Experimental: '#ce44ec',
    Deprecated: '#da4103',
  };

  function handleChange(e) {
    setEditValue(e.target.value);
  }

  const handleFieldSave = async () => {
    setSaving(true);
    const data = { [fieldname]: editvalue };
    item[fieldname] = editvalue;
    try {
      await updateAction(item, data);
      setSavedValue(editvalue);
      setEditMode(false); // close edit textfield
      setSaving(false);
    } catch (ex) {
      handlerApiError(ex, setErrorMsg);
      setSaving(false);
    }
  };

  const handleCancel = () => {
    setEditMode(false); // close editable textfield
    setErrorMsg(''); // clear error msg
    setEditValue(savedvalue); // reset to initial value
  };

  return (
    <span>
      {editMode ? (
        <span>
          <InputLabel id={`editable-listfield-label_${fieldname}`} />
          <Select
            style={{ marginRight: '10px' }}
            size="small"
            labelId="demo-simple-select-label"
            id={`editablelistfield_${fieldname}`}
            value={editvalue}
            label="List"
            onChange={handleChange}
          >
            {listoptions.map((i, idx) => (
              <MenuItem key={idx} value={i.value}>
                {i.name}
              </MenuItem>
            ))}
          </Select>
          <Button
            size="small"
            id={`save_${fieldname}`}
            variant="outlined"
            onClick={handleFieldSave}
            startIcon={<PublishOutlined />}
          >
            Save
            {saving && <CircularProgress />}
          </Button>
          <Button
            size="small"
            style={{ marginLeft: 7 }}
            id={`cancel_${fieldname}`}
            variant="outlined"
            onClick={handleCancel}
            color="error"
            startIcon={<Cancel />}
          >
            Cancel
          </Button>

          <div>{error}</div>
        </span>
      ) : (
        <span>
          {editComponent}
          {fieldname === 'status' ? (
            // Green checkmark icon for active status
            <span
              style={{
                fontWeight: 'bold',
                color: statusColor[editvalue],
              }}
            >
              <Circle
                sx={{
                  height: '10px',
                  width: '10px',
                  color: statusColor[editvalue],
                }}
              />{' '}
              {editvalue}
            </span>
          ) : (
            <span>{editvalue || 'N/A'}</span>
          )}
          {fieldname === 'tier' ? <span> {'●'.repeat(editvalue)}</span> : null}
        </span>
      )}
    </span>
  );
}
