import { Circle, Publish } from '@mui/icons-material';
import {
  Card,
  CardContent,
  LinearProgress,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  CircularProgress,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import * as React from 'react';
import { EntityDetails } from '../components/EntityDetails';
import useService from '../hooks/useService';
import useTeams from '../hooks/useTeams';
import ServiceFields from '../components/ServiceFields';
import { statusColor } from '../shared/serviceHandler';

export default function ClaimServicePage(params) {
  const { servicename } = useParams();
  const [isFormDirty, setFormDirty] = React.useState(false);
  const [isSaving, setSaving] = React.useState(false);
  const [error, setErrorMsg] = React.useState('');
  const { service, loading, updateService } = useService(servicename);
  const { items } = useTeams();
  const navigate = useNavigate();

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const teams = items
    .map((i) => i.team_name)
    .sort((a, b) => a.localeCompare(b));

  function handleInputChange(e) {
    setFormDirty(true);
  }

  async function handleSubmit(e) {
    setSaving(true);
    e.preventDefault();
    const formData = new FormData(e.target);
    const { team } = Object.fromEntries(formData.entries());
    console.log('updateService()', { service, team });
    try {
      await updateService(service, { team });
    } catch (ex) {
      const msg = ex.response?.data?.error || ex.message;
      setErrorMsg(msg);
      setSaving(false);
      return;
    }
    await delay(2200);
    setSaving(false);
    navigate(`/service/${service.service_name}`);
  }

  return (
    <span>
      {loading ? (
        <span>
          <Card variant="outlined" sx={{ minWidth: 325 }}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <Typography variant="h5" component="div" gutterBottom>
                <strong>loading</strong>
                <LinearProgress />
              </Typography>
            </Box>
          </Card>
        </span>
      ) : (
        <span>
          <Card variant="outlined" sx={{ minWidth: 325 }}>
            <CardContent sx={{ p: 0.5 }}>
              <Typography
                style={{ paddingLeft: '10px' }}
                variant="h5"
                component="div"
              >
                Claim: <strong>{service.service_name}</strong>{' '}
                <Circle
                  sx={{
                    height: '15px',
                    width: '15px',
                    color: statusColor[service.status],
                  }}
                />
              </Typography>
              <Box>
                <form className="claim-form" onSubmit={handleSubmit}>
                  <Card sx={{ m: 2, p: 4 }}>
                    <Typography sx={{ mb: 4 }}>
                      Select a Team for <strong>{service.service_name}</strong>
                    </Typography>
                    {teams?.length > 0 && (
                      <FormControl sx={{ m: 1, minWidth: 200 }}>
                        <InputLabel id="team-label">Team</InputLabel>
                        <Select
                          id="select-team"
                          labelId="team-label"
                          name="team"
                          label="Team"
                          defaultValue=""
                          onChange={handleInputChange}
                        >
                          {teams.map((team, idx) => (
                            <MenuItem key={idx} value={team}>
                              {team}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    <div>
                      <Button
                        disabled={!isFormDirty}
                        sx={{ ml: 2 }}
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        Save <Publish />
                      </Button>
                      <Button color="error">{error}</Button>
                      {isSaving && <CircularProgress />}
                    </div>
                  </Card>
                </form>
              </Box>
              <ServiceFields
                service={service}
                environment={undefined}
                updateService={updateService}
              />
              <EntityDetails entity={service} expanded={false} />
            </CardContent>
          </Card>
        </span>
      )}
    </span>
  );
}
