import {
  Grid,
  Box,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  LinearProgress,
  Typography,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import useTemplates from '../hooks/useTemplates';

function get_img(name) {
  name = name.toLowerCase();
  if (name.includes('go')) return '/assets/templates/golang.png';
  if (name.includes('nginx')) return '/assets/templates/nginx.png';
  if (name.includes('micronaut')) return '/assets/templates/micronaut.png';
  if (name.includes('node')) return '/assets/templates/nodejs.png';
  if (name.includes('helm')) return '/assets/templates/helm.png';
  if (name.includes('redis')) return '/assets/templates/redis.png';
  if (name.includes('destroy')) return '/assets/templates/delete.png';
  if (name.includes('update')) return '/assets/templates/update.png';
  if (name.includes('tf') || name.includes('terraform'))
    return '/assets/templates/terraform.png';

  // Generic logo
  return '/assets/templates/generic.png';
}
function TemplateCard({ name, inputs, filename, reponame }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Card sx={{ height: 250, width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CardMedia
            sx={{ height: 60, width: 180 }}
            image={get_img(name)}
            title={name}
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              {name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {filename}
            </Typography>
          </CardContent>
          <Box sx={{ flexGrow: 1 }} />
          <CardActions>
            <Button
              sx={{ width: '80%' }}
              component={Link}
              to={`/template/${name}/${filename
                .replace('.yaml', '')
                .replace('.yml', '')}`}
              size="small"
              fullWidth
              variant="contained"
            >
              Run
            </Button>
            <Button
              sx={{ width: '20%' }}
              component={Link}
              to={`//github.com/gopuff/${reponame}/blob/main/.github/workflows/${filename}`}
              size="small"
              variant="outlined"
              target="_blank"
            >
              Info
            </Button>
          </CardActions>
        </Box>
      </Card>
    </Box>
  );
}

function TemplatesCard({ loading, name, items }) {
  return (
    <div>
      {loading ? (
        <span>
          <LinearProgress />
          loading...
        </span>
      ) : (
        <Typography
          variant="h6"
          component="h1"
          sx={{ p: 2.5, marginBottom: 3 }}
          gutterBottom
        >
          Create Templates, using{' '}
          <Button
            sx={{ width: '30%' }}
            component={Link}
            to={`//github.com/gopuff/${name}`}
            size="small"
            variant="outlined"
            target="_blank"
          >
            {name}
          </Button>
        </Typography>
      )}
      <Grid
        container
        component={undefined}
        spacing={4}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        {!loading &&
          items &&
          items
            .filter((item) => item.name.includes('Create'))
            .map((item) => (
              <Grid item xs={12} sm={6} md={3} key={items.indexOf(item)}>
                <TemplateCard
                  name={item.name}
                  inputs={item.inputs}
                  filename={item.filename}
                  reponame={name}
                />
              </Grid>
            ))}
      </Grid>
      {!loading && (
        <Typography variant="h6" component="h1" sx={{ p: 1.5 }}>
          Other Templates
        </Typography>
      )}

      <Grid
        container
        component={undefined}
        spacing={4}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        {!loading &&
          items &&
          items
            .filter((item) => !item.name.includes('Create'))
            .map((item) => (
              <Grid item xs={12} sm={6} md={3} key={items.indexOf(item)}>
                <TemplateCard
                  name={item.name}
                  inputs={item.inputs}
                  filename={item.filename}
                  reponame={name}
                />
              </Grid>
            ))}
      </Grid>
    </div>
  );
}
export default function TemplatesPage() {
  const { items, loading } = useTemplates();
  let repoVendor = items.filter((i) => {
    return i.repo == 'repo-vendor' || i.repo == 'service-platform';
  });
  let sreServiceGenerator = items.filter((i) => {
    return i.repo == 'sre-service-generator';
  });
  return (
    <div>
      <TemplatesCard
        loading={loading}
        items={repoVendor}
        name={'repo-vendor'}
      ></TemplatesCard>
      <TemplatesCard
        loading={loading}
        items={sreServiceGenerator}
        name={'sre-service-generator'}
      ></TemplatesCard>
      {!loading && (
        <>
          <Typography variant="h6" component="h1" sx={{ p: 1.5 }}>
            Add EXISTING Service:
            <Button
              sx={{ ml: 3, width: '20%' }}
              component={Link}
              to="/addexistingservice"
              size="small"
              variant="outlined"
            >
              Add Existing Service
            </Button>
          </Typography>
        </>
      )}
    </div>
  );
}
