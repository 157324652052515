import React from 'react';
import {
  Card,
  Box,
  FormControl,
  InputLabel,
  LinearProgress,
  Link,
  TextField,
  Typography,
  Select,
  MenuItem,
  OutlinedInput,
} from '@mui/material';
import { Link as RLink } from 'react-router-dom';
import SortableTable from '../components/SortableTable';
import useServices from '../hooks/useServices';
import { Circle } from '@mui/icons-material';

const columns = [
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    hide_text: true,
    label: 'Status',
  },
  {
    id: 'deploy_target',
    numeric: false,
    disablePadding: false,
    label: 'Target',
    hide_text: true,
    hide_mobile: true,
  },
  {
    id: 'language',
    numeric: false,
    disablePadding: false,
    label: 'Lang',
    hide_text: true,
    hide_mobile: true,
  },
  {
    id: 'service_name',
    route: true,
    resource: 'service',
    numeric: false,
    disablePadding: false,
    label: 'Service',
  },
  {
    id: 'tier',
    numeric: true,
    disablePadding: false,
    label: 'Tier',
  },
  {
    id: 'total_score',
    numeric: true,
    disablePadding: false,
    label: 'Score',
  },
  {
    id: 'team',
    route: true,
    resource: 'team',
    numeric: false,
    hide_mobile: true,
    disablePadding: false,
    label: 'Team',
  },
];

export default function ServicesPage() {
  const { items, loading } = useServices();
  const [searchItems, setSearchItems] = React.useState([]);
  const [searchActive, setSearchActive] = React.useState(false);
  // localStorage.setItem("services_status", value);
  const [dropdownStatus, setDropdownStatus] = React.useState('Active');
  const [searchTxt, setSearchTxt] = React.useState('');
  const statusColor = {
    Active: '#25b225',
    Experimental: '#ce44ec',
    Deprecated: '#da4103',
  };

  const handleChangeStatus = (event) => {
    setSearchActive(true);
    const {
      target: { value },
    } = event;
    setDropdownStatus(value);
    localStorage.setItem('services_status', value);
    let filtered;
    if (value === 'ALL') {
      filtered = items.filter(
        (i) =>
          i.service_name.toLowerCase().indexOf(searchTxt.toLowerCase()) >= 0
      );
    } else {
      filtered = items
        .filter(
          (i) =>
            i.service_name.toLowerCase().indexOf(searchTxt.toLowerCase()) >= 0
        )
        .filter((i) => i.status === value);
    }
    setSearchItems(filtered);
  };

  const handleInputChange = async (e) => {
    const len = e.target.value.length;
    if (e.target.value === '') {
      setSearchActive(false);
      setSearchItems([]);
      setSearchTxt('');
    }
    if (len > 1) {
      setSearchTxt(e.target.value);
      setSearchActive(true);
      console.log(`search: ${e.target.value}, status: ${dropdownStatus}`);
      let filtered = items.filter(
        (i) =>
          i.service_name.toLowerCase().indexOf(e.target.value.toLowerCase()) >=
          0
      );

      if (dropdownStatus !== 'ALL') {
        filtered = filtered.filter((i) => i.status === dropdownStatus);
      }
      setSearchItems(filtered);
    }
  };
  return (
    <span>
      <Typography variant="h6" component="h1" sx={{ p: 1.5 }}>
        Services ({searchItems.length || items.length})
        <Link
          component={RLink}
          sx={{ ml: 3, fontSize: '.7em' }}
          to={'/orphan-services'}
        >
          orphan services
        </Link>
      </Typography>
      <Card style={{ padding: '0px' }} variant="outlined" sx={{ padding: 0 }}>
        <form noValidate autoComplete="off">
          <Box display="flex">
            <FormControl sx={{ marginTop: 1, width: 150 }}>
              <InputLabel id="status">Status</InputLabel>
              <Select
                size="small"
                labelId="status"
                id="status"
                value={dropdownStatus}
                onChange={handleChangeStatus}
                input={<OutlinedInput label="Status" />}
              >
                <MenuItem value="ALL">ALL</MenuItem>
                <MenuItem value="Active">
                  <Circle
                    sx={{
                      padding: '0px',
                      width: '10px',
                      height: '10px',
                      marginRight: 1,
                      color: statusColor.Active,
                    }}
                  />{' '}
                  Active
                </MenuItem>
                <MenuItem value="Experimental">
                  <Circle
                    sx={{
                      padding: '0px',
                      width: '10px',
                      height: '10px',
                      marginRight: 1,
                      color: statusColor.Experimental,
                    }}
                  />{' '}
                  Experimental
                </MenuItem>
                <MenuItem value="Deprecated">
                  <Circle
                    sx={{
                      padding: '0px',
                      width: '10px',
                      height: '10px',
                      marginRight: 1,
                      color: statusColor.Deprecated,
                    }}
                  />{' '}
                  Deprecated
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ marginTop: 1, flexGrow: 1 }}>
              <TextField
                size="small"
                id="outlined-search"
                label="Filter services"
                type="search"
                variant="outlined"
                onChange={handleInputChange}
              />
            </FormControl>
          </Box>
        </form>
        {loading ? (
          <span>
            <LinearProgress />
            loading...
          </span>
        ) : (
          ''
        )}
        {searchItems?.length > 0 && (
          <SortableTable id="services" rows={searchItems} columns={columns} />
        )}
        {searchActive && searchItems?.length === 0 && (
          <div>No search results</div>
        )}
        {!searchActive &&
          (dropdownStatus === 'ALL' ? (
            <SortableTable id="services" rows={items} columns={columns} />
          ) : (
            <SortableTable
              id="services"
              rows={items.filter((i) => i.status === dropdownStatus)}
              columns={columns}
            />
          ))}
      </Card>
    </span>
  );
}
