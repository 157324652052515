/// <reference path="../../typedefs.js" />
import React from 'react';
import axios from 'axios';
import Config from '../shared/Config';
import { handlerApiError } from '../shared/errorHandler';

const { apiBaseUrl } = Config();
const endpointUrl = `${apiBaseUrl}/api/resource`;
const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};
const cache = {};

/** @param {string} resourceName */
export default function useResource(resourceName) {
  const access_token = window.sessionStorage.getItem('token');
  headers.Authorization = `Bearer ${access_token}`;
  const [loading, setLoading] = React.useState(true);
  /** @type {useState<Resource>} */
  const [resource, setResource] = React.useState({ id: '', name: '' });

  React.useEffect(() => {
    const fetchData = async () => {
      const config = { url: `${endpointUrl}/${resourceName}`, headers };
      axios(config)
        .then((response) => {
          const { data } = response;
          console.log(`useResource: ${resourceName}`, { data });
          setResource(data);
          cache[resourceName] = data;
          setLoading(false);
        })
        .catch((err) => handlerApiError(err));
    };

    if (cache[resourceName]) {
      setResource(cache[resourceName]);
      setLoading(false);
    } else {
      fetchData();
    }
  }, [resourceName]);

  return { loading, resource };
}
